import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// material-ui
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import AnimateButton from "View/Admin/Components/AnimateButton";

import Errors from "Notifications/Errors";
import Spinner from "View/Spinners/Spinner";

import { validateForm } from "utils/validation";

import {
  createBank,
  cancelSave,
  setErrors,
  removeBankErrors,
  resetComponentStore,
} from "actions/bankActions";
import MainCard from "../../Components/MainCard";

const CreateBank = ({
  createBank,
  errorList,
  cancelSave,
  loadingBank,
  setErrors,
  removeBankErrors,
  resetComponentStore,
}) => {
  const navigate = useNavigate();

  const initialFormData = {
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branch: "",
    accountType: "saving",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    removeBankErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    createBank(submitData, navigate).then((res) => {
      if (!res.status) return;
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  return (
    <React.Fragment>
      {loadingBank ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            accountHolderName: Yup.string()
              .max(150)
              .required("Account Holder Name is required"),
            bankName: Yup.string().max(150).required("Bank Name is required"),
            accountNumber: Yup.string()
              .matches(/^\d+$/, "Account number must contain only digits")
              .max(20)
              .required("Account number is required"),
            ifscCode: Yup.string().max(15).required("IFSC Code is required"),
            branch: Yup.string().max(150).required("Branch Name is required"),
            accountType: Yup.string()
              .max(150)
              .required("Account Type is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => {
            const yupErrors = {};
            if (errors) {
              Object.keys(errors).forEach((key) => {
                if (touched[key]) {
                  yupErrors[key] = errors[key];
                }
              });
            }

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MainCard>
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        className="mb-2"
                      >
                        Account
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="accountHolderName-bank">
                              Account Holder Name*
                            </InputLabel>
                            <OutlinedInput
                              id="accountHolderName-bank"
                              type="text"
                              value={values.accountHolderName}
                              name="accountHolderName"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Full Name"
                              fullWidth
                              error={Boolean(
                                touched.accountHolderName &&
                                  (errors.accountHolderName ||
                                    (errorList && errorList.accountHolderName))
                              )}
                            />

                            {touched.accountHolderName &&
                              (errors.accountHolderName ||
                                yupErrors?.accountHolderName ||
                                (errorList && errorList.accountHolderName)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="accountHolderName"
                                  id="accountHolderName-bank"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="bankName-bank">
                              Bank Name*
                            </InputLabel>
                            <OutlinedInput
                              id="bankName-bank"
                              type="text"
                              value={values.bankName}
                              name="bankName"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Enter Bank Name"
                              fullWidth
                              error={Boolean(
                                touched.bankName &&
                                  (errors.bankName ||
                                    (errorList && errorList.bankName))
                              )}
                            />

                            {touched.bankName &&
                              (errors.bankName ||
                                yupErrors?.bankName ||
                                (errorList && errorList.bankName)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="bankName"
                                  id="bankName-bank"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="accountNumber-bank">
                              Account Number*
                            </InputLabel>
                            <OutlinedInput
                              id="accountNumber-bank"
                              type="text"
                              value={values.accountNumber}
                              name="accountNumber"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Enter Account Number"
                              fullWidth
                              error={Boolean(
                                touched.accountNumber &&
                                  (errors.accountNumber ||
                                    (errorList && errorList.accountNumber))
                              )}
                            />

                            {touched.accountNumber &&
                              (errors.accountNumber ||
                                yupErrors?.accountNumber ||
                                (errorList && errorList.accountNumber)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="accountNumber"
                                  id="accountNumber-bank"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="ifscCode-bank">
                              IFSC Code*
                            </InputLabel>
                            <OutlinedInput
                              id="ifscCode-bank"
                              type="text"
                              value={values.ifscCode}
                              name="ifscCode"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Enter IFSC Code"
                              fullWidth
                              error={Boolean(
                                touched.ifscCode &&
                                  (errors.ifscCode ||
                                    (errorList && errorList.ifscCode))
                              )}
                            />

                            {touched.ifscCode &&
                              (errors.ifscCode ||
                                yupErrors?.ifscCode ||
                                (errorList && errorList.ifscCode)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="ifscCode"
                                  id="ifscCode-bank"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="branch-bank">
                              Branch*
                            </InputLabel>
                            <OutlinedInput
                              id="branch-bank"
                              type="text"
                              value={values.branch}
                              name="branch"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Enter Branch"
                              fullWidth
                              error={Boolean(
                                touched.branch &&
                                  (errors.branch ||
                                    (errorList && errorList.branch))
                              )}
                            />

                            {touched.branch &&
                              (errors.branch ||
                                yupErrors?.branch ||
                                (errorList && errorList.branch)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="branch"
                                  id="branch-bank"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="accountType-bank">
                              Account Type*
                            </InputLabel>
                            <Select
                              id="accountType-bank"
                              value={values.accountType}
                              name="accountType"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              fullWidth
                              error={Boolean(
                                touched.accountType &&
                                  (errors.accountType ||
                                    (errorList && errorList.accountType))
                              )}
                            >
                              <MenuItem value="saving">Saving</MenuItem>
                              <MenuItem value="current">Current</MenuItem>
                            </Select>

                            {touched.accountType &&
                              (errors.accountType ||
                                yupErrors?.accountType ||
                                (errorList && errorList.accountType)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="accountType"
                                  id="accountType-bank"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid container justifyContent="flex-end" spacing={2}>
                          <Grid item xs={2} className="mt-4">
                            <AnimateButton>
                              <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="error"
                              >
                                Cancle
                              </Button>
                            </AnimateButton>
                          </Grid>
                          <Grid item xs={2} className="mt-4">
                            <AnimateButton>
                              <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                              >
                                Submit
                              </Button>
                            </AnimateButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      )}
    </React.Fragment>
  );
};

CreateBank.propTypes = {
  createBank: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingBank: state.bank.loadingBank,
});

export default connect(mapStateToProps, {
  createBank,
  cancelSave,
  setErrors,
  removeBankErrors,
  resetComponentStore,
})(CreateBank);
