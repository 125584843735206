import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { connect } from "react-redux";
import { capitalizeFirst, isAdmin } from "utils/helper";

const AppBreadcrumb = ({ pageTitle, crumbs, user }) => {
  const checkIsAdmin = isAdmin(user);
  return (
    <div className="bread-crumb">
      <h4>{pageTitle}</h4>

      {crumbs ? (
        <Breadcrumb>
          <Breadcrumb.Item
            linkProps={{
              to: checkIsAdmin ? "/admin/dashboard" : "/admin/user-dashboard",
            }}
            linkAs={Link}
          >
            Dashboard
          </Breadcrumb.Item>
          {crumbs.map((item, i) => (
            <React.Fragment key={i}>
              {item.path ? (
                <Breadcrumb.Item linkProps={{ to: item.path }} linkAs={Link}>
                  {capitalizeFirst(item.name)}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item active>
                  {capitalizeFirst(item.name)}
                </Breadcrumb.Item>
              )}
            </React.Fragment>
          ))}
        </Breadcrumb>
      ) : undefined}
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AppBreadcrumb);
