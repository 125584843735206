import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

import {
  getWalletHistoriesList,
  resetComponentStore,
} from "actions/walletHistoryActions";
import Dot from "../Dashboard/Table/Dot";
import { capitalizeFirst } from "utils/helper";
import AdminWalletHistoryFilters from "./AdminWalletHistoryFilters";

const WalletHistory = ({
  walletHistoriesList: { data, count },
  getWalletHistoriesList,
  loadingWalletHistoriesList,
  resetComponentStore,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = useState(initialSortingParams);
  const [onlyOnce, setOnce] = useState(true);

  const columns = [
    {
      name: "Name",
      cell: (row) => row.userObject && row.userObject.fullname,
      sortable: false,
      width: "20%",
    },

    {
      name: "Email",
      cell: (row) => row.userObject && row.userObject.email,
      sortable: false,
      width: "20%",
    },

    {
      name: "Phone",
      selector: (row) => row.userObject && row.userObject.phone,
      sortable: false,
      width: "15%",
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "Type",
      selector: (row) =>
        row.type ? (
          <div
            className={`d-flex align-items-center gap-2 fw-bold ${
              row.type == "credit"
                ? "bg-credit px-2 py-1 rounded"
                : "bg-debit px-2 py-1 rounded"
            }`}
          >
            <Dot color={row.type} size={8} />
            {capitalizeFirst(row.type)}
          </div>
        ) : null,
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "Date",
      selector: (row) =>
        row.createdAt
          ? moment(row.createdAt).format("hh:mm a, DD-MM-YYYY")
          : "",
      sortable: false,
      width: "15%",
      wrap: true,
    },
  ];

  useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getWalletHistoriesList(clientParams);
  }, [getWalletHistoriesList, clientParams, resetComponentStore]);

  const handleSearch = (
    fromDate,
    toDate,
    statusType,
    searchType,
    searchValue
  ) => {
    getWalletHistoriesList({
      fromDate,
      toDate,
      statusType,
      searchType,
      searchValue,
      ...clientParams,
    });
  };

  const totalAmount = data.reduce((acc, row) => acc + row.amount, 0);

  const totalRow = {
    amount: `Total : ${totalAmount}`,
  };

  const tableData = [...data, totalRow];

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Wallet History"
        crumbs={[{ name: "Wallet History" }]}
      />

      <div className="table-filter-section mb-4">
        <AdminWalletHistoryFilters onSearch={handleSearch} />
      </div>

      <Card>
        <Card.Body>
          <PiDataTable
            columns={columns}
            data={tableData}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingWalletHistoriesList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

WalletHistory.propTypes = {
  getWalletHistoriesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  walletHistoriesList: state.walletHistory.walletHistoriesList,
  loadingWalletHistoriesList: state.walletHistory.loadingWalletHistoriesList,
});

export default connect(mapStateToProps, {
  getWalletHistoriesList,
  resetComponentStore,
})(WalletHistory);
