// assets
import { ImTree } from "react-icons/im";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const DownlinePath = {
  id: "group-myDownline",
  title: "",
  type: "group",
  children: [
    {
      id: "myDownline",
      title: "My Network",
      type: "item",
      icon: ImTree,
      subChlidrens: [
        {
          id: "directNetwork",
          title: "Direct Network",
          type: "item",
          url: "my-downline",
          icon: ImTree,
        },
        {
          id: "allNetwork",
          title: "All Network",
          type: "item",
          url: "all-user-downline",
          icon: ImTree,
        },
      ],
    },
  ],
};

export default DownlinePath;
