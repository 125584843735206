import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

import {
  updateOrder,
  removeOrderErrors,
  resetComponentStore,
  getOrderById,
} from "actions/userOrderActions";

import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const AdminEditOrderRequest = ({
  updateOrder,
  loadingOrder,
  currentOrder,
  getOrderById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { order_id } = useParams();

  const [formData, setFormData] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(null);

  React.useEffect(() => {
    if (currentOrder) {
      setFormData(currentOrder);

      const isPending =
        currentOrder &&
        currentOrder.status &&
        currentOrder.status === "pending";

      setIsDisable(isPending);
    }
  }, [currentOrder]);

  const onSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    updateOrder(formData, order_id).then(() => {
      setSubmitting(false);
    });
  };

  const handleApprove = () => {
    setFormData({ ...formData, status: "approved" });
    setSubmitting(true);
    updateOrder({ ...formData, status: "approved" }, order_id, navigate).then(
      () => {
        setSubmitting(false);
      }
    );
  };

  const handleReject = () => {
    setFormData({ ...formData, status: "rejected" });
    setSubmitting(true);
    updateOrder({ ...formData, status: "rejected" }, order_id, navigate).then(
      () => {
        setSubmitting(false);
      }
    );
  };

  React.useEffect(() => {
    if (order_id) {
      resetComponentStore();
      getOrderById(order_id);
    }
  }, [order_id]);

  return (
    <React.Fragment>
      {loadingOrder ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={onSubmit} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    Order Information
                  </Typography>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.userId || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Method</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.method || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.amount || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>UTR Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.utrNumber || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={formData.status || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, status: e.target.value })
                    }
                  />
                </Form.Group>

                {isDisable && (
                  <div className="mb-3">
                    <Button variant="success" onClick={handleApprove}>
                      Approve
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleReject}
                      className="ms-2 me-2"
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

AdminEditOrderRequest.propTypes = {
  loadingOrder: PropTypes.bool.isRequired,
  currentOrder: PropTypes.object,
  getOrderById: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loadingOrder: state.userOrder.loadingOrder,
  currentOrder: state.userOrder.currentOrder,
});

export default connect(mapStateToProps, {
  updateOrder,
  removeOrderErrors,
  resetComponentStore,
  getOrderById,
})(AdminEditOrderRequest);
