import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

// material-ui
import { Grid, Typography } from "@mui/material";
import {
  getAllUsersCount,
  getUserCurrentWallet,
  getUserAllIncome,
  getUserDownline,
  getAdminAllData,
} from "actions/dashboardActions";
import MainCard from "../Components/MainCard";
import { FaUserFriends, FaWallet } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa6";
import { MdOutlinePendingActions } from "react-icons/md";
import AdminTable from "./Table/AdminTable";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const AdminDashboard = ({
  getAllUsersCount,
  getUserCurrentWallet,
  getUserAllIncome,
  getUserDownline,
  totalUserCount,
  currentWallet,
  userIncomesList,
  userDownlineList,
  getAdminAllData,
  adminAllDashboardData,
  adminAllDashboardLoading,
}) => {
  let totalDirectJoining = 0;
  let totalWatchVideo = 0;
  let totalSpinAndWin = 0;
  let totalTelegram = 0;
  let totalLevel = 0;
  let totalReward = 0;

  let totalUserWallet = 0;
  let totalPendingRequest = 0;

  if (userIncomesList && userIncomesList.length > 0) {
    userIncomesList.forEach((entry) => {
      switch (entry.earning_type) {
        case "direct_joining":
          totalDirectJoining = entry.totalAmount;
          break;
        case "watch_video":
          totalWatchVideo = entry.totalAmount;
          break;
        case "spin_and_win":
          totalSpinAndWin = entry.totalAmount;
          break;
        case "telegram":
          totalTelegram = entry.totalAmount;
          break;
        case "level":
          totalLevel = entry.totalAmount;
          break;
        case "rewards":
          totalReward = entry.totalAmount;
          break;
      }
    });
  }

  if (adminAllDashboardData) {
    totalUserWallet = adminAllDashboardData?.totalUserWalletSum;

    totalPendingRequest =
      adminAllDashboardData?.withdrawalPendingRequestCount +
      adminAllDashboardData?.approvalPendingRequestCount;
  }

  React.useEffect(() => {
    getAdminAllData();
    getUserAllIncome();
    getAllUsersCount();
    getUserCurrentWallet();
    getUserDownline();
  }, [
    getAllUsersCount,
    getUserCurrentWallet,
    getUserAllIncome,
    getUserDownline,
    getAdminAllData,
  ]);
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #add8e6, #00008b)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Total Users
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`${totalUserCount ? totalUserCount / 13 : 0}`}
            <FaUserFriends size={40} />
          </Typography>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #ff0000, #8b0000)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Deposit / ID Approved
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${currentWallet ? currentWallet : 0}`}
            <FaWallet size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #cc7b00, #7d3c00)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            User Wallet Total Amount
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {adminAllDashboardLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {` Loading... `}
              </>
            ) : (
              `₹ ${totalUserWallet}`
            )}

            <FaMoneyBillWave size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #c77dff, #4b0082)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Total Pending Request
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {adminAllDashboardLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {` Loading... `}
              </>
            ) : (
              `${totalPendingRequest}`
            )}
            <MdOutlinePendingActions size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Grid item xs={12}>
          <Typography variant="h5" className="mb-3">
            Recent Added Users
          </Typography>
        </Grid>

        <AdminTable />

        {(!userDownlineList || !userDownlineList.last10DownlineUsers) && (
          <Typography variant="body2" className="d-flex justify-content-center">
            No downline found
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

AdminDashboard.propTypes = {
  getAllUsersCount: PropTypes.func.isRequired,
  getUserCurrentWallet: PropTypes.func.isRequired,
  getUserAllIncome: PropTypes.func.isRequired,
  getUserDownline: PropTypes.func.isRequired,
  getAdminAllData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  totalUserCount: state.dashboard.totalUserCount,
  currentWallet: state.dashboard.currentWallet,
  userIncomesList: state.dashboard.userIncomesList,
  userDownlineList: state.dashboard.userDownlineList,
  adminAllDashboardData: state.dashboard.adminAllDashboardData,
  adminAllDashboardLoading: state.dashboard.adminAllDashboardLoading,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getAllUsersCount,
  getUserCurrentWallet,
  getUserAllIncome,
  getUserDownline,
  getAdminAllData,
})(AdminDashboard);
