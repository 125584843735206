import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  approvalsList: {
    page: 1,
    data: [],
    count: 0,
  },
  adminAccountsList: {
    data: [],
  },
  currentApproval: null,
  loadingApprovalsList: true,
  loadingApproval: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const approvalSlice = createSlice({
  name: "approval",
  initialState: initialState,
  reducers: {
    loadingApprovalsList(state) {
      return {
        ...state,
        loadingApprovalsList: true,
      };
    },
    approvalSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingApprovalsList: false,
      };
    },
    approvalListUpdated(state, action) {
      return {
        ...state,
        approvalsList: {
          data: action.payload,
          // page: action.payload.metadata[0].current_page,
          // count: action.payload.metadata[0].totalRecord,
        },
        loadingApprovalsList: false,
      };
    },

    adminAccountListUpdated(state, action) {
      return {
        ...state,
        adminAccountsList: {
          data: action.payload,
        },
        loadingApprovalsList: false,
      };
    },

    approvalDetailsById(state, action) {
      return {
        ...state,
        currentApproval: action.payload,
        loadingApproval: false,
      };
    },
    resetApproval(state) {
      return {
        ...initialState,
      };
    },

    approvalError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingApproval: false,
        loadingApprovalsList: false,
      };
    },
    loadingOnApprovalSubmit(state) {
      return {
        ...state,
        loadingApproval: true,
      };
    },
    approvalCreated(state) {
      return {
        ...state,
        loadingApproval: false,
      };
    },
    approvalUpdated(state, action) {
      return {
        ...state,
        approvalsList: {
          ...state.approvalsList,
          data: state.approvalsList.data.map((approval) =>
            approval._id === action.payload._id ? action.payload : approval
          ),
        },
      };
    },
    approvalDeleted(state, action) {
      const currentCount = state.approvalsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.approvalsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        approvalsList: {
          data: state.approvalsList.data.filter(
            (approval) => approval._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingApprovalsList: false,
      };
    },
  },
});
export const {
  loadingApprovalsList,
  approvalSearchParametersUpdate,
  approvalListUpdated,
  adminAccountListUpdated,
  resetApproval,
  approvalError,
  loadingOnApprovalSubmit,
  approvalCreated,
  approvalUpdated,
  approvalDetailsById,
  approvalDeleted,
} = approvalSlice.actions;
export default approvalSlice.reducer;
