import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  earningsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentEarning: null,
  loadingEarningsList: true,
  loadingEarning: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const earningSlice = createSlice({
  name: "earning",
  initialState: initialState,
  reducers: {
    loadingEarningsList(state) {
      return {
        ...state,
        loadingEarningsList: true,
      };
    },
    earningSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingEarningsList: false,
      };
    },
    earningListUpdated(state, action) {
      return {
        ...state,
        earningsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingEarningsList: false,
      };
    },
    earningDetailsById(state, action) {
      return {
        ...state,
        currentEarning: action.payload,
        loadingEarning: false,
      };
    },
    resetEarning(state) {
      return {
        ...initialState,
      };
    },

    earningError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingEarning: false,
        loadingEarningsList: false,
      };
    },
    loadingOnEarningSubmit(state) {
      return {
        ...state,
        loadingEarning: true,
      };
    },
    earningCreated(state) {
      return {
        ...state,
        loadingEarning: false,
      };
    },
    earningUpdated(state, action) {
      return {
        ...state,
        earningsList: {
          ...state.earningsList,
          data: state.earningsList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    earningDeleted(state, action) {
      const currentCount = state.earningsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.earningsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        earningsList: {
          data: state.earningsList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingEarningsList: false,
      };
    },
  },
});
export const {
  loadingEarningsList,
  earningSearchParametersUpdate,
  earningListUpdated,
  resetEarning,
  earningError,
  loadingOnEarningSubmit,
  earningCreated,
  earningUpdated,
  earningDetailsById,
  earningDeleted,
} = earningSlice.actions;
export default earningSlice.reducer;
