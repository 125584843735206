import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingUpisList,
  upiSearchParametersUpdate,
  upiListUpdated,
  resetUpi,
  upiError,
  loadingOnUpiSubmit,
  upiCreated,
  upiUpdated,
  upiDetailsById,
  upiDeleted,
} from "reducers/upiReducer";

import { baseURL } from "constants/index";

export const getUpisList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingUpisList());
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/upis/list/${loggedInUserID}`,
      config
    );

    dispatch(upiSearchParametersUpdate(appTokenParams));

    dispatch(upiListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          upiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUpiById = (upi_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnUpiSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/upis/${upi_id}`,
      config
    );

    dispatch(upiDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          upiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUpiByUserId = (user_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnUpiSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/upis/user/${user_id}`,
      config
    );

    dispatch(upiDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          upiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createUpi = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnUpiSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/credentials/upis`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(upiCreated(res.data.response));
      dispatch(setAlert("UPI Created.", "success"));
      navigate(`/admin/credentials`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(upiError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          upiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateUpi = (formData, navigate, upi_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      `${baseURL}/api/admin/credentials/upis/${upi_id}/edit`,
      formData,
      config
    );

    if (res.data.status === true) {
      await dispatch(upiUpdated(res.data.response));
      dispatch(setAlert(res.data.message, "success"));
      return res.data ? res.data : { status: false };
    } else {
      const errors = res.data.errors;
      if (errors.length) {
        dispatch(upiError());
        dispatch(setErrors(errors));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(
          upiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "error"
          )
        );
      }
    }
  }
};

export const deleteUpi = (upi_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(
      `${baseURL}/api/admin/credentials/upis/${upi_id}`,
      config
    );

    dispatch(upiDeleted(upi_id));
    dispatch(setAlert("UPI deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        upiError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeUpiErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetUpi());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(upiError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/credentials");
};
