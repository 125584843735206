import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Errors from "Notifications/Errors";
import AnimateButton from "../../../../AnimateButton";
import MainCard from "View/Admin/Components/MainCard";
import Spinner from "View/Spinners/Spinner";
import Form from "react-bootstrap/Form";
import {
  createEarningLinks,
  removeAdminAccountSettingErrors,
  resetComponentStore,
} from "actions/adminAccountSettingActions";

const CreateEarningLink = ({
  errorList,
  loadingAdminEarningLink,
  createEarningLinks,
  removeAdminAccountSettingErrors,
  resetComponentStore,
}) => {
  const initialState = {
    title: "",
    link: "",
    linkType: "youtube",
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      removeAdminAccountSettingErrors();
      // Your form submission logic here
      setStatus({ success: true });
      setSubmitting(false);

      createEarningLinks(values, navigate).then((res) => {
        if (!res.status) return;
      });
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {loadingAdminEarningLink ? (
        <Spinner />
      ) : (
        <Grid item xs={12}>
          <MainCard>
            <Formik
              initialValues={formData}
              validationSchema={Yup.object().shape({
                title: Yup.string().max(255).required("Title is required"),
                link: Yup.string().max(255).required("Link is required"),
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="title">Title*</InputLabel>
                        <OutlinedInput
                          id="title"
                          type="text"
                          value={values.title}
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Title"
                          fullWidth
                          error={Boolean(touched.title && errors.title)}
                        />
                        {touched.title && errors.title && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="title"
                            id="title"
                          />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="link">Link*</InputLabel>
                        <OutlinedInput
                          id="link"
                          type="url"
                          value={values.link}
                          name="link"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Link"
                          fullWidth
                          error={Boolean(touched.link && errors.link)}
                        />
                        {touched.link && errors.link && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="link"
                            id="link"
                          />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="linkType">Link Type</InputLabel>
                        <Select
                          id="linkType"
                          value={values.linkType}
                          name="linkType"
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem value="youtube">YouTube</MenuItem>
                          <MenuItem value="telegram">Telegram</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>

                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <AnimateButton>
                          <Button
                            disableElevation
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </AnimateButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </MainCard>
        </Grid>
      )}
    </React.Fragment>
  );
};

CreateEarningLink.propTypes = {
  createEarningLinks: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingAdminEarningLink: state.accountSetting.loadingAdminEarningLink,
});

export default connect(mapStateToProps, {
  createEarningLinks,
  removeAdminAccountSettingErrors,
  resetComponentStore,
})(CreateEarningLink);
