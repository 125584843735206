import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingApprovalsList,
  approvalSearchParametersUpdate,
  approvalListUpdated,
  adminAccountListUpdated,
  resetApproval,
  approvalError,
  loadingOnApprovalSubmit,
  approvalCreated,
  approvalUpdated,
  approvalDetailsById,
  approvalDeleted,
} from "reducers/approvalReducer";
import { withdrawalCreated } from "reducers/withdrawalReducer";

import { baseURL } from "constants/index";

export const getApprovalsList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingApprovalsList());
    const res = await axios.get(
      `${baseURL}/api/admin/approvals/list/${loggedInUserID}`,
      config
    );

    dispatch(approvalSearchParametersUpdate(appTokenParams));

    dispatch(approvalListUpdated(res.data?.response[0]?.data));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          approvalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createWithdrawal = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnApprovalSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/approvals/withdrawals/create`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(withdrawalCreated(res.data.response));
      dispatch(setAlert("Approval request submitted successfully", "success"));
      navigate(`/admin/user-dashboard`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(approvalError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          approvalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getAdminAccountsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingApprovalsList());
    const res = await axios.get(
      `${baseURL}/api/admin/approvals/accounts/list`,
      config
    );

    dispatch(adminAccountListUpdated(res.data.response));
  } catch (err) {
    console.error(err);
  }
};

export const getApprovalById = (approval_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnApprovalSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${baseURL}/api/admin/approvals/${approval_id}`,
      config
    );

    dispatch(approvalDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          approvalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createApproval = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnApprovalSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/approvals`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(approvalCreated(res.data.response));
      dispatch(setAlert("Approval Created.", "success"));

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(approvalError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          approvalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateApproval =
  (formData, approval_id, navigate) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `${baseURL}/api/admin/approvals/${approval_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(approvalUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        navigate("/admin/approval-request");
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(approvalError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            approvalError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteApproval = (approval_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(`${baseURL}/api/admin/approvals/${approval_id}`, config);

    dispatch(approvalDeleted(approval_id));
    dispatch(setAlert("Approval deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        approvalError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeApprovalErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetApproval());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(approvalError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/approvals");
};
