import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  qrScannersList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentQRScanner: null,
  loadingQRScannersList: true,
  loadingQRScanner: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const qrScannerSlice = createSlice({
  name: "qrScanner",
  initialState: initialState,
  reducers: {
    loadingQRScannersList(state) {
      return {
        ...state,
        loadingQRScannersList: true,
      };
    },
    qrScannerSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingQRScannersList: false,
      };
    },
    qrScannerListUpdated(state, action) {
      return {
        ...state,
        qrScannersList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingQRScannersList: false,
      };
    },
    qrScannerDetailsById(state, action) {
      return {
        ...state,
        currentQRScanner: action.payload,
        loadingQRScanner: false,
      };
    },
    resetQRScanner(state) {
      return {
        ...initialState,
      };
    },

    qrScannerError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingQRScanner: false,
        loadingQRScannersList: false,
      };
    },
    loadingOnQRScannerSubmit(state) {
      return {
        ...state,
        loadingQRScanner: true,
      };
    },
    qrScannerCreated(state) {
      return {
        ...state,
        loadingQRScanner: false,
      };
    },
    qrScannerUpdated(state, action) {
      return {
        ...state,
        qrScannersList: {
          ...state.qrScannersList,
          data: state.qrScannersList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    qrScannerDeleted(state, action) {
      const currentCount = state.qrScannersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.qrScannersList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        qrScannersList: {
          data: state.qrScannersList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingQRScannersList: false,
      };
    },
  },
});
export const {
  loadingQRScannersList,
  qrScannerSearchParametersUpdate,
  qrScannerListUpdated,
  resetQRScanner,
  qrScannerError,
  loadingOnQRScannerSubmit,
  qrScannerCreated,
  qrScannerUpdated,
  qrScannerDetailsById,
  qrScannerDeleted,
} = qrScannerSlice.actions;
export default qrScannerSlice.reducer;
