import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

// material-ui
import { Grid, Typography } from "@mui/material";

import {
  getAllUsersCount,
  getUserCurrentWallet,
  getUserAllIncome,
  getUserDownline,
} from "actions/dashboardActions";
import UserTable from "./Table/UserTable";
import MainCard from "../Components/MainCard";
import {
  FaUserFriends,
  FaWallet,
  FaYoutube,
  FaTelegramPlane,
  FaRupeeSign,
} from "react-icons/fa";
import { ImSpinner4, ImTree } from "react-icons/im";
import { SiLevelsdotfyi } from "react-icons/si";
import { FaGifts } from "react-icons/fa6";
import { FaRegUserCircle } from "react-icons/fa";
import { PiShareFat } from "react-icons/pi";
import { capitalizeFirst } from "utils/helper";
import { Button } from "react-bootstrap";
import { getAdminAccountSettingsList } from "actions/adminAccountSettingActions";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const Dashboard = ({
  getAllUsersCount,
  getUserCurrentWallet,
  getUserAllIncome,
  getUserDownline,
  totalUserCount,
  currentWallet,
  userIncomesList,
  userDownlineList,
  user,
  getAdminAccountSettingsList,
  adminAccountSettingsList: { data },
}) => {
  const directJoinReferCode =
    user && user?.myReferCode ? user?.myReferCode : "";

  const naviPath = `register/${directJoinReferCode}`;

  const profileUrl = `${window.location.origin}/${naviPath}`;

  const [value, setValue] = useState(profileUrl);
  const [copied, setCopied] = useState(false);
  const otherCopy = () => setCopied(true);

  const [telegramUrl, setTelegramUrl] = useState(null);
  const [adminMobileNumber, setAdminMobileNumber] = useState(null);

  const handleShareLink = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(profileUrl)}`;
    window.open(whatsappUrl, "_blank");
  };
  const handleTelegramLink = () => {
    const telegramLink = `https://t.me/${telegramUrl}`;
    window.open(telegramLink, "_blank");
  };

  const handleCallNow = () => {
    window.open(`tel:+91${adminMobileNumber}`);
  };

  let totalDirectJoining = 0;
  let totalWatchVideo = 0;
  let totalSpinAndWin = 0;
  let totalTelegram = 0;
  let totalLevel = 0;
  let totalReward = 0;

  if (userIncomesList && userIncomesList.length > 0) {
    userIncomesList.forEach((entry) => {
      switch (entry.earning_type) {
        case "direct_joining":
          totalDirectJoining = entry.totalAmount;
          break;
        case "watch_video":
          totalWatchVideo = entry.totalAmount;
          break;
        case "spin_and_win":
          totalSpinAndWin = entry.totalAmount;
          break;
        case "telegram":
          totalTelegram = entry.totalAmount;
          break;
        case "level":
          totalLevel = entry.totalAmount;
          break;
        case "rewards":
          totalReward = entry.totalAmount;
          break;
      }
    });
  }

  React.useEffect(() => {
    getUserAllIncome();
    getAllUsersCount();
    getUserCurrentWallet();
    getUserDownline();
    getAdminAccountSettingsList();
  }, [
    getAllUsersCount,
    getUserCurrentWallet,
    getUserAllIncome,
    getUserDownline,
    getAdminAccountSettingsList,
  ]);

  React.useEffect(() => {
    if (data?.length) {
      const telegramURL = data[0].adminTelegramLink;
      const mobileNumber = data[0].mobileNumber;

      setAdminMobileNumber(mobileNumber);
      setTelegramUrl(telegramURL);
    }
  }, [data]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #ff6347, #00018b)",
            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            User Details
          </Typography>

          <Typography
            variant="h5"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            <span>
              {`User ID : ${user && user?.myReferCode}`} <br />
              {`Name : ${
                user && user?.firstname && user?.lastname
                  ? capitalizeFirst(user?.firstname) +
                    " " +
                    capitalizeFirst(user?.lastname)
                  : "user"
              }`}
              <br />
              {/* {`Status : ${
                user && user?.isApproved === true
                  ? "Active"
                  : "Account not active"
              }`} */}
              {`Status : ${
                user && user?.isApproved === true ? "Active" : "Active"
              }`}
            </span>

            <FaRegUserCircle size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={5} lg={5}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #ff6347, #00018b)",
            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Refer & Earn
          </Typography>

          <Typography
            variant="h6"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "2",
              height: "74px",
            }}
          >
            <CopyToClipboard
              onCopy={otherCopy}
              options={{ message: "Whoa!" }}
              text={value}
            >
              <Button variant="warning">
                {copied ? (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    Copied.
                  </span>
                ) : (
                  <span style={{ color: "white", fontSize: "14px" }}>
                    Copy link
                  </span>
                )}
              </Button>
            </CopyToClipboard>

            <Button
              variant="success"
              onClick={handleShareLink}
              style={{ fontSize: "14px" }}
            >
              Share link
            </Button>

            <Button onClick={handleTelegramLink} style={{ fontSize: "14px" }}>
              Join Us
            </Button>

            <Button
              variant="info"
              onClick={handleCallNow}
              style={{ fontSize: "14px" }}
            >
              Call Now
            </Button>

            <PiShareFat size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={3}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #add8e6, #00008b)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Company Members
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
              height: "74px",
            }}
          >
            {`${totalUserCount ? totalUserCount : 0}`}
            <FaUserFriends size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #ff0000, #8b0000)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            My Current Wallet
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${currentWallet ? currentWallet : 0}`}
            <FaWallet size={40} />
          </Typography>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #ffff00, #8b8b00)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            My Direct Income
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${totalDirectJoining}`}
            <FaRupeeSign size={40} />
          </Typography>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #00ff00, #006400)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Spin & Win Income
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${totalSpinAndWin}`}
            <ImSpinner4 size={40} />
          </Typography>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to right, #333333, #666666)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Daily Ad Views Income
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${totalWatchVideo}`}
            <FaYoutube size={40} />
          </Typography>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #c77dff, #4b0082)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Telegram Join Income
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${totalTelegram}`}
            <FaTelegramPlane size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #2de0ab, #007d64)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Level Income
          </Typography>
          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${totalLevel}`}
            <SiLevelsdotfyi size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage: "linear-gradient(to left, #ffaf4b, #d16002)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            Rewards Income
          </Typography>

          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`₹ ${totalReward}`}

            <FaGifts size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard
          style={{
            backgroundImage:
              "linear-gradient(to left, #FF69B4, #FF1493, #C71585)",

            color: "#ffffff",
          }}
        >
          <Typography variant="h6" color="white">
            My Total Downline
          </Typography>
          <Typography
            variant="h4"
            color="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            {`${
              userDownlineList && userDownlineList?.totalDownlineCount
                ? userDownlineList?.totalDownlineCount
                : 0
            }`}
            <ImTree size={40} />
          </Typography>
        </MainCard>
      </Grid>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Grid item xs={12}>
          <Typography variant="h5" className="mb-3">
            My Downline
          </Typography>
        </Grid>

        <UserTable />

        {(!userDownlineList || !userDownlineList.last10DownlineUsers) && (
          <Typography variant="body2" className="d-flex justify-content-center">
            No downline found
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

Dashboard.propTypes = {
  getAllUsersCount: PropTypes.func.isRequired,
  getUserCurrentWallet: PropTypes.func.isRequired,
  getUserAllIncome: PropTypes.func.isRequired,
  getUserDownline: PropTypes.func.isRequired,
  getAdminAccountSettingsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  totalUserCount: state.dashboard.totalUserCount,
  currentWallet: state.dashboard.currentWallet,
  userIncomesList: state.dashboard.userIncomesList,
  userDownlineList: state.dashboard.userDownlineList,
  user: state.auth.user,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
});

export default connect(mapStateToProps, {
  getAllUsersCount,
  getAdminAccountSettingsList,
  getUserCurrentWallet,
  getUserAllIncome,
  getUserDownline,
})(Dashboard);
