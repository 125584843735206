import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import { validateForm } from "utils/validation";

import {
  updateProduct,
  cancelSave,
  setErrors,
  removeProductErrors,
  resetComponentStore,
  getProductById,
} from "actions/productActions";
import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const EditProduct = ({
  updateProduct,
  setErrors,
  removeProductErrors,
  loadingProduct,
  currentProduct,
  errorList,
  getProductById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { product_id } = useParams();

  const initialFormData = {
    productName: "",
    description: "",
    imageUrl: null,
    mrp: "",
    // discount: "",
    price: "",
    isActive: true,
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadProductFormData = (currentProduct) => {
    const {
      productName,
      description,
      mrp,
      // discount,
      price,
      imageUrl,
      isActive,
    } = currentProduct;

    const data = {
      productName,
      description,
      mrp,
      // discount,
      price,
      imageUrl,
      isActive,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentProduct) return;

    loadProductFormData(currentProduct);
  }, [currentProduct]);

  const {
    productName,
    description,
    mrp,
    //  discount,
    price,
    imageUrl,
    isActive,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, imageUrl: file });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const {
      productName,
      description,
      mrp,
      // discount,
      price,
      imageUrl,
      isActive,
    } = formData;

    const submitData = new FormData();
    submitData.append("productName", productName);
    submitData.append("description", description);
    submitData.append("mrp", mrp);
    // submitData.append("discount", discount);
    submitData.append("price", price);
    submitData.append("isActive", isActive);
    submitData.append("imageFile", imageUrl);

    setSubmitting(true);
    updateProduct(submitData, navigate, product_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useEffect(() => {
    if (!product_id) return;

    resetComponentStore();

    getProductById(product_id);
  }, []);

  return (
    <React.Fragment>
      {loadingProduct ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    Product Information
                  </Typography>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="productName">
                    Product Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="productName"
                    name="productName"
                    maxLength="150"
                    value={productName}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="description">Description*</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="description"
                    name="description"
                    maxLength="500"
                    value={description}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="imageUrl">Image Upload</Form.Label>
                  <Form.Control
                    type="file"
                    id="imageUrl"
                    name="imageUrl"
                    onChange={(e) => handleImageChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={productName}
                    style={{
                      width: "100px",
                      height: "100px",
                      marginBottom: "10px",
                    }}
                  />
                )}

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="mrp">MRP*</Form.Label>
                  <Form.Control
                    type="text"
                    id="mrp"
                    name="mrp"
                    maxLength="10"
                    value={mrp}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>

                {/* <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="discount">Discount*</Form.Label>
                  <Form.Control
                    type="text"
                    id="discount"
                    name="discount"
                    maxLength="3"
                    value={discount}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group> */}

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="price">Price*</Form.Label>
                  <Form.Control
                    type="text"
                    id="price"
                    name="price"
                    maxLength="10"
                    value={price}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="isActive">IsActive*</Form.Label>
                  <Form.Control
                    as="select"
                    id="isActive"
                    name="isActive"
                    value={isActive}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Form.Control>
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mx-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditProduct.propTypes = {
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingProduct: state.product.loadingProduct,
  currentProduct: state.product.currentProduct,
});

export default connect(mapStateToProps, {
  updateProduct,
  cancelSave,
  setErrors,
  removeProductErrors,
  resetComponentStore,
  getProductById,
  resetComponentStore,
})(EditProduct);
