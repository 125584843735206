import React from "react";
import UserEditBank from "./UserEditBank";
import UserEditUpi from "./UserEditUpi";
import Grid from "@mui/material/Grid";

const EditUserCredentials = () => {
  return (
    <Grid container spacing={2} className="mt-1">
      <Grid item xs={12} md={6}>
        <UserEditBank />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserEditUpi />
      </Grid>
    </Grid>
  );
};

export default EditUserCredentials;
