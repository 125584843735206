import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingAdminAccountSettingsList,
  adminAccountSettingListUpdated,
  resetAdminAccountSetting,
  adminAccountSettingError,
  loadingOnAdminAccountSettingSubmit,
  adminAccountSettingCreated,
  adminAccountSettingUpdated,
  adminAccountSettingDetailsById,
  adminAccountSettingDeleted,
  adminEarningLinksListUpdated,
  loadingAdminEarningLinksList,
  adminEarningLinkCreated,
  adminEarninglinksUpdated,
  adminEarningLinkDetailsById,
} from "reducers/adminAccountSettingReducer";

import { baseURL } from "constants/index";

export const getAdminAccountSettingsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingAdminAccountSettingsList());
    const res = await axios.get(
      `${baseURL}/api/admin/account-settings/list`,
      config
    );

    dispatch(adminAccountSettingListUpdated(res.data.response[0]));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          adminAccountSettingError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getAdminAccountSettingById =
  (accountSetting_id) => async (dispatch) => {
    dispatch(removeErrors());
    dispatch(loadingOnAdminAccountSettingSubmit());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `${baseURL}/api/admin/account-settings/${accountSetting_id}`,
        config
      );

      dispatch(adminAccountSettingDetailsById(res.data.response));
      return res.data ? res.data.response : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            adminAccountSettingError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "error"));
      }
    }
  };

export const createAdminAccountSetting =
  (formData, navigate) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnAdminAccountSettingSubmit());

      const res = await axios.post(
        `${baseURL}/api/admin/account-settings`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(adminAccountSettingCreated(res.data.response));
        dispatch(setAlert("AdminAccountSetting Created.", "success"));
        navigate(`/admin/admin-account-setting-list`);

        return res.data;
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(adminAccountSettingError());
          dispatch(setAlert(res.data.message, "error"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            adminAccountSettingError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "error"));
      }
    }
  };

export const updateAdminAccountSetting =
  (formData, navigate, accountSetting_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${baseURL}/api/admin/account-settings/${accountSetting_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(adminAccountSettingUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(adminAccountSettingError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            adminAccountSettingError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteAdminAccountSetting =
  (accountSetting_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.delete(
        `${baseURL}/api/admin/account-settings/${accountSetting_id}`,
        config
      );

      dispatch(adminAccountSettingDeleted(accountSetting_id));
      dispatch(setAlert("AdminAccountSetting deleted", "success"));
    } catch (err) {
      err.response &&
        dispatch(
          adminAccountSettingError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
    }
  };

// Earning Links Section

export const getAdminEarningLinksList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingAdminEarningLinksList());
    const res = await axios.get(
      `${baseURL}/api/admin/account-settings/earning-links/list`,
      config
    );

    dispatch(adminEarningLinksListUpdated(res.data.response[0]));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          adminAccountSettingError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createEarningLinks = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      `${baseURL}/api/admin/account-settings/earning-links`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(adminEarningLinkCreated(res.data.response));
      dispatch(setAlert("Admin Earning Link Created.", "success"));
      navigate(`/admin/admin-account-setting-list`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(adminAccountSettingError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          adminAccountSettingError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateEarningsLink =
  (formData, navigate, earningLink_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${baseURL}/api/admin/account-settings/earning-links/${earningLink_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(adminEarninglinksUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(adminAccountSettingError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            adminAccountSettingError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const getAdminEarningLinkById =
  (accountSetting_id) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `${baseURL}/api/admin/account-settings/earning-links/${accountSetting_id}`,
        config
      );

      dispatch(adminEarningLinkDetailsById(res.data.response));
      return res.data ? res.data.response : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            adminAccountSettingError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "error"));
      }
    }
  };

// reset errors
export const removeAdminAccountSettingErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetAdminAccountSetting());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(adminAccountSettingError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/admin-account-setting");
};
