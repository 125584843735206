import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingEarningsList,
  earningSearchParametersUpdate,
  earningListUpdated,
  resetEarning,
  earningError,
  loadingOnEarningSubmit,
  earningCreated,
  earningUpdated,
  earningDetailsById,
  earningDeleted,
} from "reducers/earningReducer";

import { baseURL } from "constants/index";

export const getEarningsList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingEarningsList());
    const res = await axios.get(
      `${baseURL}/api/admin/earnings/list/${loggedInUserID}`,
      config
    );

    dispatch(earningSearchParametersUpdate(appTokenParams));

    dispatch(earningListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          earningError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getEarningById = (earning_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnEarningSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/earnings/${earning_id}`,
      config
    );

    dispatch(earningDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          earningError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createEarning = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnEarningSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/earnings`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(earningCreated(res.data.response));
      dispatch(setAlert("Earning Created.", "success"));
      navigate(`/admin/earning-history`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(earningError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          earningError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateEarning =
  (formData, navigate, earning_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${baseURL}/api/admin/earnings/${earning_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(earningUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(earningError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            earningError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteEarning = (earning_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(`${baseURL}/api/admin/earnings/${earning_id}`, config);

    dispatch(earningDeleted(earning_id));
    dispatch(setAlert("Earning deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        earningError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeEarningErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetEarning());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(earningError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/earnings");
};
