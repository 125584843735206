import { BsQuestionCircle } from "react-icons/bs";
import { IoChatboxEllipsesOutline } from "react-icons/io5";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const UserQueryPath = {
  id: "group-userQuery",
  title: "",
  type: "group",
  children: [
    {
      id: "userQuery",
      title: "User Query",
      type: "item",
      icon: BsQuestionCircle,
      subChlidrens: [
        {
          id: "chatUs",
          title: "Chat Us",
          type: "item",
          url: "user-support",
          icon: IoChatboxEllipsesOutline,
        },
      ],
    },
  ],
};

export default UserQueryPath;
