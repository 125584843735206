import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

import { ImSpinner4 } from "react-icons/im";
import { FiYoutube } from "react-icons/fi";
import { LiaTelegramPlane } from "react-icons/lia";

import { getEarningsList, resetComponentStore } from "actions/earningActions";
import AdminEarningFilters from "./AdminEarningFilters";
import { RiUserSharedFill } from "react-icons/ri";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const EarningHistory = ({
  earningsList: { data, count },
  getEarningsList,
  loadingEarningsList,
  resetComponentStore,
  sortingParams,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [filterValues, setFilterValues] = React.useState({
    startDate: "",
    endDate: "",
    earningType: "",
  });

  const columns = [
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: false,
      width: "15%",
    },
    {
      name: "Email",
      cell: (row) => row.email,
      sortable: false,
      width: "25%",
    },
    {
      name: "Phone",
      cell: (row) => row.phone,
      sortable: false,
      width: "15%",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
      width: "12%",
      wrap: true,
    },

    {
      name: "Earning type",
      selector: (row) => {
        return row.earning_type === "spin_and_win" ? (
          <div className="d-flex align-items-center gap-2">
            <ImSpinner4 /> Spin and Win
          </div>
        ) : row.earning_type === "watch_video" ? (
          <div className="d-flex align-items-center gap-2">
            <FiYoutube /> Add View
          </div>
        ) : row.earning_type === "direct_joining" ? (
          <div className="d-flex align-items-center gap-2">
            <RiUserSharedFill /> Direct joining
          </div>
        ) : row.earning_type === "approval" ? (
          <div className="d-flex align-items-center gap-2">
            <IoMdCheckmarkCircleOutline /> Approval
          </div>
        ) : row.earning_type === "telegram" ? (
          <div className="d-flex align-items-center gap-2">
            <LiaTelegramPlane /> Telegram
          </div>
        ) : (
          row.earning_type
        );
      },
      sortable: false,
      width: "18%",
      wrap: true,
    },

    {
      name: "Date",
      selector: (row) =>
        row.createdAt
          ? moment(row.createdAt).format("hh:mm a, DD-MM-YYYY")
          : "",
      sortable: false,
      width: "15%",
      wrap: true,
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getEarningsList(clientParams);
  }, [getEarningsList, clientParams, resetComponentStore]);

  const handleSearch = (
    fromDate,
    toDate,
    earningType,
    searchType,
    searchValue
  ) => {
    getEarningsList({
      fromDate,
      toDate,
      earningType,
      searchType,
      searchValue,
      ...clientParams,
    });
  };

  const totalAmount = data.reduce((acc, row) => acc + row.amount, 0);

  const totalRow = {
    amount: `Total : ${totalAmount}`,
  };

  const tableData = [...data, totalRow];

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Earning History"
        crumbs={[{ name: "Earning History" }]}
      />
      <div className="table-filter-section mb-4">
        <AdminEarningFilters onSearch={handleSearch} />
      </div>

      <Card>
        <Card.Body>
          <PiDataTable
            columns={columns}
            data={tableData}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingEarningsList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

EarningHistory.propTypes = {
  getEarningsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  earningsList: state.earning.earningsList,
  loadingEarningsList: state.earning.loadingEarningsList,
  sortingParams: state.earning.sortingParams,
});

export default connect(mapStateToProps, {
  getEarningsList,
  resetComponentStore,
})(EarningHistory);
