import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  ordersList: {
    page: 1,
    data: [],
    count: 0,
  },
  userOrdersList: {
    page: 1,
    orderData: [],
    count: 0,
  },
  adminAccountsList: {
    data: [],
  },
  currentOrder: null,
  loadingOrdersList: true,
  loadingOrder: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const userOrderSlice = createSlice({
  name: "userOrder",
  initialState: initialState,
  reducers: {
    loadingOrdersList(state) {
      return {
        ...state,
        loadingOrdersList: true,
      };
    },
    orderSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingOrdersList: false,
      };
    },
    orderListUpdated(state, action) {
      return {
        ...state,
        ordersList: {
          data: action.payload,
        },
        loadingOrdersList: false,
      };
    },
    userOrderListUpdated(state, action) {
      return {
        ...state,
        userOrdersList: {
          orderData: action.payload,
        },
        loadingOrdersList: false,
      };
    },

    adminAccountListUpdated(state, action) {
      return {
        ...state,
        adminAccountsList: {
          data: action.payload,
        },
        loadingOrdersList: false,
      };
    },

    orderDetailsById(state, action) {
      return {
        ...state,
        currentOrder: action.payload,
        loadingOrder: false,
      };
    },
    resetOrder(state) {
      return {
        ...initialState,
      };
    },

    orderError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingOrder: false,
        loadingOrdersList: false,
      };
    },
    loadingOnOrderSubmit(state) {
      return {
        ...state,
        loadingOrder: true,
      };
    },
    orderCreated(state) {
      return {
        ...state,
        loadingOrder: false,
      };
    },
    orderUpdated(state, action) {
      return {
        ...state,
        ordersList: {
          ...state.ordersList,
          data: state.ordersList.data.map((approval) =>
            approval._id === action.payload._id ? action.payload : approval
          ),
        },
      };
    },
    orderDeleted(state, action) {
      const currentCount = state.ordersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.ordersList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        ordersList: {
          data: state.ordersList.data.filter(
            (approval) => approval._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingOrdersList: false,
      };
    },
  },
});
export const {
  loadingOrdersList,
  orderSearchParametersUpdate,
  orderListUpdated,
  adminAccountListUpdated,
  resetOrder,
  orderError,
  loadingOnOrderSubmit,
  userOrderListUpdated,
  orderCreated,
  orderUpdated,
  orderDetailsById,
  orderDeleted,
} = userOrderSlice.actions;
export default userOrderSlice.reducer;
