import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import { validateForm } from "utils/validation";

import {
  updateQRScanner,
  cancelSave,
  setErrors,
  removeQRScannerErrors,
  resetComponentStore,
  getQRScannerById,
} from "actions/qrScannerActions";

import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const EditQRScanner = ({
  updateQRScanner,
  setErrors,
  removeQRScannerErrors,
  loadingQRScanner,
  currentQRScanner,
  errorList,
  getQRScannerById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { scanner_id } = useParams();

  const initialFormData = {
    title: "",
    imageUrl: null,
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadQRScannerFormData = (currentQRScanner) => {
    const { title, imageUrl } = currentQRScanner;

    const data = {
      title,
      imageUrl,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentQRScanner) return;

    loadQRScannerFormData(currentQRScanner);
  }, [currentQRScanner]);

  const { title, imageUrl } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    const { name, value, files } = e.target;

    if (name === "imageUrl" && files.length > 0) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeQRScannerErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }
    const { title, imageUrl } = formData;

    const submitData = new FormData();
    submitData.append("title", title);

    if (imageUrl) {
      submitData.append("imageFile", imageUrl);
    }

    setSubmitting(true);
    updateQRScanner(submitData, navigate, scanner_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useEffect(() => {
    if (!scanner_id) return;
    resetComponentStore();

    getQRScannerById(scanner_id);
  }, [scanner_id]);

  return (
    <React.Fragment>
      {loadingQRScanner ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    QR Scanner Information
                  </Typography>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="title">
                    Title<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="title"
                    name="title"
                    maxLength="150"
                    value={title}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="imageUrl">Image URL*</Form.Label>
                  <div>
                    {imageUrl && isDisabled && (
                      <img
                        src={imageUrl}
                        alt="Preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    {!isDisabled && (
                      <Form.Control
                        type="file"
                        id="imageUrl"
                        name="imageUrl"
                        maxLength="150"
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                    )}
                  </div>
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="px-3"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="m-2 "
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditQRScanner.propTypes = {
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingQRScanner: state.qrScanner.loadingQRScanner,
  currentQRScanner: state.qrScanner.currentQRScanner,
});

export default connect(mapStateToProps, {
  updateQRScanner,
  cancelSave,
  setErrors,
  removeQRScannerErrors,
  resetComponentStore,
  getQRScannerById,
  resetComponentStore,
})(EditQRScanner);
