import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";

import Errors from "Notifications/Errors";
// assets

import AnimateButton from "../../../../AnimateButton";
import MainCard from "View/Admin/Components/MainCard";
import Spinner from "View/Spinners/Spinner";
import Form from "react-bootstrap/Form";

import {
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  // updateAdminAccountSetting,
} from "actions/adminAccountSettingActions";

const SetDefaultValues = ({
  errorList,
  loadingAdminAccountSetting,
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  adminAccountSettingsList: { data },
  // updateAdminAccountSetting,
  loadingAdminAccountSettingsList,
}) => {
  const initialState = {
    projectName: "",
    email: "",
    mobileNumber: "",
    whatsAppNumber: "",
    adminTelegramLink: "",
    setYoutubeVideoIncome: "",
    setTelegramIncome: "",
    spinSlab: "",
    spinAmmountToMultiply: "",
    registrationBonus: "",
    minimumWithdrawal: "",
    maximumWithdrawal: "",
    minimumDeposit: "",
    defaultApprovalAmount: "",
    alertText: "",
    howToWithdrawalLink: "",
    withdrawalNotification: "",
    alertComponent: "",
    withdrawalIsClosed: "",
  };

  // const navigate = useNavigate();

  // const [formData, setFormData] = useState(initialState);
  const [formData, setFormData] = useState({
    ...initialState,
    ...(data && data.length > 0 ? data[0] : {}),
  });

  const onChange = (e) => {
    if (!e) return;

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      removeAdminAccountSettingErrors();
      // Your form submission logic here
      setStatus({ success: true });
      setSubmitting(false);

      // if (data && data.length > 0) {
      //   updateAdminAccountSetting(values, navigate, data[0]._id).then((res) => {
      //     if (!res.status) return;
      //   });
      // }

      // createAdminAccountSetting(values, navigate).then((res) => {
      //   if (!res.status) return;
      // });
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    getAdminAccountSettingsList();
  }, [getAdminAccountSettingsList]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data[0],
      }));
    }
  }, [data]);
  return (
    <React.Fragment>
      {loadingAdminAccountSetting || loadingAdminAccountSettingsList ? (
        <Spinner />
      ) : (
        <Grid item xs={12}>
          <MainCard>
            <Formik
              initialValues={formData}
              validationSchema={Yup.object().shape({
                projectName: Yup.string()
                  .max(255)
                  .required("Project Name is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),

                mobileNumber: Yup.string()
                  .matches(/^\d+$/, "Phone number must contain only digits")
                  .min(10, "Phone number must be at least 10 characters")
                  .max(15, "Phone number must not exceed 15 characters")
                  .required("Phone number is required"),

                whatsAppNumber: Yup.string()
                  .matches(/^\d+$/, "Whatsapp number must contain only digits")
                  .min(10, "Whatsapp number must be at least 10 characters")
                  .max(15, "Whatsapp number must not exceed 15 characters")
                  .required("Whatsapp number is required"),

                adminTelegramLink: Yup.string()
                  .max(255)
                  .required("Admin telegram link is required"),

                setYoutubeVideoIncome: Yup.string()
                  .max(5)
                  .required("setYoutubeVideoIncome is required"),

                setTelegramIncome: Yup.string()
                  .max(5)
                  .required("setTelegramIncome is required"),

                spinSlab: Yup.string().max(2).required("Spin Slab is required"),
                spinAmmountToMultiply: Yup.string()
                  .max(5)
                  .required("Spin Amount To Multiply is required"),

                registrationBonus: Yup.string()
                  .max(5)
                  .required("Registration Bonus is required"),
                minimumWithdrawal: Yup.string()
                  .max(5)
                  .required("Minimum Withdrawal Amount is required"),

                maximumWithdrawal: Yup.string()
                  .max(8)
                  .required("Minimum Withdrawal Amount is required"),

                minimumDeposit: Yup.string()
                  .max(5)
                  .required("Minimum Deposit Amount is required"),

                defaultApprovalAmount: Yup.string()
                  .max(5)
                  .required("Default Approval Amount is required"),
                alertText: Yup.string()
                  .max(255)
                  .required("Alert Text is required"),

                howToWithdrawalLink: Yup.string()
                  .max(255)
                  .required("How To Withdrawal link is required"),
                withdrawalNotification: Yup.string()
                  .max(255)
                  .required("Withdrawal Notification is required"),

                withdrawalIsClosed: Yup.string().required(
                  "withdrawalIsClosed is required"
                ),
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => {
                const yupErrors = {};
                if (errors) {
                  Object.keys(errors).forEach((key) => {
                    if (touched[key]) {
                      yupErrors[key] = errors[key];
                    }
                  });
                }

                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="projectName">
                            Project Name*
                          </InputLabel>
                          <OutlinedInput
                            id="projectName"
                            type="text"
                            value={values.projectName}
                            name="projectName"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            placeholder="First Name"
                            fullWidth
                            error={Boolean(
                              touched.projectName &&
                                (errors.projectName ||
                                  (errorList && errorList.projectName))
                            )}
                          />

                          {touched.projectName &&
                            (errors.projectName ||
                              yupErrors?.projectName ||
                              (errorList && errorList.projectName)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="projectName"
                                id="projectName"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="email">
                            Email Address*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.email &&
                                (errors.email || (errorList && errorList.email))
                            )}
                            id="email"
                            type="email"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            placeholder="Enter your email"
                            inputProps={{}}
                          />

                          {touched.email &&
                            (errors.email ||
                              yupErrors?.email ||
                              (errorList && errorList.email)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="email"
                                id="email"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="mobileNumber">
                            Mobile Number
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.mobileNumber &&
                                (errors.mobileNumber ||
                                  (errorList && errorList.mobileNumber))
                            )}
                            id="mobileNumber"
                            type="tel"
                            value={values.mobileNumber}
                            name="mobileNumber"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Mobile number"
                            inputProps={{ maxLength: 10 }}
                          />

                          {touched.mobileNumber &&
                            (errors.mobileNumber ||
                              yupErrors?.mobileNumber ||
                              (errorList && errorList.mobileNumber)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="mobileNumber"
                                id="mobileNumber"
                              />
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="whatsAppNumber">
                            Whatsapp Number
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.whatsAppNumber &&
                                (errors.whatsAppNumber ||
                                  (errorList && errorList.whatsAppNumber))
                            )}
                            id="whatsAppNumber"
                            type="text"
                            value={values.whatsAppNumber}
                            name="whatsAppNumber"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Whatsapp number "
                            inputProps={{}}
                          />

                          {touched.whatsAppNumber &&
                            (errors.whatsAppNumber ||
                              yupErrors?.whatsAppNumber ||
                              (errorList && errorList.whatsAppNumber)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="whatsAppNumber"
                                id="whatsAppNumber"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="adminTelegramLink">
                            Telegram Channel Link*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.adminTelegramLink &&
                                (errors.adminTelegramLink ||
                                  (errorList && errorList.adminTelegramLink))
                            )}
                            id="adminTelegramLink"
                            type="text"
                            value={values.adminTelegramLink}
                            name="adminTelegramLink"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            placeholder="Enter Telegram Link"
                            inputProps={{}}
                          />

                          {touched.adminTelegramLink &&
                            (errors.adminTelegramLink ||
                              yupErrors?.adminTelegramLink ||
                              (errorList && errorList.adminTelegramLink)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="adminTelegramLink"
                                id="adminTelegramLink"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="minimumDeposit">
                            Minimum Deposit*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.minimumDeposit &&
                                (errors.minimumDeposit ||
                                  (errorList && errorList.minimumDeposit))
                            )}
                            id="minimumDeposit"
                            type="text"
                            value={values.minimumDeposit}
                            name="minimumDeposit"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Minimum Deposit Amount"
                            inputProps={{}}
                          />

                          {touched.minimumDeposit &&
                            (errors.minimumDeposit ||
                              yupErrors?.minimumDeposit ||
                              (errorList && errorList.minimumDeposit)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="minimumDeposit"
                                id="minimumDeposit"
                              />
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="minimumWithdrawal">
                            Minimum Withdrawal
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.minimumWithdrawal &&
                                (errors.minimumWithdrawal ||
                                  (errorList && errorList.minimumWithdrawal))
                            )}
                            id="minimumWithdrawal"
                            type="text"
                            value={values.minimumWithdrawal}
                            name="minimumWithdrawal"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Minimum Withdrawal Amount "
                            inputProps={{}}
                          />

                          {touched.minimumWithdrawal &&
                            (errors.minimumWithdrawal ||
                              yupErrors?.minimumWithdrawal ||
                              (errorList && errorList.minimumWithdrawal)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="minimumWithdrawal"
                                id="minimumWithdrawal"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="maximumWithdrawal">
                            Maximum Withdrawal*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.maximumWithdrawal &&
                                (errors.maximumWithdrawal ||
                                  (errorList && errorList.maximumWithdrawal))
                            )}
                            id="maximumWithdrawal"
                            type="text"
                            value={values.maximumWithdrawal}
                            name="maximumWithdrawal"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Maximum Withdrawal Amount "
                            inputProps={{}}
                          />

                          {touched.maximumWithdrawal &&
                            (errors.maximumWithdrawal ||
                              yupErrors?.maximumWithdrawal ||
                              (errorList && errorList.maximumWithdrawal)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="maximumWithdrawal"
                                id="maximumWithdrawal"
                              />
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="registrationBonus">
                            Registration Bonus
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.registrationBonus &&
                                (errors.registrationBonus ||
                                  (errorList && errorList.registrationBonus))
                            )}
                            id="registrationBonus"
                            type="text"
                            value={values.registrationBonus}
                            name="registrationBonus"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Registration Bonus number "
                            inputProps={{}}
                          />

                          {touched.registrationBonus &&
                            (errors.registrationBonus ||
                              yupErrors?.registrationBonus ||
                              (errorList && errorList.registrationBonus)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="registrationBonus"
                                id="registrationBonus"
                              />
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="spinAmmountToMultiply">
                            Spin Amount To Multiply
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.spinAmmountToMultiply &&
                                (errors.spinAmmountToMultiply ||
                                  (errorList &&
                                    errorList.spinAmmountToMultiply))
                            )}
                            id="spinAmmountToMultiply"
                            type="text"
                            value={values.spinAmmountToMultiply}
                            name="spinAmmountToMultiply"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Set Amount To Multiply"
                            inputProps={{}}
                          />

                          {touched.spinAmmountToMultiply &&
                            (errors.spinAmmountToMultiply ||
                              yupErrors?.spinAmmountToMultiply ||
                              (errorList &&
                                errorList.spinAmmountToMultiply)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="spinAmmountToMultiply"
                                id="spinAmmountToMultiply"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="spinSlab">Spin Slab</InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.spinSlab &&
                                (errors.spinSlab ||
                                  (errorList && errorList.spinSlab))
                            )}
                            id="spinSlab"
                            type="text"
                            value={values.spinSlab}
                            name="spinSlab"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Set Spin Slab "
                            inputProps={{}}
                          />

                          {touched.spinSlab &&
                            (errors.spinSlab ||
                              yupErrors?.spinSlab ||
                              (errorList && errorList.spinSlab)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="spinSlab"
                                id="spinSlab"
                              />
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="setYoutubeVideoIncome">
                            Set Youtube Video Income*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.setYoutubeVideoIncome &&
                                (errors.setYoutubeVideoIncome ||
                                  (errorList &&
                                    errorList.setYoutubeVideoIncome))
                            )}
                            id="setYoutubeVideoIncome"
                            type="text"
                            value={values.setYoutubeVideoIncome}
                            name="setYoutubeVideoIncome"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Set Youtube Video Income "
                            inputProps={{}}
                          />

                          {touched.setYoutubeVideoIncome &&
                            (errors.setYoutubeVideoIncome ||
                              yupErrors?.setYoutubeVideoIncome ||
                              (errorList &&
                                errorList.setYoutubeVideoIncome)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="setYoutubeVideoIncome"
                                id="setYoutubeVideoIncome"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="setTelegramIncome">
                            Set Telegram Income*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.setTelegramIncome &&
                                (errors.setTelegramIncome ||
                                  (errorList && errorList.setTelegramIncome))
                            )}
                            id="setTelegramIncome"
                            type="text"
                            value={values.setTelegramIncome}
                            name="setTelegramIncome"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Set Youtube Video Income "
                            inputProps={{}}
                          />

                          {touched.setTelegramIncome &&
                            (errors.setTelegramIncome ||
                              yupErrors?.setTelegramIncome ||
                              (errorList && errorList.setTelegramIncome)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="setTelegramIncome"
                                id="setTelegramIncome"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="defaultApprovalAmount">
                            Set Default Approval Amount
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.defaultApprovalAmount &&
                                (errors.defaultApprovalAmount ||
                                  (errorList &&
                                    errorList.defaultApprovalAmount))
                            )}
                            id="defaultApprovalAmount"
                            type="text"
                            value={values.defaultApprovalAmount}
                            name="defaultApprovalAmount"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="Set Youtube Video Income "
                            inputProps={{}}
                          />

                          {touched.defaultApprovalAmount &&
                            (errors.defaultApprovalAmount ||
                              yupErrors?.defaultApprovalAmount ||
                              (errorList &&
                                errorList.defaultApprovalAmount)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="defaultApprovalAmount"
                                id="defaultApprovalAmount"
                              />
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="alertText">
                            Alert Text*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.alertText &&
                                (errors.alertText ||
                                  (errorList && errorList.alertText))
                            )}
                            id="alertText"
                            type="text"
                            value={values.alertText}
                            name="alertText"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            placeholder="Enter Alert Text"
                            inputProps={{}}
                          />

                          {touched.alertText &&
                            (errors.alertText ||
                              yupErrors?.alertText ||
                              (errorList && errorList.alertText)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="alertText"
                                id="alertText"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="howToWithdrawalLink">
                            How To Withdrawal Link*
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            error={Boolean(
                              touched.howToWithdrawalLink &&
                                (errors.howToWithdrawalLink ||
                                  (errorList && errorList.howToWithdrawalLink))
                            )}
                            id="howToWithdrawalLink"
                            type="text"
                            value={values.howToWithdrawalLink}
                            name="howToWithdrawalLink"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            placeholder="Enter how to withdrawal link"
                            inputProps={{}}
                          />

                          {touched.howToWithdrawalLink &&
                            (errors.howToWithdrawalLink ||
                              yupErrors?.howToWithdrawalLink ||
                              (errorList && errorList.howToWithdrawalLink)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="howToWithdrawalLink"
                                id="howToWithdrawalLink"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="withdrawalNotification">
                            Withdrawal Notification*
                          </InputLabel>
                          <TextareaAutosize
                            fullWidth
                            minRows={3}
                            maxRows={5}
                            id="withdrawalNotification"
                            placeholder="Enter Withdrawal Notification"
                            value={values.withdrawalNotification}
                            name="withdrawalNotification"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                          />
                          {touched.withdrawalNotification &&
                            (errors.withdrawalNotification ||
                              yupErrors?.withdrawalNotification ||
                              (errorList &&
                                errorList.withdrawalNotification)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="withdrawalNotification"
                                id="withdrawalNotification"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <InputLabel>Is Withdrawal Working</InputLabel>
                          <RadioGroup
                            id="withdrawalIsClosed"
                            name="withdrawalIsClosed"
                            value={values.withdrawalIsClosed}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>

                          {touched.withdrawalIsClosed &&
                            (errors.withdrawalIsClosed ||
                              yupErrors?.withdrawalIsClosed ||
                              (errorList && errorList.withdrawalIsClosed)) && (
                              <Errors
                                errorList={errorList}
                                errors={errors}
                                yupErrors={yupErrors}
                                currentKey="withdrawalIsClosed"
                                id="withdrawalIsClosed"
                              />
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Spin And Win Terms</Form.Label>
                              <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                          </Form>
                        </Stack>
                      </Grid>

                      {errors.submit && (
                        <Grid item xs={12}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Grid>
                      )}

                      <Grid container justifyContent="flex-end" spacing={2}>
                        {/* <Grid item>
                      <AnimateButton>
                        <Button
                          disableElevation
                          disabled={isSubmitting}
                          fullWidth
                          size="medium"
                          type="submit"
                          variant="contained"
                          color="error"
                        >
                          Cancel
                        </Button>
                      </AnimateButton>
                    </Grid> */}
                        <Grid item>
                          <AnimateButton>
                            <Button
                              disableElevation
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Submit
                            </Button>
                          </AnimateButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </MainCard>
        </Grid>
      )}
    </React.Fragment>
  );
};

SetDefaultValues.propTypes = {
  // createAdminAccountSetting: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  getAdminAccountSettingsList: PropTypes.func.isRequired,
  // updateAdminAccountSetting: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingAdminAccountSetting: state.accountSetting.loadingAdminAccountSetting,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
  loadingAdminAccountSettingsList:
    state.accountSetting.loadingAdminAccountSettingsList,
});

export default connect(mapStateToProps, {
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  // updateAdminAccountSetting,
})(SetDefaultValues);
