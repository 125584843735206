import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, List, Typography } from "@mui/material";
import NavItem from "./NavItem";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem, i) => {
    switch (menuItem.type) {
      case "collapse":
        return (
          <Typography
            key={`${menuItem.id}-uni1-${i}`}
            variant="caption"
            color="error"
            sx={{ p: 2.5 }}
          >
            collapse - only available in paid version
          </Typography>
        );
      case "item":
        return (
          <>
            <NavItem
              key={`${menuItem.id}-uni2-${i}`}
              item={menuItem}
              children={menuItem.subChlidrens}
              level={1}
            />
          </>
        );
      default:
        return (
          <Typography
            key={`default-uni3-${i}`}
            variant="h6"
            color="error"
            align="center"
          >
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
        )
      }
      sx={{ py: 0, zIndex: 0 }}
      className="borderDrawer"
    >
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
