import React from "react";

// material-ui
import { Grid } from "@mui/material";
import BanksList from "./Bank/BanksList";
import UpisList from "./UPI/UpisList";
import QRScannerList from "./QRScanner/QRScannerList";
import { connect } from "react-redux";
import { isAdmin } from "utils/helper";

const CredentialsListIndex = ({ user }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BanksList />
        </Grid>
        <Grid item xs={12}>
          <UpisList />
        </Grid>
        {isAdmin(user) ? (
          <Grid item xs={12}>
            <QRScannerList />
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(CredentialsListIndex);
