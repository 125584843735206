import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Stack, FormHelperText } from "@mui/material";

const Errors = ({ errorList, errors, currentKey, yupErrors, id = "" }) => {
  let errorMessage;
  if (
    (errorList && errorList[currentKey]) ||
    (errors && errors[currentKey]) ||
    (yupErrors && yupErrors[currentKey])
  ) {
    errorMessage =
      errorList[currentKey] || errors[currentKey] || yupErrors[currentKey];
  }

  return (
    <Stack>
      {errorMessage && (
        <FormHelperText error id={`helper-text-${id ? id : ""}`}>
          {errorMessage ? errorMessage : ""}
        </FormHelperText>
      )}
    </Stack>
  );
};

Errors.propTypes = {
  errorList: PropTypes.object.isRequired,
  yupErrors: PropTypes.object.isRequired,
  currentKey: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps)(Errors);
