import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingWalletHistoriesList,
  walletHistorySearchParametersUpdate,
  walletHistoryListUpdated,
  resetWalletHistory,
  walletHistoryError,
  loadingOnWalletHistorySubmit,
  walletHistoryCreated,
  walletHistoryUpdated,
  walletHistoryDetailsById,
  walletHistoryDeleted,
} from "reducers/walletHistoryReducer";

import { baseURL } from "constants/index";

export const getWalletHistoriesList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingWalletHistoriesList());
    const res = await axios.get(
      `${baseURL}/api/admin/wallet-histories/list/${loggedInUserID}`,
      config
    );

    dispatch(walletHistorySearchParametersUpdate(appTokenParams));

    dispatch(walletHistoryListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          walletHistoryError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

// reset errors
export const removeWalletHistoryErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetWalletHistory());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(walletHistoryError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/wallet-histories");
};
