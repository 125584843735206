import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Errors from "Notifications/Errors";
import AnimateButton from "../Components/AnimateButton";
import MainCard from "View/Admin/Components/MainCard";
import Spinner from "View/Spinners/Spinner";

import {
  createProduct,
  removeProductErrors,
  resetComponentStore,
} from "actions/productActions";
import AppBreadCrumb from "View/DataTable/AppBreadCrumb";

const CreateProduct = ({
  errorList,
  loadingProduct,
  createProduct,
  removeProductErrors,
  resetComponentStore,
}) => {
  const initialState = {
    productName: "",
    description: "",
    imageUrl: null,
    mrp: "",
    // discount: "",
    price: "",
    isActive: true,
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      removeProductErrors();
      setStatus({ success: true });
      setSubmitting(false);

      const {
        productName,
        description,
        mrp,
        // discount,
        price,
        imageUrl,
        isActive,
      } = values;

      const updatedFormdata = new FormData();
      updatedFormdata.append("productName", productName);
      updatedFormdata.append("description", description);
      updatedFormdata.append("imageFile", imageUrl);
      updatedFormdata.append("mrp", mrp);
      // updatedFormdata.append("discount", discount);
      updatedFormdata.append("price", price);
      updatedFormdata.append("isActive", isActive);

      createProduct(updatedFormdata, navigate).then((res) => {
        if (!res.status) return;
      });
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {loadingProduct ? (
        <Spinner />
      ) : (
        <Grid item xs={12}>
          <AppBreadCrumb
            pageTitle="Product Create"
            crumbs={[{ name: "Product Create" }]}
          />
          <MainCard>
            <Formik
              initialValues={formData}
              validationSchema={Yup.object().shape({
                productName: Yup.string()
                  .max(150)
                  .required("Product Name is required"),
                description: Yup.string()
                  .max(500)
                  .required("Description is required"),

                imageUrl: Yup.mixed()
                  .test(
                    "fileType",
                    "Invalid file type, please upload a JPG or PNG file",
                    (value) => {
                      if (!value) return false;
                      return (
                        value &&
                        ["image/jpeg", "image/png"].includes(value.type)
                      );
                    }
                  )
                  .test(
                    "fileSize",
                    "File size should not exceed 1 MB",
                    (value) => !value || (value && value.size <= 1024 * 1024)
                  )
                  .required("Image is required"),

                mrp: Yup.number()
                  .min(0, "MRP must be greater than or equal to 0")
                  .required("MRP is required"),
                // discount: Yup.number()
                //   .min(0, "Discount must be greater than or equal to 0")
                //   .max(100, "Discount must be less than or equal to 100")
                //   .required("Discount is required"),
                price: Yup.number()
                  .min(0, "Price must be greater than or equal to 0")
                  .required("Price is required"),
                isActive: Yup.boolean().required("isActive is required"),
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="productName">
                          Product Name*
                        </InputLabel>
                        <OutlinedInput
                          id="productName"
                          type="text"
                          value={values.productName}
                          name="productName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Product Name"
                          fullWidth
                          error={Boolean(
                            touched.productName && errors.productName
                          )}
                        />
                        {touched.productName && errors.productName && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="productName"
                            id="productName"
                          />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="description">
                          Description*
                        </InputLabel>
                        <textarea
                          id="description"
                          value={values.description}
                          name="description"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Description"
                          fullWidth
                          rows={4}
                          maxLength={500}
                          style={{ resize: "vertical" }}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                        />
                        {touched.description && errors.description && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="description"
                            id="description"
                          />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="imageUrl">
                          Image Upload*
                        </InputLabel>
                        <input
                          id="imageUrl"
                          type="file"
                          accept=".jpg,.png"
                          onChange={(event) => {
                            setFieldValue(
                              "imageUrl",
                              event.currentTarget.files[0]
                            );
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          error={Boolean(touched.imageUrl && errors.imageUrl)}
                        />
                        {touched.imageUrl && errors.imageUrl && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="imageUrl"
                            id="imageUrl"
                          />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="mrp">MRP*</InputLabel>
                        <OutlinedInput
                          id="mrp"
                          type="text"
                          value={values.mrp}
                          name="mrp"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="MRP"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          error={Boolean(touched.mrp && errors.mrp)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {touched.mrp && errors.mrp && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="mrp"
                            id="mrp"
                          />
                        )}
                      </Stack>
                    </Grid>

                    {/* <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="discount">Discount*</InputLabel>
                        <OutlinedInput
                          id="discount"
                          type="text"
                          value={values.discount}
                          name="discount"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Discount"
                          fullWidth
                          inputProps={{ maxLength: 3 }}
                          error={Boolean(touched.discount && errors.discount)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {touched.discount && errors.discount && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="discount"
                            id="discount"
                          />
                        )}
                      </Stack>
                    </Grid> */}

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="price">Price*</InputLabel>
                        <OutlinedInput
                          id="price"
                          type="text"
                          value={values.price}
                          name="price"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Price"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          error={Boolean(touched.price && errors.price)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {touched.price && errors.price && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            currentKey="price"
                            id="price"
                          />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="isActive">Active*</InputLabel>
                        <Select
                          id="isActive"
                          value={values.isActive}
                          name="isActive"
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </Stack>
                    </Grid>

                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <AnimateButton>
                          <Button
                            disableElevation
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </AnimateButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </MainCard>
        </Grid>
      )}
    </React.Fragment>
  );
};

CreateProduct.propTypes = {
  createProduct: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingProduct: state.product.loadingProduct,
});

export default connect(mapStateToProps, {
  createProduct,
  removeProductErrors,
  resetComponentStore,
})(CreateProduct);
