import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaRegEye } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

import BankDetailsModal from "./BankDetailsModal";

// material-ui
import { Grid } from "@mui/material";

import {
  getBanksList,
  resetComponentStore,
  deleteBank,
} from "actions/bankActions";
import AnimateButton from "View/Admin/Components/AnimateButton";

import { isAdmin } from "utils/helper";
import { Button } from "react-bootstrap";

const BanksList = ({
  banksList: { data, count },
  getBanksList,
  loadingBanksList,
  resetComponentStore,
  sortingParams,
  deleteBank,
  user,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [showModal, setShowModal] = React.useState(false);
  const [selectedBank, setSelectedBank] = React.useState(null);

  const openModal = (bank) => {
    setSelectedBank(bank);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedBank(null);
  };

  const actions = (
    <Grid container spacing={2}>
      <Grid item xs={2} className="mb-3">
        <Link to="/admin/credentials/banks/create" title="create">
          <AnimateButton>
            <Button fullWidth size="small" type="button" variant="primary">
              Create Account
            </Button>
          </AnimateButton>
        </Link>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row.accountHolderName,
      sortable: false,
      width: "30%",
    },
    {
      name: "Bank Name",
      selector: (row) => row.bankName,
      sortable: false,
      width: "25%",
      wrap: true,
    },

    {
      name: "branch",
      selector: (row) => row.branch,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "25%",
      button: true,
      cell: (row) => (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6} sm={3} lg={2}>
            {isAdmin(user) ? (
              <Link
                to={`/admin/credentials/bank/${row._id}/edit`}
                title="View Bank"
              >
                <Button
                  type="submit"
                  variant="primary"
                  color="primary"
                  size="sm"
                >
                  <FaRegEye />
                </Button>
              </Link>
            ) : (
              <Button
                size="sm"
                type="submit"
                variant="link"
                onClick={() => openModal(row)}
              >
                <FaRegEye />
              </Button>
            )}
          </Grid>

          {isAdmin(user) ? (
            <Grid item xs={6} sm={2} lg={2}>
              <Button
                size="sm"
                type="submit"
                variant="danger"
                onClick={(e) => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete: ${row.accountHolderName}?`
                    )
                  ) {
                    deleteBank(row._id);
                  }
                }}
              >
                <BiTrash />
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getBanksList(clientParams);
  }, [getBanksList, clientParams, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Banks" crumbs={[{ name: "Banks" }]} />

      {showModal && selectedBank && (
        <BankDetailsModal bankDetails={selectedBank} onHide={closeModal} />
      )}

      <Card>
        <Card.Body>
          {data && data.length ? null : actions}

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingBanksList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

BanksList.propTypes = {
  getBanksList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  banksList: state.bank.banksList,
  loadingBanksList: state.bank.loadingBanksList,
  sortingParams: state.bank.sortingParams,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getBanksList,
  resetComponentStore,
  deleteBank,
})(BanksList);
