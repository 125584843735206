import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  walletHistoriesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentWalletHistory: null,
  loadingWalletHistoriesList: true,
  loadingWalletHistory: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const walletHistorySlice = createSlice({
  name: "walletHistory",
  initialState: initialState,
  reducers: {
    loadingWalletHistoriesList(state) {
      return {
        ...state,
        loadingWalletHistoriesList: true,
      };
    },
    walletHistorySearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingWalletHistoriesList: false,
      };
    },
    walletHistoryListUpdated(state, action) {
      return {
        ...state,
        walletHistoriesList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingWalletHistoriesList: false,
      };
    },
    walletHistoryDetailsById(state, action) {
      return {
        ...state,
        currentWalletHistory: action.payload,
        loadingWalletHistory: false,
      };
    },
    resetWalletHistory(state) {
      return {
        ...initialState,
      };
    },

    walletHistoryError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingWalletHistory: false,
        loadingWalletHistoriesList: false,
      };
    },
    loadingOnWalletHistorySubmit(state) {
      return {
        ...state,
        loadingWalletHistory: true,
      };
    },
    walletHistoryCreated(state) {
      return {
        ...state,
        loadingWalletHistory: false,
      };
    },
    walletHistoryUpdated(state, action) {
      return {
        ...state,
        walletHistoriesList: {
          ...state.walletHistoriesList,
          data: state.walletHistoriesList.data.map((wallet) =>
            wallet._id === action.payload._id ? action.payload : wallet
          ),
        },
      };
    },
    walletHistoryDeleted(state, action) {
      const currentCount = state.walletHistoriesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.walletHistoriesList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        walletHistoriesList: {
          data: state.walletHistoriesList.data.filter(
            (wallet) => wallet._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingWalletHistoriesList: false,
      };
    },
  },
});
export const {
  loadingWalletHistoriesList,
  walletHistorySearchParametersUpdate,
  walletHistoryListUpdated,
  resetWalletHistory,
  walletHistoryError,
  loadingOnWalletHistorySubmit,
  walletHistoryCreated,
  walletHistoryUpdated,
  walletHistoryDetailsById,
  walletHistoryDeleted,
} = walletHistorySlice.actions;
export default walletHistorySlice.reducer;
