import React from "react";
import Marquee from "react-fast-marquee";

const MarqueeText = ({ content, contentType }) => {
  return (
    <Marquee
      pauseOnHover={true}
      style={{ fontSize: "20px" }}
      className={contentType}
      speed={100}
    >
      {content}
    </Marquee>
  );
};

export default MarqueeText;
