import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import MainCard from "../Components/MainCard";
import { Grid } from "@mui/material";

const WalletHistoryFilters = (props) => {
  const { onSearch } = props;
  const today = new Date().toISOString().split("T")[0];

  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const [statusType, setEarningType] = useState("");

  const handleFilterClick = () => {
    props.onSearch(fromDate, toDate, statusType);
  };

  return (
    <MainCard className="mt-4">
      <div className="filter-row">
        <Form>
          <Grid container className="" spacing={1}>
            <Grid item xs={12} md={6} lg={3} className="statusType">
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  value={statusType}
                  onChange={(e) => setEarningType(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="credit">Credit</option>
                  <option value="debit">Debit</option>
                </Form.Control>
              </Form.Group>
            </Grid>
            <Grid item sm={2} md={2} lg={3}></Grid>
            <Grid item lg={2}>
              <Form.Group>
                <Form.Label>From Date</Form.Label>
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </Form.Group>
            </Grid>
            <Grid item lg={2}>
              <Form.Group>
                <Form.Label>To Date</Form.Label>
                <Form.Control
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Form.Group>
            </Grid>
            <Grid item xs={1} lg={2}>
              <Button
                variant="primary"
                className="earningFilterBtn "
                onClick={handleFilterClick}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </Form>
      </div>
    </MainCard>
  );
};

export default WalletHistoryFilters;
