import axios from "axios";
import { setAlert, removeAlert } from "./alert";

import { setErrorsList } from "./errors";
import setAuthToken from "utils/setAuthToken";
import setAuthID from "utils/setAuthID";

import {
  userLoaded,
  registerSuccess,
  resetLinkSuccess,
  loginSucess,
  registerFail,
  resetLinkFail,
  authError,
  logoutAuth,
  loginFail,
  loadingOnPasswordReset,
  loadingOnLoginSubmit,
} from "reducers/auth";
import { removeErrors } from "reducers/errors";
import { RESET_LINK_FAIL } from "./types";

import { baseURL } from "constants/index";

export const login = (formData, navigate) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnLoginSubmit());
  dispatch(removeAlert());
  try {
    const config = { headers: { "Content-Type": "application/json" } };

    const res = await axios.post(`${baseURL}/api/auth`, formData, config);

    if (res.data.status === true) {
      dispatch(loginSucess(res.data.response));

      if (res.data.response && res.data.response.user.role === 1) {
        navigate("/admin/user-dashboard");
      } else if (res.data.response && res.data.response.user.role === 2) {
        navigate("/admin/dashboard");
      }

      setAuthToken(res.data.response.token);
      setAuthID(res.data.response.user._id);
    } else {
      const errors = res.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
      dispatch(
        loginFail({
          msg: res.data.message || res.statusText,
          status: res.status,
        })
      );
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch(
        loginFail({
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        })
      );
      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "error")
      );
      return err.response.data;
    }
  }
};

export const signup = (formData, navigate) => async (dispatch) => {
  try {
    dispatch(removeAlert());
    dispatch(removeErrors());
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.post(`${baseURL}/api/register`, formData, config);

    if (res.data.status === true) {
      dispatch(registerSuccess(res.data.response));
      dispatch(login(formData, navigate));
      setAlert("Sign up successfully", 200);
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "error"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
  } catch (err) {
    dispatch(
      registerFail({
        msg: err.response.statusText,
        status: err.response.status,
      })
    );
  }
};

export const userSignup = (formData, navigate) => async (dispatch) => {
  try {
    dispatch(removeAlert());
    dispatch(removeErrors());
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.post(
      `${baseURL}/api/register/user`,
      formData,
      config
    );

    let updatedFormData = { ...formData, email_or_referCode: formData.email };

    if (res.data.status === true) {
      dispatch(registerSuccess(res.data.response));
      dispatch(login(updatedFormData, navigate));
      setAlert("Sign up successfully", 200);
    } else {
      const errors = res.data.errors;

      if (errors) {
        errors.forEach((error) => {
          dispatch(setAlert(error.msg, "error"));
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      dispatch(
        resetLinkFail({
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        })
      );

      dispatch(
        setErrorsList(
          err.response.data.message || err.response.statusText,
          "error"
        )
      );

      dispatch(
        setAlert(err.response.data.message || err.response.statusText, "error")
      );
    }
  }
};

export const loadUser = (navigate) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  if (localStorage.authLocalID) {
    setAuthID(localStorage.authLocalID);
  }

  try {
    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(`${baseURL}/api/auth/${loggedInUserID}`);

    if (res.data.status === true) {
      dispatch(userLoaded(res.data.response));

      if (!window.location.pathname.match(/admin/)) {
        if (res.data.response && res.data.response.role === 1) {
          navigate("/admin/user-dashboard");
        } else if (res.data.response && res.data.response.role === 2) {
          navigate("/admin/dashboard");
        }
      }
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "error"));
      }
    }
  } catch (err) {
    console.log("err-----", err);
    if (err.response) {
      dispatch(
        authError({
          msg: err.response?.statusText,
          status: err.response?.status,
        })
      );
    }
  }
};

// Generate Reset Link
export const resetLink =
  ({ email }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ email });

    try {
      dispatch(removeAlert());
      dispatch(removeErrors());
      dispatch(loadingOnPasswordReset());
      const res = await axios.post(
        `${baseURL}/api/forgot-password`,
        body,
        config
      );
      if (res.data.status === true) {
        dispatch(resetLinkSuccess(res.data.response));
        dispatch(
          setAlert(
            res.data.message ||
              "We sent you an email with reset password link!",
            "success"
          )
        );
      } else {
        const errors = res.data.errors;
        dispatch(resetLinkFail({ msg: res.data.message }));
        dispatch(
          setAlert(
            "Errors! Please correct the following error and try again.",
            "error"
          )
        );
        if (errors) {
          // dispatch(setAlert(res.data.message, "error"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
    } catch (err) {
      if (err.response) {
        dispatch(
          resetLinkFail({
            msg: err.response.data.message || err.response.statusText,
            status: err.response.status,
          })
        );

        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "error"
          )
        );
      }
    }
  };

// Reset Password
export const resetPassword = (password, token, history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch(removeAlert());
    dispatch(removeErrors());
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(
      `${baseURL}/api/forgot-password/${token}`,
      { password },
      config
    );
    if (res.data.status === true) {
      dispatch(resetLinkSuccess(res.data.response));
      dispatch(setAlert(res.data.message, "success"));
    } else {
      // dispatch(tokenInvalid());
      const errors = res.data.errors;

      if (errors) {
        dispatch(setAlert(res.data.message, "error"));
        dispatch(resetLinkFail());
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch(
        resetLinkFail({
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        })
      );
    } else if (err.errors) {
      dispatch(
        resetLinkFail({ msg: err.message, status: err.response.status })
      );
    }
  }
};

// Verify Reset Password Token Validity
export const getResetTokenStatus = (token) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/forgot-password/${token}`,
      config
    );

    if (res.data.status === true) {
      dispatch(resetLinkSuccess(res.data.response));
    } else {
      dispatch(setAlert(res.data.message || res.message, "error"));
    }

    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      resetLinkFail({
        msg: err.response.data.message || err.response.statusText,
        status: err.response.status,
      });
    }
  }
};

//Logout / Clear Profile
export const logout = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // clearInterval(authTimer);
    dispatch(removeAlert());
    dispatch(removeErrors());
    const res = await axios.put(`${baseURL}/api/auth/logout`, {}, config);

    if (res.data.status === true) {
      setAuthToken();
      setAuthID();
      dispatch(logoutAuth());
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "error"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(setAlert(err.response.data.msg, "error"));
        setAuthToken();
        setAuthID();
        dispatch(removeErrors());
        dispatch(logoutAuth());
      } else {
        dispatch(
          authError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "error"
          )
        );
      }
    }
  }
};

export const setPassword = (password, id, navigate) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch(removeAlert());
    dispatch(removeErrors());
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(
      `${baseURL}/api/auth/set-password`,
      { password, id },
      config
    );

    let providerID =
      res.data && res.data.response ? res.data.response.provider_id : null;

    if (res.data.status === true) {
      loadUser();
      dispatch(setAlert(res.data.message, "success"));
      navigate(`/admin/providers/${providerID}/provider-dashboard`);
    } else {
      const errors = res.data.errors;
      dispatch(resetLinkFail());

      if (errors) {
        dispatch(setAlert(res.data.message, "error"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, "error"));
      // Handle HTTP error response
    } else {
      // Handle other types of errors
      dispatch(setAlert("An error occurred", "error"));
    }
  }
};

export const setTransactionPassword =
  (transactionPassword, id, navigate) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch(removeAlert());
      dispatch(removeErrors());
      dispatch(loadingOnPasswordReset());

      const res = await axios.post(
        `${baseURL}/api/auth/set-transaction-password`,
        { transactionPassword, id },
        config
      );

      // let providerID =
      //   res.data && res.data.response ? res.data.response.provider_id : null;

      if (res.data.status === true) {
        loadUser();
        dispatch(setAlert(res.data.message, "success"));
        navigate(`/admin/user-dashboard`);
      } else {
        const errors = res.data.errors;
        dispatch(resetLinkFail());

        if (errors) {
          errors.forEach((error) => {
            dispatch(setAlert(error.msg, "error"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, "error"));
        // Handle HTTP error response
      } else {
        // Handle other types of errors
        dispatch(setAlert("An error occurred", "error"));
      }
    }
  };

export const forgotPassword = (values, navigate) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    dispatch(removeAlert());
    dispatch(removeErrors());
    dispatch(loadingOnPasswordReset());

    const res = await axios.post(
      `${baseURL}/api/auth/forgot-password`,
      values,
      config
    );

    const userID = res.data.response._id;

    if (res.data.status === true) {
      // loadUser();
      navigate("/forgot-password/change-password", { state: userID });
      dispatch(setAlert(res.data.message, "success"));
    } else {
      const errors = res.data.errors;
      dispatch(resetLinkFail());

      if (errors) {
        dispatch(setAlert(res.data.message, "error"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, "error"));
      // Handle HTTP error response
    } else {
      // Handle other types of errors
      dispatch(setAlert("An error occurred", "error"));
    }
  }
};
export const changeForgotPassword =
  (values, id, navigate) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { password } = values;
    try {
      dispatch(removeAlert());
      dispatch(removeErrors());
      dispatch(loadingOnPasswordReset());

      const res = await axios.put(
        `${baseURL}/api/auth/forgot-password/change-password`,
        { password, id },
        config
      );

      if (res.data.status === true) {
        loadUser();
        navigate("/login");
        dispatch(setAlert(res.data.message, "success"));
      } else {
        const errors = res.data.errors;
        dispatch(resetLinkFail());

        if (errors) {
          dispatch(setAlert(res.data.message, "error"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      dispatch(resetLinkFail());

      if (err.response) {
        dispatch({
          type: RESET_LINK_FAIL,
          payload: {
            msg: err.response.data.message || err.response.statusText,
            status: err.response.status,
          },
        });
      } else if (err.errors) {
        dispatch({
          type: RESET_LINK_FAIL,
          payload: { msg: err.message, status: err.response.status },
        });
      }
    }
  };
export const ChangeTransactionPassword =
  (changeTransactionPassword, id, navigate) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { oldTransactionPassword, newTransactionPassword } =
      changeTransactionPassword;
    try {
      dispatch(removeAlert());
      dispatch(removeErrors());
      dispatch(loadingOnPasswordReset());

      const res = await axios.put(
        `${baseURL}/api/auth/change-transaction-password`,
        { newTransactionPassword, oldTransactionPassword, id },
        config
      );

      // let providerID =
      //   res.data && res.data.response ? res.data.response.provider_id : null;

      if (res.data.status === true) {
        loadUser();
        dispatch(setAlert(res.data.message, "success"));

        navigate(`/admin/user-dashboard`);
      } else {
        const errors = res.data.errors;
        dispatch(resetLinkFail());

        if (errors) {
          errors.forEach((error) => {
            dispatch(setAlert(error.msg, "error"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, "error"));
        // Handle HTTP error response
      } else {
        // Handle other types of errors
        dispatch(setAlert("An error occurred", "error"));
      }
    }
  };

export const ChangePassword =
  (changePassword, id, navigate) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { oldPassword, newPassword } = changePassword;
    try {
      dispatch(removeAlert());
      dispatch(removeErrors());
      dispatch(loadingOnPasswordReset());

      const res = await axios.put(
        `${baseURL}/api/auth/change-password`,
        { newPassword, oldPassword, id },
        config
      );

      if (res.data.status === true) {
        await dispatch(loadUser());
        navigate(`/admin/user-dashboard`);
        dispatch(setAlert(res.data.message, "success"));
      } else {
        const errors = res.data.errors;
        dispatch(resetLinkFail());

        if (errors) {
          errors.forEach((error) => {
            dispatch(setAlert(error.msg, "error"));
            dispatch(setErrorsList(error.msg, error.path));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response) {
        dispatch(setAlert(err.response.data.message, "error"));
        // Handle HTTP error response
      } else {
        // Handle other types of errors
        dispatch(setAlert("An error occurred", "error"));
      }
    }
  };

// Token invalid
export const tokenInvalid = (navigate, type) => async (dispatch) => {
  navigate("/forgot-password");
};

// Redirect to Login screen
export const loginRedirect = (history, type) => async (dispatch) => {
  dispatch(removeAlert());
  dispatch(removeErrors());
  history.push("/login");
};

//Dispatch Confirm password error
export const setPasswordError = (msg, path) => async (dispatch) => {
  dispatch(setErrorsList(msg, path));
};

export const loadPage = () => async (dispatch) => {
  dispatch(removeAlert());
  dispatch(removeErrors());
};
