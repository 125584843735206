import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  userTotalCount,
  userCurrentWallet,
  resetDashboard,
  dashboardError,
  userAllIncomes,
  userDownline,
  userAllDownlineList,
  adminAllDashboardData,
  adminAllDownlineList,
  usetTotalDrCr,
  userAllDownlineListData,
} from "reducers/dashboardReducer";

import { baseURL } from "constants/index";

export const getAllUsersCount = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/count`,
      config
    );

    dispatch(userTotalCount(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

// Withdrawal

export const getUserTotalDRCR = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/total-drcr/${loggedInUserID}`,
      config
    );

    dispatch(usetTotalDrCr(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUserCurrentWallet = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/current-wallet/${loggedInUserID}`,
      config
    );

    dispatch(userCurrentWallet(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUserAllIncome = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/all-incomes/${loggedInUserID}`,
      config
    );

    dispatch(userAllIncomes(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUserDownline = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/downline/${loggedInUserID}`,
      config
    );

    dispatch(userDownline(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUserAllDownline = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/all-downline/${loggedInUserID}`,
      config
    );

    dispatch(userAllDownlineList(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};
export const getUserAllDownlineData = (params) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/users/all-downline-data/${loggedInUserID}`,
      config
    );
    dispatch(userAllDownlineListData(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getAdminAllDownline = (params) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/all-downline/${loggedInUserID}`,
      config
    );

    dispatch(adminAllDownlineList(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getAdminAllData = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/dashboard/admin-all-data/${loggedInUserID}`,
      config
    );

    dispatch(adminAllDashboardData(res.data.response));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

// reset errors
export const removeDashboardErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetDashboard());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(dashboardError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/dashboard");
};
