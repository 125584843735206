import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import {
  updateApproval,
  removeApprovalErrors,
  resetComponentStore,
  getApprovalById,
} from "actions/approvalActions";
import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const EditApprovalRequest = ({
  updateApproval,
  loadingApproval,
  currentApproval,
  getApprovalById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { approval_id } = useParams();

  const [formData, setFormData] = React.useState({});
  const [approveSubmitting, setApproveSubmitting] = React.useState(false);
  const [rejectSubmitting, setRejectSubmitting] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(null);

  React.useEffect(() => {
    if (currentApproval) {
      setFormData(currentApproval);

      const isPending =
        currentApproval &&
        currentApproval.status &&
        currentApproval.status === "pending";

      setIsDisable(isPending);
    }
  }, [currentApproval]);

  const handleApprove = () => {
    const updatedData = { ...formData, status: "approved" };
    setApproveSubmitting(true);
    updateApproval(updatedData, approval_id, navigate).then(() => {
      setApproveSubmitting(false);
    });
  };

  const handleReject = () => {
    const updatedData = { ...formData, status: "rejected" };
    setRejectSubmitting(true);
    updateApproval(updatedData, approval_id, navigate).then(() => {
      setRejectSubmitting(false);
    });
  };

  React.useEffect(() => {
    if (approval_id) {
      resetComponentStore();
      getApprovalById(approval_id);
    }
  }, [approval_id]);

  return (
    <React.Fragment>
      {loadingApproval ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <div className="card-heading mb-3">
                <Typography variant="h5" color="textSecondary" className="mb-2">
                  Approval Request Information
                </Typography>
              </div>

              <Form autoComplete="off">
                <Form.Group className="form-group mb-3">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.userId || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Method</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.method || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.amount || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>UTR Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.utrNumber || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={formData.status || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, status: e.target.value })
                    }
                  />
                </Form.Group>

                {isDisable && (
                  <div className="mb-3">
                    <Button variant="success" onClick={handleApprove}>
                      {approveSubmitting ? "Submitting..." : "Approve"}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleReject}
                      className="ms-2 me-2"
                    >
                      {rejectSubmitting ? "Submitting..." : "Reject"}
                    </Button>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditApprovalRequest.propTypes = {
  loadingApproval: PropTypes.bool.isRequired,
  currentApproval: PropTypes.object,
  getApprovalById: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  updateApproval: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loadingApproval: state.approval.loadingApproval,
  currentApproval: state.approval.currentApproval,
});

export default connect(mapStateToProps, {
  updateApproval,
  removeApprovalErrors,
  resetComponentStore,
  getApprovalById,
})(EditApprovalRequest);
