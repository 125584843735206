import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
// import Spinner from "views/Spinner";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  updateUpi,
  cancelSave,
  setErrors,
  removeUpiErrors,
  resetComponentStore,
  getUpiByUserId,
} from "actions/upiActions";

import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";
import { upiOptions } from "constants/index";

const UserEditUpi = ({
  updateUpi,
  setErrors,
  removeUpiErrors,
  loadingUpi,
  currentUpi,
  errorList,
  getUpiByUserId,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { upi_id, user_id } = useParams();

  const initialFormData = {
    upiHolderName: "",
    upiId: "",
    mobileNumber: "",
    selectedUpiOption: "default",
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const [upi_ID, setUpi_ID] = React.useState(true);

  const toggleEdit = () => setDisabled(!isDisabled);

  const loadUpiFormData = (currentUpi) => {
    const { upiHolderName, upiId, mobileNumber, selectedUpiOption } =
      currentUpi;

    const data = {
      upiHolderName,
      upiId,
      mobileNumber,
      selectedUpiOption,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentUpi) return;

    const upiID = currentUpi && currentUpi?._id ? currentUpi?._id : null;

    setUpi_ID(upiID);

    loadUpiFormData(currentUpi);
  }, [currentUpi]);

  const { upiHolderName, upiId, mobileNumber, selectedUpiOption } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeUpiErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    updateUpi(submitData, navigate, upi_ID).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!user_id) return;

    resetComponentStore();

    getUpiByUserId(user_id);
  }, []);

  return (
    <React.Fragment>
      {loadingUpi ? (
        <Spinner />
      ) : (
        <Col xs="12">
          {currentUpi ? (
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    UPI Information
                  </Typography>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="upiHolderName">
                    Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    // className={errorList.upiHolderName ? "invalid" : ""}
                    type="text"
                    id="upiHolderName"
                    name="upiHolderName"
                    maxLength="150"
                    value={upiHolderName}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="upiHolderName"
                    id="upiHolderName-upi"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="upiId">UPI Id*</Form.Label>
                  <Form.Control
                    // className={errorList.upiId ? "invalid" : ""}
                    type="text"
                    id="upiId"
                    name="upiId"
                    maxLength="150"
                    value={upiId}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="upiId"
                    id="upiId-upi"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="mobileNumber">Mobile Number*</Form.Label>
                  <Form.Control
                    // className={errorList.mobileNumber ? "invalid" : ""}
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    maxLength="10"
                    value={mobileNumber}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="mobileNumber"
                    id="mobileNumber-upi"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="selectedUpiOption">
                    Selected UPI Option*
                  </Form.Label>
                  <Form.Select
                    id="selectedUpiOption"
                    name="selectedUpiOption"
                    value={selectedUpiOption}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  >
                    <option value="default">Select UPI Option</option>
                    {upiOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="px-3"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="  m-2 "
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          ) : (
            <Card className="p-4 text-danger">
              The user has not added their UPI details yet.
            </Card>
          )}
        </Col>
      )}
    </React.Fragment>
  );
};

UserEditUpi.propTypes = {
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingUpi: state.upi.loadingUpi,
  currentUpi: state.upi.currentUpi,
});

export default connect(mapStateToProps, {
  updateUpi,
  cancelSave,
  setErrors,
  removeUpiErrors,
  resetComponentStore,
  getUpiByUserId,
  resetComponentStore,
})(UserEditUpi);
