import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  adminAccountSettingsList: {
    page: 1,
    data: [],
    count: 0,
  },
  adminEarningLinksList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentAdminAccountSetting: null,
  currentAdminEarningLink: null,
  loadingAdminAccountSettingsList: true,
  loadingAdminEarningLinksList: true,
  loadingAdminEarningLink: false,
  loadingAdminAccountSetting: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const adminAccountSettingSlice = createSlice({
  name: "adminAccountSetting",
  initialState: initialState,
  reducers: {
    loadingAdminAccountSettingsList(state) {
      return {
        ...state,
        loadingAdminAccountSettingsList: true,
      };
    },

    loadingAdminEarningLinksList(state) {
      return {
        ...state,
        loadingAdminEarningLinksList: true,
      };
    },

    adminAccountSettingListUpdated(state, action) {
      return {
        ...state,
        adminAccountSettingsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingAdminAccountSettingsList: false,
      };
    },

    adminEarningLinksListUpdated(state, action) {
      return {
        ...state,
        adminEarningLinksList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingAdminEarningLinksList: false,
      };
    },

    adminAccountSettingDetailsById(state, action) {
      return {
        ...state,
        currentAdminAccountSetting: action.payload,
        loadingAdminAccountSetting: false,
      };
    },

    adminEarningLinkDetailsById(state, action) {
      return {
        ...state,
        currentAdminEarningLink: action.payload,
        loadingAdminAccountSetting: false,
      };
    },

    resetAdminAccountSetting(state) {
      return {
        ...initialState,
      };
    },

    adminAccountSettingError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingAdminAccountSetting: false,
        loadingAdminAccountSettingsList: false,
      };
    },
    loadingOnAdminAccountSettingSubmit(state) {
      return {
        ...state,
        loadingAdminAccountSetting: true,
      };
    },
    adminAccountSettingCreated(state) {
      return {
        ...state,
        loadingAdminAccountSetting: false,
      };
    },

    adminEarningLinkCreated(state) {
      return {
        ...state,
        loadingAdminEarningLink: false,
      };
    },

    adminAccountSettingUpdated(state, action) {
      return {
        ...state,
        adminAccountSettingsList: {
          ...state.adminAccountSettingsList,
          data: state.adminAccountSettingsList.data.map((accountSetting) =>
            accountSetting._id === action.payload._id
              ? action.payload
              : accountSetting
          ),
        },
      };
    },

    adminEarninglinksUpdated(state, action) {
      return {
        ...state,
        adminEarningLinksList: {
          ...state.adminEarningLinksList,
          data: state.adminEarningLinksList.data.map((earningLink) =>
            earningLink._id === action.payload._id
              ? action.payload
              : earningLink
          ),
        },
      };
    },

    adminAccountSettingDeleted(state, action) {
      const currentCount = state.adminAccountSettingsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.adminAccountSettingsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        adminAccountSettingsList: {
          data: state.adminAccountSettingsList.data.filter(
            (accountSetting) => accountSetting._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingAdminAccountSettingsList: false,
      };
    },
  },
});
export const {
  loadingAdminAccountSettingsList,
  adminAccountSettingListUpdated,
  adminEarningLinksListUpdated,
  resetAdminAccountSetting,
  adminAccountSettingError,
  loadingOnAdminAccountSettingSubmit,
  adminAccountSettingCreated,
  adminAccountSettingUpdated,
  adminAccountSettingDetailsById,
  adminAccountSettingDeleted,
  loadingAdminEarningLinksList,
  adminEarningLinkCreated,
  adminEarninglinksUpdated,
  adminEarningLinkDetailsById,
} = adminAccountSettingSlice.actions;
export default adminAccountSettingSlice.reducer;
