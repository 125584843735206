import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  productsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentProduct: null,
  loadingProductsList: true,
  loadingProduct: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    loadingProductsList(state) {
      return {
        ...state,
        loadingProductsList: true,
      };
    },
    productSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingProductsList: false,
      };
    },
    productListUpdated(state, action) {
      return {
        ...state,
        productsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingProductsList: false,
      };
    },
    productDetailsById(state, action) {
      return {
        ...state,
        currentProduct: action.payload,
        loadingProduct: false,
      };
    },
    resetProduct(state) {
      return {
        ...initialState,
      };
    },

    productError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProduct: false,
        loadingProductsList: false,
      };
    },
    loadingOnProductSubmit(state) {
      return {
        ...state,
        loadingProduct: true,
      };
    },
    productCreated(state) {
      return {
        ...state,
        loadingProduct: false,
      };
    },
    productUpdated(state, action) {
      return {
        ...state,
        productsList: {
          ...state.productsList,
          data: state.productsList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    productDeleted(state, action) {
      const currentCount = state.productsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.productsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        productsList: {
          data: state.productsList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingProductsList: false,
      };
    },
  },
});
export const {
  loadingProductsList,
  productSearchParametersUpdate,
  productListUpdated,
  resetProduct,
  productError,
  loadingOnProductSubmit,
  productCreated,
  productUpdated,
  productDetailsById,
  productDeleted,
} = productSlice.actions;
export default productSlice.reducer;
