import { MdOutlineHistory } from "react-icons/md";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const UserHistoryPath = {
  id: "group-history-user",
  title: "",
  type: "group",
  children: [
    {
      id: "history-user",
      title: "My Earning",
      type: "item",
      icon: MdOutlineHistory,
      subChlidrens: [
        {
          id: "earningHistoryUser",
          title: "Earning History",
          type: "item",
          url: "user-earning-history",
          icon: MdOutlineHistory,
        },
        {
          id: "walletHistoryUser",
          title: "Wallet History",
          type: "item",
          url: "user-wallet-histories",
          icon: MdOutlineHistory,
        },
      ],
    },
  ],
};

export default UserHistoryPath;
