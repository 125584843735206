import React from "react";

const Spinner = () => {
  return (
    <div style={{ height: "500px", alignContent: "center" }}>
      <div className="loader">Loading...</div>
    </div>
  );
};

export default Spinner;
