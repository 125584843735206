import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

// material-ui
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// project import
import Dot from "./Dot";
import { getAdminAllData } from "actions/dashboardActions";
import MainCard from "View/Admin/Components/MainCard";
import { capitalizeFirst } from "utils/helper";

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  {
    id: "userFullname",
    align: "left",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    align: "left",
    disablePadding: false,
    label: "Phone",
  },

  {
    id: "myReferCode",
    align: "left",
    disablePadding: false,
    label: "Refer Code",
  },
  {
    id: "RegistrationDate",
    align: "left",
    disablePadding: false,
    label: "Registration Date",
  },

  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function AdminTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#e2e6ed" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// ==============================|| ORDER TABLE ||============================== //
function AdminTable({ getAdminAllData, adminAllDashboardData }) {
  const [order] = useState("asc");
  const [orderBy] = useState("userId");
  const [selected] = useState([]);

  const isSelected = (userId) => selected.indexOf(userId) !== -1;

  React.useEffect(() => {
    getAdminAllData();
  }, [getAdminAllData]);

  return (
    <MainCard>
      <Box>
        <TableContainer>
          <Table>
            <AdminTableHead order={order} orderBy={orderBy} />

            <TableBody>
              {adminAllDashboardData &&
                adminAllDashboardData?.last10AddedUser?.map((user, index) => {
                  const labelId = `user-${index}`;

                  // Construct full name
                  const fullName = `${capitalizeFirst(
                    user.firstname
                  )} ${capitalizeFirst(user.lastname)}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected(user._id)}
                      tabIndex={-1}
                      key={user._id}
                      selected={isSelected(user._id)}
                      // style={{ backgroundColor: rowColor(index) }}
                      sx={{
                        borderBottom: "1px solid gray", // Add border to table rows
                      }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        sx={{
                          fontSize: "13px",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        {fullName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: "13px",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        {user.email}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: "13px",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        {user.phone}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: "1px solid gray",
                        }}
                      >
                        {user.myReferCode}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: "1px solid gray",
                        }}
                      >
                        {moment(user.createdAt).format("hh:mm a, DD-MM-YYYY")}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "13px",
                        }}
                      >
                        {user.status === 1 ? (
                          <Dot color="success" />
                        ) : (
                          <Dot color="error" />
                        )}
                        {user.status === 1 ? "Active" : "Inactive"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MainCard>
  );
}

const mapStateToProps = (state) => ({
  adminAllDashboardData: state.dashboard.adminAllDashboardData,
});

export default connect(mapStateToProps, {
  getAdminAllData,
})(AdminTable);
