import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// material-ui
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import AnimateButton from "../../../Admin/Components/AnimateButton";
import { strengthColor, strengthIndicator } from "utils/PasswordStrength";
import { connect } from "react-redux";
import { userSignup } from "actions/auth";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { generateRandomNumberString, splitByRegister } from "utils/helper";
import Errors from "Notifications/Errors";

// ============================|| FIREBASE - REGISTER ||============================ //

const AuthUserRegister = ({ userSignup, errorList, loading }) => {
  const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    referralCode: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    password: "",
    captcha: "",
  };

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const directJoinReferCode = splitByRegister(pathname);

  const [formData, setFormData] = useState({
    ...initialState,
    referralCode: directJoinReferCode || "",
  });

  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [randomNumber, setRandomNumber] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const randomNum = generateRandomNumberString(6);
    const newToken = uuidv4();
    setRandomNumber(randomNum);
    setCaptchaToken(newToken);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("");
  }, []);

  const onChange = (e) => {
    if (!e) return;

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVerify = (captcha) => {
    const isValid = captcha === randomNumber;
    return isValid;
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      const isValidCaptcha = handleVerify(values.captcha);

      if (!isValidCaptcha) {
        setStatus({ success: false });
        setErrors({ submit: "Captcha is invalid" });
        setSubmitting(false);
        return;
      }

      setStatus({ success: true });
      setSubmitting(false);

      const formDataWithCaptcha = { ...values, captchaToken };

      userSignup(formDataWithCaptcha, navigate);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={Yup.object().shape({
          firstname: Yup.string().max(55).required("First Name is required"),
          lastname: Yup.string().max(55).required("Last Name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          phone: Yup.string()
            .matches(/^\d+$/, "Phone number must contain only digits")
            .min(10, "Phone number must be at least 10 characters")
            .max(10, "Phone number must not exceed 10 characters")
            .required("Phone number is required"),
          referralCode: Yup.string().max(15),
          address: Yup.string().max(200).required("Address is required"),
          city: Yup.string().max(55).required("City is required"),
          state: Yup.string().max(55).required("State is required"),
          zip: Yup.string().min(6).max(6).required("Pin is required"),
          password: Yup.string().min(3).required("Password is required"),
          captcha: Yup.string().required("Captcha is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const yupErrors = {};
          if (errors) {
            Object.keys(errors).forEach((key) => {
              if (touched[key]) {
                yupErrors[key] = errors[key];
              }
            });
          }

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="firstname-signup">
                      First Name*
                    </InputLabel>
                    <OutlinedInput
                      id="firstname-signup"
                      type="text"
                      value={values.firstname}
                      name="firstname"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="Firstname"
                      fullWidth
                      error={Boolean(
                        touched.firstname &&
                          (errors.firstname ||
                            (errorList && errorList.firstname))
                      )}
                    />
                    {touched.firstname &&
                      (errors.firstname ||
                        yupErrors?.firstname ||
                        (errorList && errorList.firstname)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="firstname"
                          id="helper-text-firstname-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="lastname-signup">
                      Last Name*
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.lastname &&
                          (errors.lastname || (errorList && errorList.lastname))
                      )}
                      id="lastname-signup"
                      type="text"
                      value={values.lastname}
                      name="lastname"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="Lastname"
                      inputProps={{}}
                    />
                    {touched.lastname &&
                      (errors.lastname ||
                        yupErrors?.lastname ||
                        (errorList && errorList.lastname)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="lastname"
                          id="helper-text-lastname-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-signup">
                      Email Address*
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.email &&
                          (errors.email || (errorList && errorList.email))
                      )}
                      id="email-signup"
                      type="email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="Enter your email"
                      inputProps={{}}
                    />

                    {touched.email &&
                      (errors.email ||
                        yupErrors?.email ||
                        (errorList && errorList.email)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="email"
                          id="helper-text-email-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="phone-signup">Phone*</InputLabel>
                    <OutlinedInput
                      id="phone-signup"
                      type="number"
                      value={values.phone}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          handleChange(e);
                          onChange(e);
                        }
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                      placeholder="Enter your mobile number"
                      fullWidth
                      error={Boolean(
                        touched.phone &&
                          (errors.phone || (errorList && errorList.phone))
                      )}
                    />
                    {touched.phone &&
                      (errors.phone ||
                        yupErrors?.phone ||
                        (errorList && errorList.phone)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="phone"
                          id="helper-text-phone-signup"
                        />
                      )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="referral-code-signup">
                      Sponsor Id
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      readOnly={!!directJoinReferCode}
                      error={Boolean(
                        touched.referralCode &&
                          (errors.referralCode ||
                            (errorList && errorList.referralCode))
                      )}
                      id="referral-code-signup"
                      type="text"
                      value={values.referralCode}
                      name="referralCode"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="Enter referral code"
                      inputProps={{ maxLength: 15 }}
                    />
                    {touched.referralCode &&
                      (errors.referralCode ||
                        yupErrors?.referralCode ||
                        (errorList && errorList.referralCode)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="referralCode"
                          id="helper-text-referralCode-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="address-signup">Address*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.address &&
                          (errors.address || (errorList && errorList.address))
                      )}
                      id="address-signup"
                      type="text"
                      value={values.address}
                      name="address"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="Address"
                      inputProps={{}}
                    />
                    {touched.address &&
                      (errors.address ||
                        yupErrors?.address ||
                        (errorList && errorList.address)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="address"
                          id="helper-text-address-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="city-signup">City*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.city &&
                          (errors.city || (errorList && errorList.city))
                      )}
                      id="city-signup"
                      type="text"
                      value={values.city}
                      name="city"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="City"
                      inputProps={{}}
                    />
                    {touched.city &&
                      (errors.city ||
                        yupErrors?.city ||
                        (errorList && errorList.city)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="city"
                          id="helper-text-city-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="state-signup">State*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.state &&
                          (errors.state || (errorList && errorList.state))
                      )}
                      id="state-signup"
                      type="text"
                      value={values.state}
                      name="state"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      placeholder="State"
                    />
                    {touched.state &&
                      (errors.state ||
                        yupErrors?.state ||
                        (errorList && errorList.state)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="state"
                          id="helper-text-state-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="zip-signup">Pin Code*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.zip &&
                          (errors.zip || (errorList && errorList.zip))
                      )}
                      id="zip-signup"
                      type="text"
                      value={values.zip}
                      name="zip"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Pin"
                      inputProps={{ maxLength: 6 }}
                    />
                    {touched.zip &&
                      (errors.zip ||
                        yupErrors?.zip ||
                        (errorList && errorList.zip)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="zip"
                          id="helper-text-zip-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-signup">Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.password &&
                          (errors.password || (errorList && errorList.password))
                      )}
                      id="password-signup"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        onChange(e);
                        changePassword(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="******"
                      inputProps={{}}
                    />

                    {touched.password &&
                      (errors.password ||
                        yupErrors?.password ||
                        (errorList && errorList.password)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="password"
                          id="helper-text-password-signup"
                        />
                      )}
                  </Stack>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box
                          sx={{
                            bgcolor: level?.color,
                            width: 85,
                            height: 8,
                            borderRadius: "7px",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" fontSize="0.75rem">
                          {level?.label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Typography variant="body2" sx={{ flex: 1 }}>
                    To verify you're not a robot, please enter the number below:
                  </Typography>
                  <Typography
                    variant="h4"
                    component="strong"
                    sx={{ color: "primary.main" }}
                  >
                    {randomNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="captcha">Captcha*</InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="captcha"
                      type="text"
                      value={values.captcha}
                      name="captcha"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ maxLength: 6 }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter the characters you see in the image above"
                      error={
                        touched.captcha &&
                        (errors.captcha ||
                          yupErrors?.captcha ||
                          (errorList && errorList.captcha))
                      }
                    />
                    {touched.captcha &&
                      (errors.captcha ||
                        yupErrors?.captcha ||
                        (errorList && errorList.captcha)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="captcha"
                          id="helper-text-captcha-signup"
                        />
                      )}
                  </Stack>
                </Grid>

                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {loading ? "Loading..." : "Create Account"}
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  userSignup,
})(AuthUserRegister);
