import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaRegEye } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

// material-ui
import { Grid } from "@mui/material";

import {
  getProductsList,
  resetComponentStore,
  deleteProduct,
} from "actions/productActions";

import AnimateButton from "View/Admin/Components/AnimateButton";
import { Button } from "react-bootstrap";

const ProductsList = ({
  productsList: { data, count },
  getProductsList,
  loadingProductsList,
  resetComponentStore,
  sortingParams,
  deleteProduct,
  user,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const actions = (
    <Grid container spacing={2}>
      <Grid item xs={3} className="mb-3">
        <Link to="/admin/products/create" title="create">
          <AnimateButton>
            <Button type="button" variant="primary">
              Create Product
            </Button>
          </AnimateButton>
        </Link>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: "Product Name",
      selector: (row) => row.productName,
      sortable: false,
      width: "15%",
      wrap: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
      width: "25%",
      wrap: true,
    },

    {
      name: "Image",
      cell: (row) => (
        <img
          src={row.imageUrl}
          alt={row.productName}
          style={{
            width: "100px",
            height: "100px",
            marginBottom: "10px",
          }}
        />
      ),
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "MRP",
      selector: (row) => row.mrp,
      sortable: false,
      width: "10%",
      wrap: true,
    },

    {
      name: "Price",
      selector: (row) => row.price,
      sortable: false,
      width: "10%",
      wrap: true,
    },

    {
      name: "isActive",
      selector: (row) => (row.isActive === true ? "Active" : "Inactive"),
      sortable: false,
      width: "10%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "15%",
      button: true,
      cell: (row) => (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          // className="gap-2"
        >
          <Grid item xs={6} sm={3} lg={4}>
            <Link to={`/admin/products/${row._id}/edit`} title="View Product">
              <Button type="submit" variant="primary" color="primary" size="sm">
                <FaRegEye />
              </Button>
            </Link>
          </Grid>

          <Grid item xs={6} sm={2} lg={4}>
            <Button
              size="sm"
              type="submit"
              variant="danger"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete: ${row.productName}?`
                  )
                ) {
                  deleteProduct(row._id);
                }
              }}
            >
              <BiTrash />
            </Button>
          </Grid>
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getProductsList(clientParams);
  }, [getProductsList, clientParams, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Products" crumbs={[{ name: "Products" }]} />

      <Card>
        <Card.Body>
          {actions}
          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingProductsList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ProductsList.propTypes = {
  getProductsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  productsList: state.product.productsList,
  loadingProductsList: state.product.loadingProductsList,
  sortingParams: state.product.sortingParams,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getProductsList,
  resetComponentStore,
  deleteProduct,
})(ProductsList);
