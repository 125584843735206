// assets
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdOutlineHistory } from "react-icons/md";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const BankingPath = {
  id: "group-banking",
  title: "",
  type: "group",
  children: [
    {
      id: "group-withdrawal",
      title: "Withdrawal",
      type: "item",
      icon: BiMoneyWithdraw,
      subChlidrens: [
        {
          id: "withdrawal",
          title: "Withdrawal",
          type: "item",
          url: "/admin/withdrawal",
          icon: BiMoneyWithdraw,
          breadcrumbs: false,
        },
        {
          id: "withdrawalHistory",
          title: "Withdrawal History",
          type: "item",
          url: "withdrawal-history",
          icon: MdOutlineHistory,
        },
      ],
    },
  ],
};

export default BankingPath;
