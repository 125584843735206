import { TbPasswordMobilePhone } from "react-icons/tb";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const SetTransactionPasswordPath = {
  id: "group-updateTransactionPassword",
  title: "",
  type: "group",
  children: [
    {
      id: "setTransactionPassword",
      title: " Transaction Password",
      type: "item",
      icon: TbPasswordMobilePhone,
      subChlidrens: [
        {
          id: "transactionPassword",
          title: " Set Password",
          type: "item",
          url: "users/transaction-password",
          icon: TbPasswordMobilePhone,
        },
      ],
    },
  ],
};

export default SetTransactionPasswordPath;
