import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import WheelComponent from "./Wheel";
import {
  createSpinAndWin,
  getSpinAndWinList,
  resetComponentStore,
} from "actions/spinAndWinActions";

import { getAdminAccountSettingsList } from "actions/adminAccountSettingActions";
import { useNavigate } from "react-router-dom";

const SpinAndWin = ({
  getAdminAccountSettingsList,
  adminAccountSettingsList: { data },
  spinAndWinList: { spinAndWinData },
  resetComponentStore,
  createSpinAndWin,
  getSpinAndWinList,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();
  const [difference, setHoursDifference] = useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getAdminAccountSettingsList();
  }, [getAdminAccountSettingsList, resetComponentStore]);

  React.useEffect(() => {
    getSpinAndWinList();
  }, [getSpinAndWinList]);

  React.useEffect(() => {
    if (spinAndWinData && spinAndWinData?.createdAt) {
      const createdAtDate = new Date(spinAndWinData.createdAt);
      const currentDate = new Date();
      const timeDifference = currentDate - createdAtDate;
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      setHoursDifference(hoursDifference);
    }
  }, [spinAndWinData]);

  if (!data || data.length === 0) {
    return null;
  }

  const spinSlab = parseFloat(data[0]?.spinSlab) || 10;
  const ammountToMultiply = parseFloat(data[0]?.spinAmmountToMultiply) || 10;
  const segments = [];
  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#FF9000"];

  for (let i = 1; i <= spinSlab; i++) {
    segments.push((i * ammountToMultiply).toString());
  }
  const repeatedSegColors = Array.from(
    { length: segments.length },
    (_, index) => segColors[index % segColors.length]
  );

  const onFinished = (winner) => {
    setResult(winner);
    setOpen(true);
    setSpinning(false);
    setShowConfetti(true);

    createSpinAndWin(winner, navigate);
  };

  const handleClose = () => {
    setOpen(false);
    setShowConfetti(false);
  };

  return (
    <Grid container>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        gap={1}
        marginBottom={{ md: 3, xs: 0 }}
      >
        <Typography fontSize={40} style={{ textShadow: "2px 1px 2px black" }}>
          Spin
        </Typography>
        <Typography
          style={{
            textShadow: "2px 1px 2px black",

            boxShadow: 3,
          }}
          sx={{ borderRadius: "100%" }}
          fontSize={60}
        >
          &
        </Typography>
        <Typography style={{ textShadow: "2px 1px 2px black" }} fontSize={40}>
          Win
        </Typography>
      </Grid>
      <WheelComponent
        segments={segments}
        segColors={repeatedSegColors}
        winningSegment=""
        onFinished={(winner) => onFinished(winner)}
        primaryColor="black"
        primaryColoraround="#ffffffb4"
        contrastColor="white"
        buttonText="Win"
        hoursDifference={difference}
        isOnlyOnce={false}
        size={150} // Adjust size based on the number of segments
        upDuration={50} // Lower value to increase spinning speed
        downDuration={500}
        spinning={spinning}
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Spin and Win Result</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {result && `Congratulations! You won ${result}!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          style={{ position: "fixed ", top: 0, left: 0, zIndex: 991 }}
        />
      )}
    </Grid>
  );
};

SpinAndWin.propTypes = {
  getAdminAccountSettingsList: PropTypes.func.isRequired,
  createSpinAndWin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
  spinAndWinList: state.spinAndWin.spinAndWinList,
});

export default connect(mapStateToProps, {
  getAdminAccountSettingsList,
  resetComponentStore,
  createSpinAndWin,
  getSpinAndWinList,
})(SpinAndWin);
