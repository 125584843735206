import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingBanksList,
  bankSearchParametersUpdate,
  bankListUpdated,
  resetBank,
  bankError,
  loadingOnBankSubmit,
  bankCreated,
  bankUpdated,
  bankDetailsById,
  bankDeleted,
} from "reducers/bankReducer";

import { baseURL } from "constants/index";

export const getBanksList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingBanksList());
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/accounts/list/${loggedInUserID}`,
      config
    );

    dispatch(bankSearchParametersUpdate(appTokenParams));

    dispatch(bankListUpdated(res.data.response[0]));
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          bankError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getBankById = (bank_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnBankSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/accounts/${bank_id}`,
      config
    );

    dispatch(bankDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          bankError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getBankByUserId = (user_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnBankSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/accounts/user/${user_id}`,
      config
    );

    dispatch(bankDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          bankError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createBank = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnBankSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/credentials/accounts`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(bankCreated(res.data.response));
      dispatch(setAlert("Bank Created.", "success"));
      navigate(`/admin/credentials`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(bankError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          bankError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateBank = (formData, navigate, bank_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.put(
      `${baseURL}/api/admin/credentials/accounts/${bank_id}/edit`,
      formData,
      config
    );

    if (res.data.status === true) {
      await dispatch(bankUpdated(res.data.response));
      dispatch(setAlert(res.data.message, "success"));
      return res.data ? res.data : { status: false };
    } else {
      const errors = res.data.errors;
      if (errors.length) {
        dispatch(bankError());
        dispatch(setErrors(errors));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(
          bankError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "error"
          )
        );
      }
    }
  }
};

export const deleteBank = (bank_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(
      `${baseURL}/api/admin/credentials/accounts/${bank_id}`,
      config
    );

    dispatch(bankDeleted(bank_id));
    dispatch(setAlert("Bank deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        bankError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeBankErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetBank());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(bankError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/credentials");
};
