import React from "react";
import EditUser from "./EditUser";
import EditUserCredentials from "./EditUserCredentials";

const EditIndex = () => {
  return (
    <div>
      <EditUser />
      <EditUserCredentials />
    </div>
  );
};

export default EditIndex;
