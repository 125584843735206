import { combineReducers } from "redux";

import errors from "./errors";
import menu from "./menu";
import auth from "./auth";
import alert from "./alert";
import bank from "./bankReducer";
import upi from "./upiReducer";
import withdrawal from "./withdrawalReducer";
import earning from "./earningReducer";
import walletHistory from "./walletHistoryReducer";
import approval from "./approvalReducer";
import accountSetting from "./adminAccountSettingReducer";
import dashboard from "./dashboardReducer";
import spinAndWin from "./spinAndWinReducer";
import user from "./user";
import youtube from "./youtubeReducer";
import qrScanner from "./qrScannerReducer";
import product from "./productReducer";
import userOrder from "./userOrderReducer";

const rootReducer = combineReducers({
  errors,
  menu,
  auth,
  alert,
  bank,
  upi,
  qrScanner,
  withdrawal,
  earning,
  walletHistory,
  approval,
  accountSetting,
  dashboard,
  spinAndWin,
  user,
  youtube,
  product,
  userOrder,
});

export default rootReducer;
