import axios from "axios";

const setAuthID = (authLocalID) => {
  if (authLocalID) {
    axios.defaults.headers.common["x-auth-authLocalID"] = authLocalID;
  } else {
    delete axios.defaults.headers.common["x-auth-authLocalID"];
  }
};

export default setAuthID;
