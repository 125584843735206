import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  withdrawalsList: {
    page: 1,
    data: [],
    count: 0,
  },
  approvedDownlineCount: 0,
  currentWithdrawal: null,
  loadingWithdrawalsList: true,
  loadingWithdrawal: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: initialState,
  reducers: {
    loadingWithdrawalsList(state) {
      return {
        ...state,
        loadingWithdrawalsList: true,
      };
    },
    withdrawalSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingWithdrawalsList: false,
      };
    },
    withdrawalListUpdated(state, action) {
      return {
        ...state,
        withdrawalsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingWithdrawalsList: false,
      };
    },

    userApprovedDownlineCountData(state, action) {
      return {
        ...state,
        approvedDownlineCount: action.payload,
        loadingWithdrawalsList: false,
      };
    },
    withdrawalDetailsById(state, action) {
      return {
        ...state,
        currentWithdrawal: action.payload,
        loadingWithdrawal: false,
      };
    },
    resetWithdrawal(state) {
      return {
        ...initialState,
      };
    },

    withdrawalError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingWithdrawal: false,
        loadingWithdrawalsList: false,
      };
    },
    loadingOnWithdrawalSubmit(state) {
      return {
        ...state,
        loadingWithdrawal: true,
      };
    },
    withdrawalCreated(state) {
      return {
        ...state,
        loadingWithdrawal: false,
      };
    },
    withdrawalUpdated(state, action) {
      return {
        ...state,
        withdrawalsList: {
          ...state.withdrawalsList,
          data: state.withdrawalsList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    withdrawalDeleted(state, action) {
      const currentCount = state.withdrawalsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.withdrawalsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        withdrawalsList: {
          data: state.withdrawalsList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingWithdrawalsList: false,
      };
    },
  },
});
export const {
  loadingWithdrawalsList,
  withdrawalSearchParametersUpdate,
  withdrawalListUpdated,
  userApprovedDownlineCountData,
  resetWithdrawal,
  withdrawalError,
  loadingOnWithdrawalSubmit,
  withdrawalCreated,
  withdrawalUpdated,
  withdrawalDetailsById,
  withdrawalDeleted,
} = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
