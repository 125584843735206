import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

import {
  getWithdrawalsList,
  resetComponentStore,
} from "actions/withdrawalActions";

import Dot from "../Dashboard/Table/Dot";
import { capitalizeFirst } from "utils/helper";
import WithdrawalFilters from "./WithdrawalFilters";

const WithdrawalHistory = ({
  withdrawalsList: { data, count },
  getWithdrawalsList,
  loadingWithdrawalsList,
  resetComponentStore,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const columns = [
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Status",
      selector: (row) =>
        row.status ? (
          <div className="d-flex align-items-center gap-2">
            <Dot color={row.status} size={8} />
            {capitalizeFirst(row.status)}
          </div>
        ) : null,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Method",
      selector: (row) => row.method,
      sortable: false,
      width: "15%",
      wrap: true,
    },
    {
      name: "Debit type",
      selector: (row) => capitalizeFirst(row.debit_type),
      sortable: false,
      width: "15%",
      wrap: true,
    },
    {
      name: "Date",
      cell: (row) =>
        row.createdAt
          ? moment(row.createdAt).format("hh:mm a, DD-MM-YYYY")
          : "",
      sortable: false,
      width: "30%",
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getWithdrawalsList(clientParams);
  }, [getWithdrawalsList, clientParams, resetComponentStore]);

  const handleSearch = (fromDate, toDate, statusType) => {
    getWithdrawalsList({ fromDate, toDate, statusType, ...clientParams });
  };

  const totalAmount = data.reduce((acc, row) => acc + row.amount, 0);

  const totalRow = {
    amount: `Total : ${totalAmount}`,
  };

  const tableData = [...data, totalRow];

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Withdrawal History"
        crumbs={[{ name: "Withdrawal History" }]}
      />

      <div className="table-filter-section mb-4">
        <WithdrawalFilters onSearch={handleSearch} />
      </div>

      <Card>
        <Card.Body>
          <PiDataTable
            columns={columns}
            data={tableData}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingWithdrawalsList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

WithdrawalHistory.propTypes = {
  getWithdrawalsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  withdrawalsList: state.withdrawal.withdrawalsList,
  loadingWithdrawalsList: state.withdrawal.loadingWithdrawalsList,
});

export default connect(mapStateToProps, {
  getWithdrawalsList,
  resetComponentStore,
})(WithdrawalHistory);
