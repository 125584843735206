// material-ui
import { Box, Typography } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import { connect } from "react-redux";
import menuItem from "../../DrawerItems/index"; // Import menuItems
import { isAdmin } from "utils/helper";
import EarningPath from "../../DrawerItems/EarningPath";
import BankingPath from "../../DrawerItems/BankingPath";
import DashboardPath from "../../DrawerItems/DashboardPath";
import AdminRequestPath from "../../DrawerItems/AdminRequestPath";
import AdminDashboardPath from "../../DrawerItems/AdminDashboardPath";
import AdminDownlinePath from "../../DrawerItems/AdminDownlinePath";
import DownlinePath from "../../DrawerItems/DownlinePath";
import AdminUsersListPath from "../../DrawerItems/AdminUsersListPath";
import UserQueryPath from "../../DrawerItems/UserQueryPath";
import { AiOutlineLogout } from "react-icons/ai";
import { logout } from "actions/auth";
import PropTypes from "prop-types";
import { List } from "../../../../../../../node_modules/@mui/material/index";
import UserProductPath from "../../DrawerItems/UserProductPath";
import AdminProductPath from "../../DrawerItems/AdminProductPath";
import SetTransactionPasswordPath from "../../DrawerItems/SetTransactionPasswordPath";
import ChangeTransactionPasswordPath from "../../DrawerItems/ChangeTransactionPasswordPath";
import UserHistoryPath from "../../DrawerItems/UserHistoryPath";
import HistoryPath from "../../DrawerItems/HistoryPath";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = ({ user, logout }) => {
  // Filter out EarningPath if user is an admin

  const { isSetTxnPassword } = user;
  const filteredMenuItems = isAdmin(user)
    ? menuItem.items.filter(
        (item) =>
          item !== EarningPath &&
          item !== UserQueryPath &&
          item !== BankingPath &&
          item !== UserProductPath &&
          item !== DashboardPath &&
          item !== DownlinePath &&
          item !== DashboardPath &&
          item !== DownlinePath &&
          item !== UserHistoryPath &&
          item !== SetTransactionPasswordPath &&
          item !== ChangeTransactionPasswordPath
      )
    : menuItem.items.filter(
        (item) =>
          item !== AdminRequestPath &&
          item !== AdminDashboardPath &&
          item !== AdminProductPath &&
          item !== AdminDownlinePath &&
          item !== AdminUsersListPath &&
          item !== HistoryPath
      );

  const menuItems = isSetTxnPassword
    ? filteredMenuItems.filter((item) => item !== SetTransactionPasswordPath)
    : filteredMenuItems.filter(
        (item) => item !== ChangeTransactionPasswordPath
      );

  const handleLogout = async () => {
    logout();
  };

  const navGroups = menuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ pt: 2 }}>
      {navGroups}{" "}
      <List
        variant="div"
        color="initial"
        className="ps-4 borderDrawer py-2 fs-6   "
        sx={{
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        <AiOutlineLogout size={20} className="me-2 text-danger" />{" "}
        <span className="text-danger">Logout</span>
      </List>
    </Box>
  );
};

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  logout,
})(Navigation);
