import React from "react";
import { connect } from "react-redux";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import AnimateButton from "View/Admin/Components/AnimateButton";

// assets
import Errors from "Notifications/Errors";
import MainCard from "View/Admin/Components/MainCard";
import YouTubeVideos from "View/Admin/Earning/YouTube/YouTubeVideos";
import AuthCodeVerification from "./AuthCodeVerification";

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthTroubleLogin = ({ errorList }) => {
  const initialState = {
    email_or_referCode: "",
    utrNumber: "",
    rememberMe: "",
  };

  const [showNotification, setShowNotification] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      setStatus({ success: false });
      setSubmitting(false);
      if (values.email_or_referCode && values.utrNumber) {
        setShowNotification(true);
      } else {
        setShowNotification(false);
        setErrors({ code: "Verification code is invalid" });
      }
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };
  const telegramUrl = "Successoffuturepvtltd";
  const handleTelegramLink = () => {
    const telegramLink = `https://t.me/${telegramUrl}`;
    window.open(telegramLink, "_blank");
  };
  const videoLink = "t8m0wjXGA8k";
  const handleOpenModal = () => {
    setSelectedVideo(videoLink);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 300,
            bgcolor: "white",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <MainCard>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ height: "40px", marginBottom: "10px" }}
            >
              <Grid item>
                <Typography variant="h3" color="initial">
                  Troubleshoot logging
                </Typography>
              </Grid>
              <IconButton
                aria-label="close"
                onClick={() => handleCloseModal()}
                sx={{
                  zIndex: 1,
                  bgcolor: "",
                  borderRadius: "50%",
                }}
              >
                <RxCross2 />
              </IconButton>
            </Grid>
            <Grid container>
              <YouTubeVideos videoId={selectedVideo} />
            </Grid>
          </MainCard>
        </div>
      </Modal>

      <FormHelperText
        id="error"
        style={{
          backgroundColor: "#ffebee",
          color: "red",
          padding: "10px",
          fontSize: "15px",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        Note: To obtain the payment QR code, please visit our official Telegram
        channel exclusively
        <br />
        <Link onClick={handleTelegramLink} style={{ cursor: "pointer" }}>
          Click Here To Get QR Code
        </Link>
      </FormHelperText>
      <FormHelperText
        onClick={() => handleOpenModal()}
        id="error"
        style={{
          backgroundColor: "#87ceeb47",
          color: "#343a40",
          padding: "10px",
          fontSize: "15px",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        {`Note: Click on this link for trouble login    `}
        <Link style={{ cursor: "pointer" }}>Click here</Link>
      </FormHelperText>

      <Formik
        initialValues={initialState}
        validationSchema={Yup.object().shape({
          email_or_referCode: Yup.string().required(
            "Email or referCode is required"
          ),
          utrNumber: Yup.string().required("UTR number is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const yupErrors = {};
          if (errors) {
            Object.keys(errors).forEach((key) => {
              if (touched[key]) {
                yupErrors[key] = errors[key];
              }
            });
          }

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email_or_referCode-login">
                      User ID*
                    </InputLabel>
                    <OutlinedInput
                      id="email_or_referCode-login"
                      type="text"
                      value={values.email_or_referCode}
                      name="email_or_referCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter User ID"
                      fullWidth
                      error={Boolean(
                        touched.email_or_referCode &&
                          (errors.email_or_referCode ||
                            (errorList && errorList.email_or_referCode))
                      )}
                      autoComplete="off"
                    />

                    {touched.email_or_referCode &&
                      (errors.email_or_referCode ||
                        yupErrors?.email_or_referCode ||
                        (errorList && errorList.email_or_referCode)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="email_or_referCode"
                          id="email_or_referCode-login"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="utrNumber">UTR Number*</InputLabel>
                    <OutlinedInput
                      id="utrNumber"
                      type="text"
                      value={values.utrNumber}
                      name="utrNumber"
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 25 }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder="Enter Utr Number"
                      fullWidth
                      error={Boolean(touched.utrNumber && errors.utrNumber)}
                    />
                    {touched.utrNumber && errors.utrNumber && (
                      <FormHelperText error id="utrNumber">
                        {errors.utrNumber}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
      {showNotification && (
        <FormHelperText
          id="error"
          style={{
            backgroundColor: "#14cd7747",
            color: "#1d6745c7",
            padding: "10px",
            fontSize: "15px",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          Note: Your account has been processed for verification, please share
          the screenshot, it will be verified within 30 minutes. You will get a
          verification code to verify your account.
        </FormHelperText>
      )}

      <Grid item xs={12} className="mt-3">
        <AuthCodeVerification showNotificationProp={showNotification} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AuthTroubleLogin);
