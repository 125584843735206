// assets
import { DashboardOutlined } from "@ant-design/icons";

// icons
const icons = {
  DashboardOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const AdminDashboardPath = {
  id: "group-adminDashboard",
  title: "",
  type: "group",
  children: [
    {
      id: "adminDashboard",
      title: "Dashboard",
      type: "item",
      url: "dashboard",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};

export default AdminDashboardPath;
