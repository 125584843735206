import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Modal,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { strengthColor, strengthIndicator } from "utils/PasswordStrength";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Errors from "Notifications/Errors";
import AnimateButton from "../../../AnimateButton";
import { connect } from "react-redux";
import { setTransactionPassword } from "actions/auth";
import { useNavigate } from "react-router-dom";
import MainCard from "View/Admin/Components/MainCard";

const TransactionPasswordModal = ({
  open,
  onClose,
  errorList,
  setTransactionPassword,
  user,
}) => {
  const navigate = useNavigate();

  const initialState = {
    transactionPassword: "",
  };

  const userId = user._id;

  const [formData, setFormData] = useState(initialState);
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("");
  }, []);

  const onChange = (e) => {
    if (!e) return;

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      setStatus({ success: true });
      setSubmitting(false);

      setTransactionPassword(values, userId, navigate);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <Grid container>
      <MainCard>
        <Typography id="modal-modal-title" variant="h5">
          Set Transaction Password
        </Typography>

        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            transactionPassword: Yup.string()
              .min(3)
              .max(50)
              .required("Password is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => {
            const yupErrors = {};
            if (errors) {
              Object.keys(errors).forEach((key) => {
                if (touched[key]) {
                  yupErrors[key] = errors[key];
                }
              });
            }

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1} className="mt-3">
                      <InputLabel htmlFor="transactionPassword-profile">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.transactionPassword &&
                            (errors.transactionPassword ||
                              (errorList && errorList.transactionPassword))
                        )}
                        id="transactionPassword-profile"
                        type={showPassword ? "text" : "password"}
                        value={values.transactionPassword}
                        name="transactionPassword"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          onChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle transactionPassword visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="******"
                        inputProps={{}}
                      />

                      {touched.transactionPassword &&
                        (errors.transactionPassword ||
                          yupErrors?.transactionPassword ||
                          (errorList && errorList.transactionPassword)) && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            yupErrors={yupErrors}
                            currentKey="transactionPassword"
                            id="transactionPassword-profile"
                          />
                        )}
                    </Stack>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Box
                            sx={{
                              bgcolor: level?.color,
                              width: 85,
                              height: 8,
                              borderRadius: "7px",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" fontSize="0.75rem">
                            {level?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </MainCard>
    </Grid>
  );
};

TransactionPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setTransactionPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.auth.user,
});

export default connect(mapStateToProps, { setTransactionPassword })(
  TransactionPasswordModal
);
