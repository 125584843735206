import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// assets
import {
  CommentOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { RiLockPasswordLine } from "react-icons/ri";

import TransactionPasswordModal from "./TransactionPassword/TransactionPasswordModal";
import { isAdmin } from "utils/helper";
import ChangeTransactionPasswordModal from "./TransactionPassword/ChangeTransactionPasswordModal";

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = ({ user }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { isSetTxnPassword } = user;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 4) {
      navigate("/admin/users/transaction-password");
    }
    if (index === 3) {
      navigate("/admin/users/change-transaction-password");
    }
    if (index === 1) {
      navigate("admin-account-setting-list");
    }
    if (index === 0) {
      navigate("user-support");
    }
  };

  return (
    <>
      <List
        component="nav"
        sx={{
          p: 0,
          "& .MuiListItemIcon-root": {
            minWidth: 32,
            color: theme.palette.grey[500],
          },
        }}
      >
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <QuestionCircleOutlined />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItemButton>
        {isAdmin(user) ? (
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <UserOutlined />
            </ListItemIcon>
            <ListItemText primary="Account Settings" />
          </ListItemButton>
        ) : null}
        {/* <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <LockOutlined />
          </ListItemIcon>
          <ListItemText primary="Privacy Center" />
        </ListItemButton> */}
        {isAdmin(user)
          ? null
          : isSetTxnPassword && (
              <ListItemButton
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemIcon>
                  <RiLockPasswordLine />
                </ListItemIcon>
                <ListItemText primary="Change Transaction Password" />
              </ListItemButton>
            )}
        {isAdmin(user)
          ? null
          : !isSetTxnPassword && (
              <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemIcon>
                  <RiLockPasswordLine />
                </ListItemIcon>
                <ListItemText primary="Set Transaction Password" />
              </ListItemButton>
            )}
      </List>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(SettingTab);
