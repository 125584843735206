import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  editUser,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
  getUserById,
} from "actions/user";

const EditProfile = ({
  editUser,
  errorList,
  currentUser,
  setErrors,
  removeUserErrors,
  getUserById,
}) => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadUserFormData = (currentUser) => {
    const { firstname, lastname, email, phone } = currentUser;

    const data = {
      firstname,
      lastname,
      email,
      phone,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!user_id) return;
    getUserById(user_id);
  }, [getUserById, user_id]);

  React.useEffect(() => {
    if (!currentUser) return;

    loadUserFormData(currentUser);
  }, [currentUser]);

  const { firstname, lastname, email, phone } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeUserErrors();

    let validationRules = [
      {
        path: "firstname",
        msg: "First Name is required.",
      },

      {
        path: "lastname",
        msg: "Last Name is required.",
      },
      {
        path: "email",
        msg: "Email is required.",
      },
      {
        path: "phone",
        msg: "Phone is requuired.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    editUser(submitData, navigate, user_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    loadUserFormData(currentUser);
    toggleEdit();
  };

  return (
    <Card className="card-body">
      <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
        <div className="card-heading mb-3">
          <h4 className="header-title">User Information</h4>
          <Button
            variant="link"
            size="sm"
            className="float-end"
            onClick={toggleEdit}
          >
            {isDisabled ? (
              <span>
                <MdEdit title="Click to Edit" size={20} />
              </span>
            ) : (
              <span>
                <FaRegEye title="View mode" size={20} />
              </span>
            )}
          </Button>
        </div>

        <Form.Group className="form-group">
          <Form.Label htmlFor="firstname">
            First Name <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.firstname ? "invalid" : ""}
            type="text"
            id="firstname"
            name="firstname"
            maxLength="100"
            value={firstname}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />
          <Errors
            errorList={errorList}
            current_key="firstname"
            id="firstname"
          />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="lastname">
            Last Name <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.lastname ? "invalid" : ""}
            type="text"
            id="lastname"
            name="lastname"
            maxLength="100"
            value={lastname}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />
          <Errors errorList={errorList} current_key="lastname" id="lastname" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="email">
            Email <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.email ? "invalid" : ""}
            type="mail"
            id="email"
            name="email"
            maxLength="100"
            value={email}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          {errorList && errorList?.email && (
            <Errors errorList={errorList} current_key="email" id="email" />
          )}
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="phone">
            Phone <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.phone ? "invalid" : ""}
            type="mail"
            id="phone"
            name="phone"
            maxLength="100"
            value={phone}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors errorList={errorList} current_key="phone" id="phone" />
        </Form.Group>

        <div className="float-end">
          <Button
            className="m-2"
            type="submit"
            variant="primary"
            disabled={submitting || isDisabled}
          >
            {submitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {` Loading... `}
              </>
            ) : (
              <>Save</>
            )}
          </Button>
          <Button
            className="ml-2"
            type="reset"
            variant="danger"
            onClick={onClickCancel}
            disabled={submitting || isDisabled}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Card>
  );
};

EditProfile.propTypes = {
  editUser: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingUser: state.user.loadingUser,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, {
  editUser,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
  getUserById,
})(EditProfile);
