import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { getAdminAllDownline } from "actions/dashboardActions";
import { Grid } from "../../../../node_modules/@mui/material/index";
import MainCard from "../Components/MainCard";
import { capitalizeFirst } from "utils/helper";
import { Button } from "react-bootstrap";
import AppPagination from "View/DataTable/AppPagination";

const TreeDownline = ({
  getAdminAllDownline,
  adminAllDownlineList: { count, data },
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const [params, setParams] = React.useState(initialSortingParams);

  useEffect(() => {
    getAdminAllDownline(params);
  }, [getAdminAllDownline, params]);

  const TreeNode = ({ node }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
      setIsExpanded(!isExpanded);
    };

    const hasChildren = (node) => {
      return node.children && node.children.length > 0;
    };

    return (
      <MainCard>
        <Grid container className="tree-node overflow-scroll px-3 ">
          <Grid
            item
            sx={{ cursor: "pointer" }}
            className={`node-content ${
              isExpanded ? "expanded info.main" : ""
            } `}
          >
            {/* Displaying node content */}
            <Grid className="d-flex gap-2 flex-column">
              {" "}
              <span className="node-content fw-bold text-muted">
                <span className="node-heading fw-bold  text-black">Name: </span>
                {`${capitalizeFirst(node.user.firstname)} ${capitalizeFirst(
                  node.user.lastname
                )}`}
              </span>
              <span className="node-content fw-bold  text-muted">
                <span className="fw-bold node-heading  text-black">
                  Email:{" "}
                </span>
                {node.user.email}
              </span>
              <span className="node-content fw-bold  text-muted">
                <span className="fw-bold node-heading text-black">
                  Reffer Code:{" "}
                </span>
                {node.user.myReferCode}
              </span>
            </Grid>
            <Grid className="d-flex downlineIcon">
              {hasChildren(node) && (
                <span className="node-toggle" onClick={handleToggle}>
                  {isExpanded ? (
                    <Button>
                      Hide <FaAngleUp />
                    </Button>
                  ) : (
                    <Button>
                      Show <FaAngleDown />
                    </Button>
                  )}
                </span>
              )}
            </Grid>
            {/* Icons for expand/collapse */}
            {isExpanded && hasChildren(node) && (
              <ul className="child-nodes">
                {node.children.map((childNode, i) => (
                  <li key={`${childNode.id}_${i}`}>
                    <TreeNode node={childNode} />
                  </li>
                ))}
              </ul>
            )}
          </Grid>
        </Grid>
      </MainCard>
    );
  };

  return (
    <>
      <div className="tree-downline ">
        {data.map((rootNode, i) => (
          <TreeNode key={`${rootNode.id}_${i}`} node={rootNode} />
        ))}
      </div>
      <div>
        <AppPagination count={count} params={params} setParams={setParams} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  adminAllDownlineList: state.dashboard.adminAllDownlineList,
});

export default connect(mapStateToProps, { getAdminAllDownline })(TreeDownline);
