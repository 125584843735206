import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  totalUserCount: 0,
  currentWallet: 0,
  usetTotalDrCr: null,
  userIncomesList: [],
  userDownlineList: null,
  userAllDownlineList: [],
  adminAllDashboardData: {},
  adminAllDashboardLoading: true,
  // userAllDownlineListData: [],
  // adminAllDownlineList: [],

  userAllDownlineListData: {
    page: 1,
    data: [],
    count: 0,
  },

  adminAllDownlineList: {
    page: 1,
    data: [],
    count: 0,
  },

  loadingDashboard: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    userTotalCount(state, action) {
      return {
        ...state,
        totalUserCount: action.payload,
      };
    },

    usetTotalDrCr(state, action) {
      return {
        ...state,
        usetTotalDrCr: action.payload,
      };
    },

    userCurrentWallet(state, action) {
      return {
        ...state,
        currentWallet: action.payload,
      };
    },

    userAllIncomes(state, action) {
      return {
        ...state,
        userIncomesList: action.payload,
      };
    },

    userDownline(state, action) {
      return {
        ...state,
        userDownlineList: action.payload,
      };
    },

    userAllDownlineList(state, action) {
      return {
        ...state,
        userAllDownlineList: action.payload,
      };
    },
    adminAllDownlineList(state, action) {
      console.log("action------", action.payload);
      return {
        ...state,
        adminAllDownlineList: {
          data: action.payload.data,
          page: action.payload.metadata.current_page,
          count: action.payload.metadata.totalRecord,
        },
      };
    },
    userAllDownlineListData(state, action) {
      console.log("action------", action.payload);
      return {
        ...state,
        userAllDownlineListData: {
          data: action.payload.data,
          page: action.payload.metadata.current_page,
          count: action.payload.metadata.totalRecord,
        },
      };
    },

    adminAllDashboardData(state, action) {
      return {
        ...state,
        adminAllDashboardData: action.payload,
        adminAllDashboardLoading: false,
      };
    },

    resetDashboard(state) {
      return {
        ...initialState,
      };
    },

    dashboardError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingDashboard: false,
      };
    },
  },
});
export const {
  userTotalCount,
  usetTotalDrCr,
  resetDashboard,
  dashboardError,
  userCurrentWallet,
  userAllIncomes,
  userDownline,
  userAllDownlineList,
  adminAllDashboardData,
  adminAllDownlineList,
  userAllDownlineListData,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
