import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  editUser,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
  getUserById,
} from "actions/user";

const EditUser = ({
  editUser,
  errorList,
  currentUser,
  setErrors,
  removeUserErrors,
  getUserById,
}) => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    referralCode: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    status: "",
    password: "",
    transactionPassword: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadUserFormData = (currentUser) => {
    const {
      firstname,
      lastname,
      email,
      phone,
      referralCode,
      address,
      city,
      state,
      zip,
      status,
      password,
      transactionPassword,
    } = currentUser;

    const data = {
      firstname,
      lastname,
      email,
      phone,
      referralCode,
      address,
      city,
      state,
      zip,
      status,
      password,
      transactionPassword,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!user_id) return;
    getUserById(user_id);
  }, [getUserById, user_id]);

  React.useEffect(() => {
    if (!currentUser) return;

    loadUserFormData(currentUser);
  }, [currentUser]);

  const {
    firstname,
    lastname,
    email,
    phone,
    referralCode,
    address,
    city,
    state,
    zip,
    status,
    password,
    transactionPassword,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeUserErrors();

    let validationRules = [
      {
        path: "firstname",
        msg: "First Name is required.",
      },

      {
        path: "lastname",
        msg: "Last Name is required.",
      },
      {
        path: "email",
        msg: "Email is required.",
      },
      {
        path: "phone",
        msg: "Phone is requuired.",
      },

      {
        path: "referralCode",
        msg: "Referral Code is requuired.",
      },

      {
        path: "address",
        msg: "Address Code is requuired.",
      },

      {
        path: "city",
        msg: "City is requuired.",
      },

      {
        path: "state",
        msg: "State is requuired.",
      },

      {
        path: "zip",
        msg: "Zip Code is requuired.",
      },

      {
        path: "status",
        msg: "Status is requuired.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    editUser(submitData, navigate, user_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    loadUserFormData(currentUser);
    toggleEdit();
  };

  return (
    <Card className="card-body">
      <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
        <div className="card-heading mb-3">
          <h4 className="header-title">User Information</h4>
          <Button
            variant="link"
            size="sm"
            className="float-end"
            onClick={toggleEdit}
          >
            {isDisabled ? (
              <span>
                <MdEdit title="Click to Edit" size={20} />
              </span>
            ) : (
              <span>
                <FaRegEye title="View mode" size={20} />
              </span>
            )}
          </Button>
        </div>

        <Form.Group className="form-group">
          <Form.Label htmlFor="firstname">
            First Name <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.firstname ? "invalid" : ""}
            type="text"
            id="firstname"
            name="firstname"
            maxLength="100"
            value={firstname}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />
          <Errors current_key="firstname" key="firstname" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="lastname">
            Last Name <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.lastname ? "invalid" : ""}
            type="text"
            id="lastname"
            name="lastname"
            maxLength="100"
            value={lastname}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />
          <Errors current_key="lastname" key="lastname" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="email">
            Email <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.email ? "invalid" : ""}
            type="mail"
            id="email"
            name="email"
            maxLength="100"
            value={email}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="email" key="email" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="phone">
            Phone <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.phone ? "invalid" : ""}
            type="mail"
            id="phone"
            name="phone"
            maxLength="10"
            value={phone}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <Errors current_key="phone" key="phone" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="referralCode">
            Refer By <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.referralCode ? "invalid" : ""}
            type="mail"
            id="referralCode"
            name="referralCode"
            maxLength="100"
            value={referralCode}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="referralCode" key="referralCode" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="address">
            Address <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.address ? "invalid" : ""}
            type="mail"
            id="address"
            name="address"
            maxLength="100"
            value={address}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="address" key="address" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="city">
            City <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.city ? "invalid" : ""}
            type="mail"
            id="city"
            name="city"
            maxLength="100"
            value={city}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="city" key="city" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="state">
            State <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.state ? "invalid" : ""}
            type="mail"
            id="state"
            name="state"
            maxLength="100"
            value={state}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="state" key="state" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="zip">
            Zip <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.zip ? "invalid" : ""}
            type="mail"
            id="zip"
            name="zip"
            maxLength="6"
            value={zip}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <Errors current_key="zip" key="zip" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="password">
            Password <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.password ? "invalid" : ""}
            type="text"
            id="password"
            name="password"
            value={password}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="password" key="password" />
        </Form.Group>

        {/* New Form.Group for Transaction Password */}
        <Form.Group className="form-group">
          <Form.Label htmlFor="transactionPassword">
            Transaction Password <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.transactionPassword ? "invalid" : ""}
            type="text"
            id="transactionPassword"
            name="transactionPassword"
            value={transactionPassword}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="transactionPassword" key="transactionPassword" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="status">
            Activate/Deactivate <span>*</span>
          </Form.Label>

          <Form.Select
            className={errorList.status ? "invalid" : ""}
            id="status"
            name="status"
            value={status}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          >
            <option value="1">Active</option>
            <option value="2">Inactive</option>
          </Form.Select>

          <Errors current_key="status" key="status" />
        </Form.Group>

        <div className="float-end">
          <Button
            className="m-2"
            type="submit"
            variant="primary"
            disabled={submitting || isDisabled}
          >
            {submitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {` Loading... `}
              </>
            ) : (
              <>Save</>
            )}
          </Button>
          <Button
            className="ml-2"
            type="reset"
            variant="danger"
            onClick={onClickCancel}
            disabled={submitting || isDisabled}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Card>
  );
};

EditUser.propTypes = {
  editUser: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingUser: state.user.loadingUser,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, {
  editUser,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
  getUserById,
})(EditUser);
