import Dashboard from "View/Admin/Dashboard/Dashboard";

// Bank files
import CredentialsListIndex from "View/Admin/ManageBank/CredentialsListIndex";

import CreateBank from "View/Admin/ManageBank/Bank/CreateBank";
import EditBank from "View/Admin/ManageBank/Bank/EditBank";

import CreateUPI from "View/Admin/ManageBank/UPI/CreateUPI";
import EditUpi from "View/Admin/ManageBank/UPI/EditUpi";

import CreateQRScanner from "View/Admin/ManageBank/QRScanner/CreateQRScanner";
import EditQRScanner from "View/Admin/ManageBank/QRScanner/EditQRScanner";

// Earning files
import SpinAndWin from "View/Admin/Earning/SpinAndWin/SpinAndWin";
import YouTubeEarning from "View/Admin/Earning/YouTube/YouTubeEarning";
import TelegramEarning from "View/Admin/Earning/Telegram/TelegramEarning";

// Withdrawal
import Withdrawal from "View/Admin/Banking/Withdrawal/Withdrawal";
import UserApproval from "View/Admin/Components/Header/Profile/UserApproval/UserApproval";

// Histories
import EarningHistory from "View/Admin/History/EarningHistory";
import WithdrawalHistory from "View/Admin/History/WithdrawalHistory";
import WalletHistory from "View/Admin/History/WalletHistory";
import UserEarningHistory from "View/Admin/History/UserEarningHistory";
import UserWalletHistory from "View/Admin/History/UserWalletHistory";

//User
import EditProfile from "View/Admin/Components/Header/Profile/EditProfile";
import ApprovalRequest from "View/Admin/AdminRequests/ApprovalRequest";
import WithdrawalRequest from "View/Admin/AdminRequests/WithdrawalRequest";
import SetDefaultValues from "View/Admin/Components/Header/Profile/AdminAccountSetting/SetDefaulValues/SetDefaultValues";
import SetDefaultValuesEdit from "View/Admin/Components/Header/Profile/AdminAccountSetting/SetDefaulValues/SetDefaultValuesEdit";
import UserDownLine from "View/Admin/Downline/UserDownLine";
import AdminDashboard from "View/Admin/Dashboard/AdminDashboard";
import EditApprovalRequest from "View/Admin/AdminRequests/EditApprovalRequest";
import EditWithdrawalRequest from "View/Admin/AdminRequests/EditWithdrawalRequest";
import AdminDownline from "View/Admin/Downline/AdminDownline";
import AdminAccountSetting from "View/Admin/Components/Header/Profile/AdminAccountSetting/AdminAccountSetting";

// Earning Links
import CreateEarningLink from "View/Admin/Components/Header/Profile/AdminAccountSetting/AdminLinks/CreateEarningLink";
import EditEarningLinks from "View/Admin/Components/Header/Profile/AdminAccountSetting/AdminLinks/EditEarningLinks";

// User Section
import UsersList from "View/Admin/Users/UsersList";
import EditUser from "View/Admin/Users/EditUser";
import EditIndex from "View/Admin/Users/EditIndex";
import UsersUnapprovedList from "View/Admin/Users/UsersUnapprovedList";
import ChangePasswordModal from "View/Auth/ChangePasswordModal";
import UserSupport from "View/Admin/UserSupport/UserSupport";
import UserTreeDownline from "View/Admin/Downline/UserTreeDownline";

// Products Section
import ProductCards from "View/Admin/Products/ProductCards";
import ProductsList from "View/Admin/Products/ProductsList";
import CreateProduct from "View/Admin/Products/CreateProduct";
import EditProduct from "View/Admin/Products/EditProduct";
import AdminOrdersRequest from "View/Admin/Products/AdminOrdersRequest";
import UserOrdersHistory from "View/Admin/Products/UserOrdersHistory";
import AdminEditOrderRequest from "View/Admin/Products/AdminEditOrderRequest";

// Order Section
import UserCheckout from "View/Admin/Products/UserCheckout";
import ProfileInfo from "View/Admin/Components/Header/Profile/ProfileInfo";
import TreeDownline from "View/Admin/Downline/TreeDownline";
import TransactionPasswordModal from "View/Admin/Components/Header/Profile/TransactionPassword/TransactionPasswordModal";
import ChangeTransactionPasswordModal from "View/Admin/Components/Header/Profile/TransactionPassword/ChangeTransactionPasswordModal";

const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Admin Dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/user-dashboard",
    name: "Admin Dashboard",
    element: <Dashboard />,
  },

  //Direct Joining

  // {
  //   path: "/users/register",
  //   name: "User Register",
  //   element: <UserRegister />,
  // },

  // Bank Section
  {
    path: "/credentials",
    name: "Bank UPIs List",
    element: <CredentialsListIndex />,
  },
  {
    path: "/credentials/banks/create",
    name: "Add Bank",
    element: <CreateBank />,
  },
  {
    path: "/credentials/bank/:bank_id/edit",
    name: "Edit Bank",
    element: <EditBank />,
  },

  // UPI Section

  {
    path: "/credentials/upis/create",
    name: "Add UPI",
    element: <CreateUPI />,
  },
  {
    path: "/credentials/upis/:upi_id/edit",
    name: "Edit UPI",
    element: <EditUpi />,
  },
  // QR sCANNER Section

  {
    path: "/credentials/qr-scanner/create",
    name: "Add qr Scanner",
    element: <CreateQRScanner />,
  },
  {
    path: "/credentials/qr-scanner/:scanner_id/edit",
    name: "Edit QR Scanner",
    element: <EditQRScanner />,
  },

  // Earning Section
  {
    path: "/earnings/spin-and-win",
    name: "Spin And win",
    element: <SpinAndWin />,
  },
  {
    path: "/earnings/youtube",
    name: "Youtube",
    element: <YouTubeEarning />,
  },
  {
    path: "/earnings/telegram",
    name: "Telegram",
    element: <TelegramEarning />,
  },

  // Downline Section
  {
    path: "/my-downline",
    name: "Downline",
    element: <UserDownLine />,
  },
  {
    path: "/all-user-downline",
    name: "Downline",
    element: <UserTreeDownline />,
  },
  {
    path: "/admin-downline",
    name: "Downline",
    element: <TreeDownline />,
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    element: <Withdrawal />,
  },

  // History
  {
    path: "/withdrawal-history",
    name: "Withdrawal History",
    element: <WithdrawalHistory />,
  },
  {
    path: "/earning-history",
    name: "Earning History",
    element: <EarningHistory />,
  },
  {
    path: "/wallet-histories",
    name: "Wallet History",
    element: <WalletHistory />,
  },

  {
    path: "/user-earning-history",
    name: "Earning History",
    element: <UserEarningHistory />,
  },
  {
    path: "/user-wallet-histories",
    name: "Wallet History",
    element: <UserWalletHistory />,
  },

  // Profile Tab
  {
    path: "/user-approval",
    name: "User Approval",
    element: <UserApproval />,
  },
  {
    path: "/users/:user_id/*",
    name: "Edit Profile",
    element: <EditProfile />,
  },

  {
    path: "/profile-info/*",
    name: "Profile Info",
    element: <ProfileInfo />,
  },
  {
    path: "/set-default-values",
    name: "Set Default Values",
    element: <SetDefaultValues />,
  },
  {
    path: "/admin-account-setting-list",
    name: "Admin Account Setting List",
    element: <AdminAccountSetting />,
  },
  {
    path: "/admin-account-edit/:accountSetting_id",
    name: "Admin Account List",
    element: <SetDefaultValuesEdit />,
  },

  // Earning Links

  {
    path: "/earning-links/create",
    name: "Add Earning Link",
    element: <CreateEarningLink />,
  },

  {
    path: "/earning-links/:earningLink_id/edit",
    name: "Edit Earning Link",
    element: <EditEarningLinks />,
  },

  // Admin Request
  {
    path: "/approval-request",
    name: "Approval Request",
    element: <ApprovalRequest />,
  },

  {
    path: "/approvals/:approval_id/edit",
    name: "Edit Approval Request",
    element: <EditApprovalRequest />,
  },
  {
    path: "/withdrawal-request",
    name: "Withdrawal Request",
    element: <WithdrawalRequest />,
  },

  {
    path: "/withdrawals/:withdrawal_id/edit",
    name: "Edit Withdrawal Request",
    element: <EditWithdrawalRequest />,
  },

  // User Section

  {
    path: "/users-all-list",
    name: "All Users List",
    element: <UsersList />,
  },

  {
    path: "/unapproved-users-all-list",
    name: "All Unapproved Users List",
    element: <UsersUnapprovedList />,
  },

  {
    path: "/users/edit/:user_id/*",
    name: "Admin Edit User",
    element: <EditIndex />,
  },

  {
    path: "/users/change-password",
    name: "Change Password Modal",
    element: <ChangePasswordModal />,
  },

  {
    path: "/users/transaction-password",
    name: "Transaction Password Modal",
    element: <TransactionPasswordModal />,
  },
  {
    path: "/users/change-transaction-password",
    name: "Change Transaction Password Modal",
    element: <ChangeTransactionPasswordModal />,
  },
  {
    path: "/user-support",
    name: "User Support",
    element: <UserSupport />,
  },

  // Products Section

  {
    path: "/products",
    name: "Products List",
    element: <ProductsList />,
  },

  {
    path: "/products/create",
    name: "Product Create",
    element: <CreateProduct />,
  },

  {
    path: "/products/:product_id/edit",
    name: "Product Edit By ID",
    element: <EditProduct />,
  },
  {
    path: "/orders/request",
    name: "Orders Request",
    element: <AdminOrdersRequest />,
  },

  {
    path: "/user/orders/history",
    name: "User Orders History",
    element: <UserOrdersHistory />,
  },
  {
    path: "/products/list",
    name: "User Orders History",
    element: <ProductCards />,
  },

  {
    path: "/orders/checkout",
    name: "User Orders Checkout",
    element: <UserCheckout />,
  },
  {
    path: "order/approvals/:order_id/edit",
    name: "Admin Edit Order Request",
    element: <AdminEditOrderRequest />,
  },
];

export default AdminRoutes;
