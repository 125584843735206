import { Link } from "react-router-dom";

// material-ui
import { Grid, Stack, Typography } from "@mui/material";

// project import
import AuthWrapper from "./AuthWrapper";
import logo from "../../assets/images/logo_sof.png";
import AuthChangeForgotPassword from "./auth-forms/AuthChangeForgotPassword";

const ChangeForgotPassword = () => {
  return (
    <AuthWrapper>
      <Grid container justifyContent="center">
        <img src={logo} height={200} alt="" />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography variant="h3">Change Password</Typography>
            {/* <Typography
              component={Link}
              to="/register"
              variant="body1"
              sx={{ textDecoration: "none" }}
              color="primary"
            >
              Don&apos;t have an account?
            </Typography> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthChangeForgotPassword />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default ChangeForgotPassword;
