import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import React, { useState } from "react";
import { Grid, Modal, IconButton, Typography } from "@mui/material";
import YouTubeVideos from "./YouTubeVideos";
import MainCard from "View/Admin/Components/MainCard";
import { RxCross2 } from "react-icons/rx";
import hand from "../../../../assets/images/youtube-6362801_1280.png";

import { getAdminEarningLinksList } from "actions/adminAccountSettingActions";
import { createYoutube } from "actions/youtubeActions";

const YouTubeEarning = ({
  getAdminEarningLinksList,
  adminEarningLinksList: { data },
  createYoutube,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [vdTitle, setVDTitle] = useState(null);
  const [showCrossButton, setShowCrossButton] = useState(false);

  const [youtubeLinksData, setYoutubeLinksData] = useState(null);
  const [itemVideo, setItemVideo] = useState(null);

  // Function to handle modal open and set the selected videoId
  const handleOpenModal = (item) => {
    setSelectedVideo(item.link);
    setVDTitle(item.title);
    setItemVideo(item);
    setOpen(true);

    const timer = setTimeout(() => {
      setShowCrossButton(true);
    }, 20000);
    return () => clearTimeout(timer);
  };

  const handleCloseModal = (itemVideo) => {
    setOpen(false);
    setShowCrossButton(false);
    createYoutube(itemVideo._id);
  };

  React.useEffect(() => {
    const youtubeLinks = data.filter((item) => item.linkType === "youtube");

    setYoutubeLinksData(youtubeLinks);
  }, [data]);

  React.useEffect(() => {
    getAdminEarningLinksList();
  }, [getAdminEarningLinksList]);

  return (
    <Grid container spacing={2}>
      {youtubeLinksData && youtubeLinksData.length
        ? youtubeLinksData.map((item, i) => (
            <Grid item xs={12} lg={4} key={i}>
              <MainCard
                onClick={() => handleOpenModal(item)}
                style={{
                  backgroundImage:
                    "linear-gradient(to left, #ADD8E6, #87CEEB, #00BFFF)",
                  position: "relative",
                  color: "#ffffff",
                }}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="h5"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  Watch and Earn
                  <img src={hand} alt="" height={50} />
                </Typography>
              </MainCard>
            </Grid>
          ))
        : null}

      <Modal open={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 300,
            bgcolor: "white",
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <MainCard>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ height: "40px", marginBottom: "10px" }}
            >
              <Grid item>
                <Typography variant="h3" color="initial">
                  {vdTitle}
                </Typography>
              </Grid>
              {showCrossButton ? (
                <IconButton
                  aria-label="close"
                  onClick={() => handleCloseModal(itemVideo)}
                  sx={{
                    zIndex: 1,
                    bgcolor: "",
                    borderRadius: "50%",
                  }}
                >
                  <RxCross2 />
                </IconButton>
              ) : (
                ""
              )}
            </Grid>
            <Grid container>
              {selectedVideo && <YouTubeVideos videoId={selectedVideo} />}
            </Grid>
            <Grid
              item
              style={{
                backgroundColor: "#ffe0b2",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <Typography variant="body1" color="error.main">
                Please watch the video for at least 30 seconds to earn your
                reward.
              </Typography>
            </Grid>
          </MainCard>
        </div>
      </Modal>
    </Grid>
  );
};

YouTubeEarning.propTypes = {
  errorList: PropTypes.object.isRequired,
  getAdminEarningLinksList: PropTypes.func.isRequired,
  createYoutube: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  adminEarningLinksList: state.accountSetting.adminEarningLinksList,
  loadingAdminEarningLinksList:
    state.accountSetting.loadingAdminEarningLinksList,
});

export default connect(mapStateToProps, {
  getAdminEarningLinksList,
  createYoutube,
})(YouTubeEarning);
