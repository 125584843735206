import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { FaWallet } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import {
  Grid,
  Typography,
  Stack,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Modal,
  IconButton,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import MainCard from "View/Admin/Components/MainCard";
import { Formik } from "formik";
import * as Yup from "yup";
import MarqueeText from "View/Admin/Components/Marquee/Marquee";
import { getUpisList } from "actions/upiActions";
import { getBanksList } from "actions/bankActions";
import { getAdminAccountSettingsList } from "actions/adminAccountSettingActions";
import {
  createWithdrawal,
  getUserApprovedDownlineCount,
  getWithdrawalsList,
  resetComponentStore,
} from "actions/withdrawalActions";
import { getUserTotalDRCR } from "actions/dashboardActions";
import TransactionPasswordModal from "View/Admin/Components/Header/Profile/TransactionPassword/TransactionPasswordModal";
import YouTubeVideos from "View/Admin/Earning/YouTube/YouTubeVideos";

import { IoVideocam } from "react-icons/io5";
import { TbHandClick } from "react-icons/tb";

const Withdrawal = ({
  getUpisList,
  upisList,
  getBanksList,
  banksList,
  user,
  createWithdrawal,
  getWithdrawalsList,
  withdrawalsList: { data },
  resetComponentStore,
  getAdminAccountSettingsList,
  adminAccountSettingsList,
  getUserTotalDRCR,
  usetTotalDrCr,
  getUserApprovedDownlineCount,
  approvedDownlineCount,
}) => {
  const navigate = useNavigate();

  const initialState = {
    methodID: "",
    amount: "",
    transactionPassword: "",
  };

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  React.useEffect(() => {
    getAdminAccountSettingsList();
  }, [getAdminAccountSettingsList]);

  const [clientParams, setClientParams] = React.useState(initialSortingParams);

  const [onlyOnce, setOnce] = React.useState(true);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(initialState);
  const [selectedLabel, setSelectedLabel] = React.useState("");
  const [withdrawalPending, setWithdrawalPending] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [withdrawalClosed, setWithdrawalClosed] = React.useState(false);
  const [approvedUsersCount, setApprovedUsersCount] = React.useState(0);
  const [minimumWithdrawalValue, setMinimumWithdrawalValue] = React.useState(0);
  const [maximumWithdrawalValue, setMaximumWithdrawalValue] = React.useState(0);
  const [videoLink, setVideoLink] = React.useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpen(false);
  };
  const openTransactionPasswordModal = () => {
    // setOpenModal(true);
    navigate("/admin/users/transaction-password");
  };
  const onChange = (e) => {
    if (!e) return;

    const { name, value } = e.target;

    switch (name) {
      case "methodID":
        handleMethodChange(value);
        break;

      default:
        setFormData({ ...formData, [name]: value });
    }
  };

  const handleMethodChange = (value) => {
    if (upisList.data.length === 0 && banksList.data.length === 0) {
      setSelectedLabel("");
      return;
    }

    switch (value) {
      case upisList.data.length > 0 && upisList.data[0]._id:
        setSelectedLabel("UPI");
        break;
      case banksList.data.length > 0 && banksList.data[0]._id:
        setSelectedLabel("Bank");
        break;
      default:
        setSelectedLabel("");
        break;
    }
  };

  React.useEffect(() => {
    if (!adminAccountSettingsList && !adminAccountSettingsList.data.length) {
      return;
    }

    const isClosed =
      adminAccountSettingsList.data[0]?.withdrawalIsClosed === "false"
        ? true
        : false;

    setWithdrawalClosed(isClosed);

    const miniWithdrawal = adminAccountSettingsList.data[0]?.minimumWithdrawal
      ? parseInt(adminAccountSettingsList.data[0]?.minimumWithdrawal)
      : 0;

    setMinimumWithdrawalValue(miniWithdrawal);

    const maxWithdrawal = adminAccountSettingsList.data[0]?.maximumWithdrawal
      ? parseInt(adminAccountSettingsList.data[0]?.maximumWithdrawal)
      : 0;

    setMaximumWithdrawalValue(maxWithdrawal);

    const howToWidLink = adminAccountSettingsList.data[0]?.howToWithdrawalLink
      ? adminAccountSettingsList.data[0]?.howToWithdrawalLink
      : null;

    setVideoLink(howToWidLink);
  }, [
    adminAccountSettingsList,
    setWithdrawalClosed,
    setMinimumWithdrawalValue,
    setMaximumWithdrawalValue,
    setVideoLink,
  ]);

  React.useEffect(() => {
    if (onlyOnce) {
      setOnce(false);
    }
    getUserTotalDRCR();
    getUpisList(clientParams);
    getBanksList(clientParams);
  }, [getUpisList, getBanksList, clientParams]);

  React.useEffect(() => {
    getUserApprovedDownlineCount();
  }, [getUserApprovedDownlineCount]);

  React.useEffect(() => {
    setApprovedUsersCount(approvedDownlineCount);
  }, [approvedDownlineCount]);

  React.useEffect(() => {
    const filteredData = data.filter((item) => item.status === "pending");

    const isAnyPending = filteredData.length > 0;

    setWithdrawalPending(isAnyPending);
  }, [data]);

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getWithdrawalsList(clientParams);
  }, [getWithdrawalsList, clientParams, resetComponentStore]);

  const onSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    try {
      const withdrawalAmount = parseFloat(values.amount);
      const minimumWithdrawal = parseFloat(minimumWithdrawalValue);
      const maxWithdrawal = parseInt(maximumWithdrawalValue);

      if (withdrawalAmount > parseFloat(user.wallet)) {
        setErrors({
          amount: "Withdrawal amount cannot be greater than wallet balance",
        });
        return;
      }

      if (withdrawalAmount <= minimumWithdrawal) {
        setErrors({
          amount:
            "Withdrawal amount must be greater than the minimum withdrawal balance.",
        });
        return;
      }

      if (withdrawalAmount > maxWithdrawal) {
        setErrors({
          amount: `Withdrawal amount cannot exceed ${maxWithdrawal}`,
        });
        return;
      }

      if (!user.isApproved) {
        setErrors({
          submit: "Please approve your account to proceed with withdrawals.",
        });
        return;
      }

      if (approvedUsersCount >= 0 && approvedUsersCount < 3) {
        setErrors({
          submit: "Please approve at least 3 direct members for withdrawal.",
        });
        return;
      } else if (approvedUsersCount >= 3 && approvedUsersCount < 5) {
        setErrors({
          submit: "Please approve at least 5 direct members for withdrawal.",
        });
        return;
      }

      values.selectedLabel = selectedLabel;
      setStatus({ success: false });
      setSubmitting(true);
      createWithdrawal(values, navigate);
      resetForm();
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };
  const handleOpenModal = () => {
    setSelectedVideo(videoLink);

    // setItemVideo(Id);
    setOpen(true);
  };
  return (
    <>
      <Grid container className="mb-4 bg-white  shadow-sm">
        <MarqueeText
          content={`${adminAccountSettingsList.data[0]?.alertText}`}
          contentType={"text-danger"}
        />
      </Grid>

      {withdrawalPending && (
        <div
          style={{
            backgroundColor: "#ffe0b2",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ fontWeight: "bold" }}
          >
            Info:
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Your withdrawal request has been submitted successfully. Please wait
            for admin approval.
          </Typography>
          <Typography variant="body1" color="textPrimary">
            You also have pending withdrawal(s). Your new withdrawal request
            will be processed after the pending one(s) are approved.
          </Typography>
        </div>
      )}

      <Grid container rowSpacing={1} columnSpacing={1} className="mb-3">
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <MainCard
            style={{
              backgroundImage: "linear-gradient(to left, #ff0000, #8b0000)",
              color: "#ffffff",
            }}
          >
            <Typography variant="h6" color="white">
              Wallet :-
            </Typography>

            <Typography
              variant="h4"
              color="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              {`₹ ${user && user.wallet ? user.wallet : 0}`}
              <FaWallet size={40} />
            </Typography>
          </MainCard>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={5}>
          <MainCard
            onClick={() => handleOpenModal()}
            style={{
              backgroundImage: "linear-gradient(to left, #add8e6, #00008b)",

              color: "#ffffff",
            }}
          >
            <Typography variant="h6" color="white">
              Easy Withdrawal Tutorial
            </Typography>

            <Typography
              variant="h4"
              color="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              Click Here
              <TbHandClick size={40} />
              {/* <IoVideocam size={40} /> */}
            </Typography>
          </MainCard>
        </Grid>
        <Modal open={open}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 300,
              bgcolor: "white",
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <MainCard>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ height: "40px", marginBottom: "10px" }}
              >
                <Grid item>
                  <Typography variant="h3" color="initial">
                    Quick Guide: Withdrawing Funds
                  </Typography>
                </Grid>
                <IconButton
                  aria-label="close"
                  onClick={() => handleCloseModal()}
                  sx={{
                    zIndex: 1,
                    bgcolor: "",
                    borderRadius: "50%",
                  }}
                >
                  <RxCross2 />
                </IconButton>
              </Grid>
              <Grid container>
                <YouTubeVideos videoId={selectedVideo} />
              </Grid>
            </MainCard>
          </div>
        </Modal>
      </Grid>
      <Formik
        initialValues={formData}
        validationSchema={Yup.object().shape({
          methodID: Yup.string().required("Please select a methodID"),
          amount: Yup.string().max(6).required("Amount is required"),
          transactionPassword: Yup.string()
            .max(50)
            .required("Transaction Password is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          submitCount,
          resetForm,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {adminAccountSettingsList &&
            adminAccountSettingsList.data.length &&
            adminAccountSettingsList.data[0]?.withdrawalIsClosed === "false" ? (
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <MainCard>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className="mb-1"
                    >
                      Note :-{" "}
                      <span className="fw-bold text-black fs-6">
                        {
                          adminAccountSettingsList.data[0]
                            ?.withdrawalNotification
                        }
                      </span>
                    </Typography>
                  </MainCard>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={5}>
                  <MainCard>
                    <Grid container spacing={2}>
                      <Grid item>
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Method
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="methodID"
                            value={values.methodID}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                          >
                            {upisList &&
                            upisList?.data &&
                            upisList.data.length ? (
                              <FormControlLabel
                                value={upisList.data[0]._id}
                                control={<Radio />}
                                label="UPI"
                                disabled={
                                  isSubmitting ||
                                  !user.isApproved ||
                                  withdrawalPending ||
                                  withdrawalClosed
                                }
                              />
                            ) : (
                              <FormHelperText error id="method-upi-error">
                                Add UPI First
                              </FormHelperText>
                            )}

                            {banksList &&
                            banksList?.data &&
                            banksList.data.length ? (
                              <FormControlLabel
                                value={banksList.data[0]._id}
                                control={<Radio />}
                                label="Bank Account"
                                disabled={
                                  isSubmitting ||
                                  !user.isApproved ||
                                  withdrawalPending ||
                                  withdrawalClosed
                                }
                              />
                            ) : (
                              <FormHelperText error id="method-bank-error">
                                Add Bank First
                              </FormHelperText>
                            )}
                          </RadioGroup>

                          {touched.methodID && errors.methodID && (
                            <FormHelperText error id="methodID-error">
                              {errors.methodID}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="amount">
                            Withdrawal Amount*
                          </InputLabel>
                          <OutlinedInput
                            id="amount"
                            type="text"
                            value={values.amount}
                            disabled={
                              isSubmitting ||
                              !user.isApproved ||
                              withdrawalPending ||
                              withdrawalClosed
                            }
                            name="amount"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            placeholder="100"
                            fullWidth
                            error={Boolean(touched.amount && errors.amount)}
                          />
                          {touched.amount && errors.amount && (
                            <FormHelperText error id="amount">
                              {errors.amount}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="transactionPassword">
                            Transaction Password*
                          </InputLabel>
                          <OutlinedInput
                            id="transactionPassword"
                            type="text"
                            value={values.transactionPassword}
                            disabled={
                              isSubmitting ||
                              !user.isApproved ||
                              withdrawalPending ||
                              (user && !user.isSetTxnPassword ? true : false) ||
                              withdrawalClosed
                            }
                            name="transactionPassword"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            placeholder="********"
                            fullWidth
                            error={Boolean(
                              touched.transactionPassword &&
                                errors.transactionPassword
                            )}
                          />
                          {touched.transactionPassword &&
                            errors.transactionPassword && (
                              <FormHelperText error id="transactionPassword">
                                {errors.transactionPassword}
                              </FormHelperText>
                            )}

                          {!user.isSetTxnPassword ? (
                            <Grid
                              item
                              className="d-flex justify-content-between align-items-center"
                            >
                              <FormHelperText
                                error
                                id="transactionPassword-tnx"
                              >
                                Add Transaction Password First
                              </FormHelperText>

                              <Button
                                variant="link"
                                onClick={openTransactionPasswordModal}
                              >
                                Click Here
                              </Button>
                            </Grid>
                          ) : null}
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <Button
                          disabled={
                            isSubmitting ||
                            !user.isApproved ||
                            !user.isSetTxnPassword ||
                            withdrawalPending ||
                            withdrawalClosed
                          }
                          style={{ width: "100px", marginRight: "30px" }}
                          size="small"
                          type="submit"
                          variant="primary"
                          color="primary"
                        >
                          Submit
                        </Button>

                        {!user.isApproved &&
                        adminAccountSettingsList &&
                        adminAccountSettingsList.data.length &&
                        adminAccountSettingsList.data[0]?.withdrawalIsClosed ===
                          "true" ? (
                          <Grid item>
                            <Link to={"/admin/user-approval"}>Click Here </Link>
                            to approve your account
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                    {errors.submit && (
                      <FormHelperText
                        error
                        id="submit"
                        style={{
                          backgroundColor: "#ffebee",
                          color: "red",
                          padding: "10px",
                          marginTop: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        {errors.submit}
                      </FormHelperText>
                    )}

                    {!user.isApproved &&
                    adminAccountSettingsList &&
                    adminAccountSettingsList.data.length &&
                    adminAccountSettingsList.data[0]?.withdrawalIsClosed ===
                      "true" ? (
                      <FormHelperText
                        id="error"
                        style={{
                          backgroundColor: "#ffebee",
                          color: "red",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {`Your account is currently not approved. Please approve your account to proceed with withdrawals.`}
                      </FormHelperText>
                    ) : null}
                    {adminAccountSettingsList &&
                    adminAccountSettingsList.data.length &&
                    adminAccountSettingsList.data[0]?.withdrawalIsClosed ===
                      "false" ? (
                      <FormHelperText
                        id="error"
                        style={{
                          backgroundColor: "#ffebee",
                          color: "red",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {`Currently, withdrawal is unavailable or you may not be eligible.`}
                      </FormHelperText>
                    ) : null}
                  </MainCard>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      </Formik>

      {/* <TransactionPasswordModal open={openModal} onClose={handleCloseModal} /> */}
    </>
  );
};

Withdrawal.propTypes = {
  getUpisList: PropTypes.func.isRequired,
  getBanksList: PropTypes.func.isRequired,
  createWithdrawal: PropTypes.func.isRequired,
  getWithdrawalsList: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  getUserTotalDRCR: PropTypes.func.isRequired,
  upisList: PropTypes.object.isRequired,
  banksList: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  withdrawalsList: PropTypes.object.isRequired,
  adminAccountSettingsList: PropTypes.object.isRequired,
  getUserApprovedDownlineCount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  upisList: state.upi.upisList,
  banksList: state.bank.banksList,
  withdrawalsList: state.withdrawal.withdrawalsList,
  user: state.auth.user,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
  usetTotalDrCr: state.dashboard.usetTotalDrCr,
  approvedDownlineCount: state.withdrawal.approvedDownlineCount,
});

export default connect(mapStateToProps, {
  getUpisList,
  getBanksList,
  createWithdrawal,
  getWithdrawalsList,
  getAdminAccountSettingsList,
  resetComponentStore,
  getUserTotalDRCR,
  getUserApprovedDownlineCount,
})(Withdrawal);
