import React from "react";
import Grid from "@mui/material/Grid";
import { IoMdCall, IoIosMail } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import MainCard from "../Components/MainCard";
import { Typography } from "@mui/material";

import { connect } from "react-redux";
import { getAdminAccountSettingsList } from "actions/adminAccountSettingActions";

import { FaWhatsapp } from "react-icons/fa";

const UserSupport = ({
  getAdminAccountSettingsList,
  adminAccountSettingsList: { data },
}) => {
  React.useEffect(() => {
    getAdminAccountSettingsList();
  }, [getAdminAccountSettingsList]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sm={6} lg={5}>
        <MainCard>
          <Typography variant="h5" color="textSecondary" className="mb-4">
            Reach Us At
          </Typography>
          <Grid container className="mb-2">
            <Typography color="textSecondary">
              {data && data.length && data[0]?.projectName}
            </Typography>
            {/* <Typography color="textsecondary">

            </Typography> */}
          </Grid>
          <Grid container className="mb-2" alignItems="center" gap={2}>
            <Typography color="textSecondary">
              <IoIosMail size={20} />
            </Typography>
            <Typography color="textSecondary">
              {data && data.length && data[0]?.email}
            </Typography>
          </Grid>
          <Grid container className="mb-2" alignItems="center" gap={2}>
            <Typography color="textSecondary">
              <IoMdCall size={20} />
            </Typography>
            <Typography color="textSecondary">
              {data && data.length && data[0]?.mobileNumber}
            </Typography>
          </Grid>
          <Grid container className="mb-2" alignItems="center" gap={2}>
            <Typography color="textSecondary">
              <FaWhatsapp size={20} />
            </Typography>
            <Typography color="textSecondary">
              {data && data.length && data[0]?.whatsAppNumber}
            </Typography>
          </Grid>
          <Grid container className="mb-2" gap={2}>
            <Typography color="textSecondary">
              <FaMapMarkerAlt size={20} />
            </Typography>
            <Typography color="textSecondary">
              Vinay Marg, Chanakyapuri, New Delhi – 110021 India
            </Typography>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  errorList: state.errors,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
});

export default connect(mapStateToProps, {
  getAdminAccountSettingsList,
})(UserSupport);
