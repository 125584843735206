import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import { baseURL } from "constants/index";
import {
  loadingOnQRScannerSubmit,
  loadingQRScannersList,
  qrScannerCreated,
  qrScannerDeleted,
  qrScannerDetailsById,
  qrScannerError,
  qrScannerListUpdated,
  qrScannerSearchParametersUpdate,
  qrScannerUpdated,
  resetQRScanner,
} from "reducers/qrScannerReducer";

export const getQRScannersList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingQRScannersList());
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/qr-scanner/list/${loggedInUserID}`,
      config
    );

    dispatch(qrScannerSearchParametersUpdate(appTokenParams));

    dispatch(qrScannerListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          qrScannerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getQRScannerById = (scanner_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnQRScannerSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/qr-scanner/${scanner_id}`,
      config
    );

    dispatch(qrScannerDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          qrScannerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getQRScannerByUserId = (user_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnQRScannerSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/credentials/qr-scanner/user/${user_id}`,
      config
    );

    dispatch(qrScannerDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          qrScannerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createQRScanner = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnQRScannerSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/credentials/qr-scanner`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(qrScannerCreated(res.data.response));
      dispatch(setAlert("qrScanner Created.", "success"));
      navigate(`/admin/credentials`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(qrScannerError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          qrScannerError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateQRScanner =
  (formData, navigate, scanner_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${baseURL}/api/admin/credentials/qr-scanner/${scanner_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(qrScannerUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(qrScannerError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            qrScannerError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteQRScanner = (scanner_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(
      `${baseURL}/api/admin/credentials/qr-scanner/${scanner_id}`,
      config
    );

    dispatch(qrScannerDeleted(scanner_id));
    dispatch(setAlert("QR Scanner deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        qrScannerError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeQRScannerErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetQRScanner());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(qrScannerError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/credentials");
};
