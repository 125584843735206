import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

// material-ui
import { Grid } from "@mui/material";

import { getOrdersList, resetComponentStore } from "actions/userOrderActions";

import AnimateButton from "View/Admin/Components/AnimateButton";
import Dot from "../Dashboard/Table/Dot";
import { capitalizeFirst } from "utils/helper";
import moment from "moment";
import { Button } from "react-bootstrap";
import OrderRequestFilters from "../AdminRequests/OrderRequestFilters";

const AdminOrdersRequest = ({
  ordersList: { data, count },
  getOrdersList,
  loadingOrdersList,
  resetComponentStore,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const columns = [
    {
      name: "Name",
      cell: (row) => capitalizeFirst(row.name),
      sortable: false,
      width: "15%",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: false,
      width: "15%",
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <div className="d-flex align-items-center gap-3">
          <Dot color={row.status} size={8} />
          {capitalizeFirst(row.status)}
        </div>
      ),
      sortable: false,
      width: "15%",
      wrap: true,
    },
    {
      name: "UTR Number",
      selector: (row) => row.utrNumber,
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("hh:mm a, DD-MM-YYYY"),
      sortable: false,
      width: "10%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "15%",
      button: true,
      cell: (row) => (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          spacing={2}
        >
          <Grid item xs={6} sm={3} lg={4}>
            <Link
              to={`/admin/order/approvals/${row._id}/edit`}
              title="View Approval"
            >
              <AnimateButton>
                <Button
                  type="submit"
                  variant="primary"
                  color="primary"
                  size="sm"
                >
                  <FaRegEye />
                </Button>
              </AnimateButton>
            </Link>
          </Grid>
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getOrdersList(clientParams);
  }, [getOrdersList, clientParams, resetComponentStore]);

  const handleSearch = (
    fromDate,
    toDate,
    statusType,
    searchType,
    searchValue
  ) => {
    getOrdersList({
      fromDate,
      toDate,
      statusType,
      searchType,
      searchValue,
      ...clientParams,
    });
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Orders Request" />

      <div className="table-filter-section mb-4">
        <OrderRequestFilters onSearch={handleSearch} />
      </div>

      <Card>
        <Card.Body>
          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingOrdersList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ordersList: state.userOrder.ordersList,
  loadingOrdersList: state.userOrder.loadingOrdersList,
  sortingParams: state.userOrder.sortingParams,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getOrdersList,
  resetComponentStore,
})(AdminOrdersRequest);
