import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingProductsList,
  productSearchParametersUpdate,
  productListUpdated,
  resetProduct,
  productError,
  loadingOnProductSubmit,
  productCreated,
  productUpdated,
  productDetailsById,
  productDeleted,
} from "reducers/productReducer";

import { baseURL } from "constants/index";

export const getProductsList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingProductsList());
    const res = await axios.get(
      `${baseURL}/api/admin/products/list/${loggedInUserID}`,
      config
    );
    dispatch(productSearchParametersUpdate(appTokenParams));

    dispatch(productListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          productError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getProductById = (product_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnProductSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/products/${product_id}`,
      config
    );

    dispatch(productDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          productError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createProduct = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnProductSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/products`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(productCreated(res.data.response));
      dispatch(setAlert("Product Created.", "success"));
      navigate(`/admin/products`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(productError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          productError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateProduct =
  (formData, navigate, product_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const res = await axios.put(
        `${baseURL}/api/admin/products/${product_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(productUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        navigate(`/admin/products`);
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(productError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            productError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteProduct = (product_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(`${baseURL}/api/admin/products/${product_id}`, config);

    dispatch(productDeleted(product_id));
    dispatch(setAlert("Product deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        productError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeProductErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetProduct());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(productError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/products");
};
