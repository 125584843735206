import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaRegEye } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

// material-ui
import { Grid } from "@mui/material";

import {
  getQRScannersList,
  resetComponentStore,
  deleteQRScanner,
} from "actions/qrScannerActions";

import AnimateButton from "View/Admin/Components/AnimateButton";

import { isAdmin } from "utils/helper";
import { Button } from "react-bootstrap";

const QRScannerList = ({
  qrScannersList: { data, count },
  getQRScannersList,
  loadingQRScannersList,
  resetComponentStore,
  sortingParams,
  deleteQRScanner,
  user,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const actions = (
    <Grid container spacing={2} className="mb-3">
      <Grid item xs={2}>
        <Link to="/admin/credentials/qr-scanner/create" title="create">
          <AnimateButton>
            <Button fullWidth size="small" type="button" variant="primary">
              Create QRScanner
            </Button>
          </AnimateButton>
        </Link>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: false,
      width: "25%",
    },
    {
      name: "Image URL",
      selector: (row) => (
        <img
          src={row.imageUrl}
          alt="Preview"
          style={{
            width: "100px",
            height: "100px",
            marginBottom: "10px",
          }}
        />
      ),
      sortable: false,
      width: "55%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "20%",
      button: true,
      cell: (row) => (
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          spacing={2}
        >
          {/* <Grid item xs={2}>
            {isAdmin(user) ? (
              <Link
                to={`/admin/credentials/qr-scanner/${row._id}/edit`}
                title="View QR Scanner"
              >
                  <Button
                    size="small"
                    type="submit"
                    variant="link"
                    color="primary"
                  >
                    <FaRegEye />
                  </Button>
              </Link>
            ) : (
                <Button
                  size="small"
                  type="submit"
                  variant="link"
                  color="primary"
                >
                  <FaRegEye />
                </Button>
            )}
          </Grid> */}

          {isAdmin(user) ? (
            <Grid item xs={6} sm={2}>
              <Button
                size="sm"
                type="submit"
                variant="danger"
                onClick={(e) => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete: ${row.title}?`
                    )
                  ) {
                    deleteQRScanner(row._id);
                  }
                }}
              >
                <BiTrash />
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getQRScannersList(clientParams);
  }, [getQRScannersList, clientParams, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="QR Scanners" />

      <Card>
        <Card.Body>
          {data && data.length ? null : actions}

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingQRScannersList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

QRScannerList.propTypes = {
  getQRScannersList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  qrScannersList: state.qrScanner.qrScannersList,
  loadingQRScannersList: state.qrScanner.loadingQRScannersList,
  sortingParams: state.qrScanner.sortingParams,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getQRScannersList,
  resetComponentStore,
  deleteQRScanner,
})(QRScannerList);
