import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// material-ui
import {
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import AnimateButton from "View/Admin/Components/AnimateButton";

import Errors from "Notifications/Errors";
import Spinner from "View/Spinners/Spinner";

import { validateForm } from "utils/validation";

import {
  createUpi,
  cancelSave,
  setErrors,
  removeUpiErrors,
  resetComponentStore,
} from "actions/upiActions";

import MainCard from "View/Admin/Components/MainCard";
import { upiOptions } from "constants/index";

const CreateUPI = ({
  createUpi,
  errorList,
  cancelSave,
  loadingUpi,
  setErrors,
  removeUpiErrors,
  resetComponentStore,
}) => {
  const navigate = useNavigate();

  const initialFormData = {
    upiHolderName: "",
    upiId: "",
    mobileNumber: "",
    selectedUpiOption: "paytm",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
    }

    removeUpiErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    createUpi(submitData, navigate).then((res) => {
      if (!res.status) return;
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  return (
    <React.Fragment>
      {loadingUpi ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            upiHolderName: Yup.string().required("Name is required"),
            upiId: Yup.string().required("UPI ID is required"),
            mobileNumber: Yup.string().required("Mobile Number is required"),
            selectedUpiOption: Yup.string().required("Option is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => {
            const yupErrors = {};
            if (errors) {
              Object.keys(errors).forEach((key) => {
                if (touched[key]) {
                  yupErrors[key] = errors[key];
                }
              });
            }

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <MainCard>
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        className="mb-2"
                      >
                        UPI
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="upiHolderName-upi">
                              Full Name*
                            </InputLabel>
                            <OutlinedInput
                              id="upiHolderName-upi"
                              type="text"
                              value={values.upiHolderName}
                              name="upiHolderName"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Full Name"
                              fullWidth
                              error={Boolean(
                                touched.upiHolderName &&
                                  (errors.upiHolderName ||
                                    (errorList && errorList.upiHolderName))
                              )}
                            />

                            {touched.upiHolderName &&
                              (errors.upiHolderName ||
                                yupErrors?.upiHolderName ||
                                (errorList && errorList.upiHolderName)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="upiHolderName"
                                  id="upiHolderName-upi"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="upiId-upi">UPI ID*</InputLabel>
                            <OutlinedInput
                              id="upiId-upi"
                              type="text"
                              value={values.upiId}
                              name="upiId"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              placeholder="Enter your upi id"
                              fullWidth
                              error={Boolean(
                                touched.upiId &&
                                  (errors.upiId ||
                                    (errorList && errorList.upiId))
                              )}
                            />

                            {touched.upiId &&
                              (errors.upiId ||
                                yupErrors?.upiId ||
                                (errorList && errorList.upiId)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="upiId"
                                  id="upiId-upi"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="mobileNumber-upi">
                              Mobile Number*
                            </InputLabel>
                            <OutlinedInput
                              fullWidth
                              error={Boolean(
                                touched.mobileNumber &&
                                  (errors.mobileNumber ||
                                    (errorList && errorList.mobileNumber))
                              )}
                              id="mobileNumber-upi"
                              type="tel"
                              value={values.mobileNumber}
                              name="mobileNumber"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChange(e);
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Enter your mobileNumber number"
                              inputProps={{ maxLength: 10 }}
                            />

                            {touched.mobileNumber &&
                              (errors.mobileNumber ||
                                yupErrors?.mobileNumber ||
                                (errorList && errorList.mobileNumber)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="mobileNumber"
                                  id="mobileNumber-upi"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel id="selectUpiOptionLabel">
                              Select UPI Option*
                            </InputLabel>
                            <Select
                              labelId="selectUpiOptionLabel"
                              id="selectedUpiOption"
                              name="selectedUpiOption"
                              value={values.selectedUpiOption}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fullWidth
                            >
                              {upiOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>

                            {touched.selectedUpiOption &&
                              (errors.selectedUpiOption ||
                                yupErrors?.selectedUpiOption ||
                                (errorList && errorList.selectedUpiOption)) && (
                                <Errors
                                  errorList={errorList}
                                  errors={errors}
                                  yupErrors={yupErrors}
                                  currentKey="selectedUpiOption"
                                  id="selectedUpiOption-upi"
                                />
                              )}
                          </Stack>
                        </Grid>

                        <Grid container justifyContent="flex-end" spacing={2}>
                          <Grid item xs={2} className="mt-4">
                            <AnimateButton>
                              <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="error"
                              >
                                Cancle
                              </Button>
                            </AnimateButton>
                          </Grid>
                          <Grid item xs={2} className="mt-4">
                            <AnimateButton>
                              <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                              >
                                Submit
                              </Button>
                            </AnimateButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      )}
    </React.Fragment>
  );
};

CreateUPI.propTypes = {
  createUpi: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingUpi: state.upi.loadingUpi,
});

export default connect(mapStateToProps, {
  createUpi,
  cancelSave,
  setErrors,
  removeUpiErrors,
  resetComponentStore,
})(CreateUPI);
