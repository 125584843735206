import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { Grid, Typography } from "@mui/material";
import MainCard from "View/Admin/Components/MainCard";

import newImg from "../../../../assets/images/newImg_1280.png";
import hand from "../../../../assets/images/telegram-5662082_1280.png";

import { getAdminEarningLinksList } from "actions/adminAccountSettingActions";
import { Link } from "../../../../../node_modules/react-router-dom/dist/index";
import { createTelegram } from "actions/telegramActions";

const TelegramEarning = ({
  getAdminEarningLinksList,
  adminEarningLinksList: { data },
  createTelegram,
}) => {
  const [telegramLinksData, setTelegramLinksData] = React.useState(null);

  React.useEffect(() => {
    getAdminEarningLinksList();
  }, [getAdminEarningLinksList]);

  React.useEffect(() => {
    const telegramLinks = data.filter((item) => item.linkType === "telegram");
    setTelegramLinksData(telegramLinks);
  }, [data]);

  const handleCreateTelegram = (link) => {
    createTelegram(link);
  };

  return (
    <Grid container spacing={2}>
      {telegramLinksData &&
        telegramLinksData.map((item, i) => (
          <Grid item key={i} xs={12} lg={4} md={4} sm={4}>
            <Link
              to={`https://t.me/${item.link}`}
              target="_blank"
              className="text-decoration-none"
            >
              <MainCard
                style={{
                  backgroundImage:
                    "linear-gradient(to left, #ADD8E6, #87CEEB, #00BFFF)",
                  position: "relative",
                  color: "#ffffff",
                }}
                onClick={() => handleCreateTelegram(item._id)}
              >
                <Typography
                  variant="h4"
                  color="white"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.title}
                  {/* <img src={newImg} alt="" className="newimg" height={40} /> */}
                </Typography>
                <Typography
                  variant="h5"
                  color="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  Join and Earn
                  <img src={hand} alt="" height={50} />
                </Typography>
              </MainCard>
            </Link>
          </Grid>
        ))}
    </Grid>
  );
};

TelegramEarning.propTypes = {
  errorList: PropTypes.object.isRequired,
  getAdminEarningLinksList: PropTypes.func.isRequired,
  createTelegram: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  adminEarningLinksList: state.accountSetting.adminEarningLinksList,
  loadingAdminEarningLinksList:
    state.accountSetting.loadingAdminEarningLinksList,
});

export default connect(mapStateToProps, {
  getAdminEarningLinksList,
  createTelegram,
})(TelegramEarning);
