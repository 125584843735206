import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import AuthLoginCopy from "./auth-forms/AuthLoginCopy";
import AuthWrapper from "./AuthWrapper";
import logo from "../../assets/images/logo_sof.png";
import MainCard from "View/Admin/Components/MainCard";

// ================================|| LOGIN ||================================ //

const LoginCopy = () => {
  const endDate = new Date("June 5, 2024 11:59:59").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = endDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <AuthWrapper>
        <Grid container justifyContent="center">
          <img src={logo} height={150} alt="" />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              sx={{ mb: { xs: -0.5, sm: 0.5 } }}
            >
              <Typography variant="h3">Login</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AuthLoginCopy />
          </Grid>
        </Grid>
      </AuthWrapper>
      <Grid container className="justify-content-center mb-5">
        <Grid item xs={11} sm={8}>
          <MainCard className="shadow-lg">
            <MainCard className="shadow">
              <Typography
                variant="h4"
                color="initial"
                style={{ textAlign: "justify" }}
              >
                यह अंतिम समय दिया गया है, आपके पास बहुत कम समय बचा है, यदि आप
                दिए गए समय से पहले अपना केवाईसी पूरा नहीं करते हैं, तो आपकी आईडी
                हमेशा के लिए हटा दी जाएगी।
                <br /> This is the last time given, you have very little time
                left, if you do not complete your KYC before the given time,
                your ID will be deleted forever.
              </Typography>
            </MainCard>
            <Grid container spacing={4} className="mt-2">
              <Grid item xs={4} className="text-center fs-5 fw-bold ">
                <MainCard className="bg-secondary text-white fs-1">
                  {timeLeft.days}
                </MainCard>
                Day
              </Grid>
              <Grid item xs={4} className="text-center fs-5 fw-bold ">
                <MainCard className="bg-secondary text-white fs-1">
                  {timeLeft.hours}
                </MainCard>
                Hours
              </Grid>
              <Grid item xs={4} className="text-center fs-5 fw-bold ">
                <MainCard className="bg-secondary text-white fs-1">
                  {timeLeft.minutes}
                </MainCard>
                Minutes
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginCopy;
