import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
// import Spinner from "views/Spinner";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  removeAdminAccountSettingErrors,
  resetComponentStore,
  updateEarningsLink,
  getAdminEarningLinkById,
} from "actions/adminAccountSettingActions";

import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";
import { MenuItem, InputLabel, Select } from "@mui/material";

const EditEarningLinks = ({
  resetComponentStore,
  currentAdminEarningLink,
  updateEarningsLink,
  getAdminEarningLinkById,
  loadingAdminAccountSetting,
}) => {
  const navigate = useNavigate();
  const { earningLink_id } = useParams();

  const initialFormData = {
    title: "",
    link: "",
    linkType: "youtube",
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadBankFormData = (currentAdminEarningLink) => {
    const { title, link, linkType } = currentAdminEarningLink;
    const data = {
      title,
      link,
      linkType,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentAdminEarningLink) return;

    loadBankFormData(currentAdminEarningLink);
  }, [currentAdminEarningLink]);

  const { title, link, linkType } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // removeBankErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    updateEarningsLink(submitData, navigate, earningLink_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!earningLink_id) return;

    resetComponentStore();

    getAdminEarningLinkById(earningLink_id);
  }, []);

  return (
    <React.Fragment>
      {loadingAdminAccountSetting ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    Earning Link Details
                  </Typography>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="title">Title*</Form.Label>
                  <Form.Control
                    // className={errorList.title ? "invalid" : ""}
                    type="text"
                    id="title"
                    name="title"
                    maxLength="150"
                    value={title}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="title"
                    id="title-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="link">Link*</Form.Label>
                  <Form.Control
                    // className={errorList.link ? "invalid" : ""}
                    type="text"
                    id="link"
                    name="link"
                    maxLength="150"
                    value={link}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="link"
                    id="link-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <InputLabel htmlFor="linkType">Link Type*</InputLabel>
                  <Select
                    id="linkType"
                    value={linkType}
                    name="linkType"
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  >
                    <MenuItem value="youtube">YouTube</MenuItem>
                    <MenuItem value="telegram">Telegram</MenuItem>
                  </Select>
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mx-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

// EditEarningLinks.propTypes = {
//   loadPage: PropTypes.func.isRequired,
//   errorList: PropTypes.object.isRequired,
//   cancelSave: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingAdminAccountSetting: state.accountSetting.loadingAdminAccountSetting,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
  currentAdminEarningLink: state.accountSetting.currentAdminEarningLink,
});

export default connect(mapStateToProps, {
  removeAdminAccountSettingErrors,
  resetComponentStore,
  updateEarningsLink,
  getAdminEarningLinkById,
})(EditEarningLinks);
