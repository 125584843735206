import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import Header from "../Components/Header/Header";
import Drawer from "../Components/Drawer/index";
// import navigation from "../Components/Drawer/DrawerItems/index";
// import Breadcrumbs from "../../Components/Breadcrumbs";

// types
import { openDrawer } from "reducers/menu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ auth: { actionPending, user }, alerts }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const createAlertNotification = (type, message) => {
    switch (type) {
      case "info":
        return toast.info(message);
      case "success":
        return toast.success(message);
      case "warning":
        return toast.warning(message);
      case "error":
        return toast.error(message);
    }
  };

  useEffect(() => {
    alerts !== null &&
      alerts.length > 0 &&
      alerts.map((alert) => {
        createAlertNotification(`${alert.alertType}`, alert.msg);
      });
  }, [alerts, actionPending]);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <ToastContainer />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
      >
        <Toolbar />
        {/* <Breadcrumbs navigation={navigation} title /> */}
        <Outlet />
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alerts: state.alert,
});
export default connect(mapStateToProps, {
  // loadPendingAction,
  // logout,
})(MainLayout);
