import React from "react";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { connect } from "react-redux";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import AnimateButton from "../../Admin/Components/AnimateButton";
// import { forgotPassword } from "actions/auth";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";

const AuthForgotPassword = (
  {
    // forgotPassword
  }
) => {
  const initialState = {
    phone: "",
    resetPasswordOTP: "",
  };

  const navigate = useNavigate();

  const [formData, setFormData] = React.useState(initialState);

  const onChange = (e) => {
    if (!e) return;

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      // Your form submission logic here
      setStatus({ success: true });
      setSubmitting(false);
      // forgotPassword(values, navigate).then(() => {});
      // signup(values);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialState}
        validationSchema={Yup.object().shape({
          phone: Yup.string()
            .matches(/^\d+$/, "Phone number must contain only digits")
            .min(10, "Phone number must be at least 10 characters")
            .max(10, "Phone number must not exceed 10 characters")
            .required("Phone number is required"),
          resetPasswordOTP: Yup.string()
            .matches(/^\d{6}$/, "Invalid OTP")
            .required("OTP is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="phone-login">Mobile Number</InputLabel>
                  <OutlinedInput
                    id="phone-login"
                    type="phone"
                    value={values.phone}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      onChange(e);
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter Mobile number"
                    fullWidth
                    error={Boolean(touched.phone && errors.phone)}
                    autoComplete="phone"
                    inputProps={{ maxLength: 10 }}
                  />
                  {touched.phone && errors.phone && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-phone-login"
                    >
                      {errors.phone}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="reset-password-otp">
                    Enter OTP
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(
                      touched.resetPasswordOTP && errors.resetPasswordOTP
                    )}
                    id="reset-password-otp"
                    type="text"
                    value={values.resetPasswordOTP}
                    name="resetPasswordOTP"
                    onChange={(e) => {
                      handleChange(e);
                      onChange(e);
                    }}
                    onBlur={handleBlur}
                    placeholder="Enter OTP"
                  />
                  {touched.resetPasswordOTP && errors.resetPasswordOTP && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.resetPasswordOTP}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send OTP
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  // forgotPassword,
})(AuthForgotPassword);
