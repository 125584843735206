import PropTypes from "prop-types";

// material-ui
import { Box, Grid } from "@mui/material";

// project import
import AuthCard from "./AuthCard";
import ShowAlert from "../../Notifications/ShowAlert";
// import Logo from 'components/Logo';
// import AuthFooter from 'components/cards/AuthFooter';

// assets
// import AuthBackground from 'assets/images/auth/AuthBackground';

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }) => (
  <Box sx={{ minHeight: "" }}>
    <ShowAlert />

    {/* <AuthBackground /> */}
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        minHeight: "",
      }}
    >
      {/* <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
        <Logo />
      </Grid> */}
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="flex-end"
          className="my-4"
        >
          <Grid item>
            <AuthCard>{children}</AuthCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

AuthWrapper.propTypes = {
  children: PropTypes.node,
};

export default AuthWrapper;
