import { MdPendingActions } from "react-icons/md";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const AdminRequestPath = {
  id: "group-admin-request",
  title: "",
  type: "group",
  children: [
    {
      id: "group-child-admin-request",
      title: "Users Request",
      type: "item",
      icon: MdPendingActions,
      subChlidrens: [
        {
          id: "approvalRequest",
          title: "Approval Request",
          type: "item",
          url: "/admin/approval-request",
          icon: MdPendingActions,
        },
        {
          id: "withdrawalRequest",
          title: "Withdrawal Request",
          type: "item",
          url: "/admin/withdrawal-request",
          icon: MdPendingActions,
        },
      ],
      // target: true,
    },
  ],
};

export default AdminRequestPath;
