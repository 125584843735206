import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import { useTheme } from "@mui/material/styles";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { FaRegShareSquare } from "react-icons/fa";
import { connect } from "react-redux";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import ChangePasswordModal from "View/Auth/ChangePasswordModal";

const ProfileTab = ({ handleLogout, user }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const directJoinReferCode =
    user && user?.myReferCode ? user?.myReferCode : "";

  const naviPath = `register/${directJoinReferCode}`;

  const profileUrl = `${window.location.origin}/${naviPath}`;

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    if (index === 0) {
      navigate(`/admin/users/${user._id}`);
    }
    if (index === 4) {
      navigate(`/admin/users/change-password`);
      // setOpenModal(true);
    }
  };

  return (
    <>
      <List
        component="nav"
        sx={{
          p: 0,
          "& .MuiListItemIcon-root": {
            minWidth: 32,
            color: theme.palette.grey[500],
          },
        }}
      >
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary="Edit Profile" />
        </ListItemButton>

        <ListItemButton
          aria-label="Chat on WhatsApp"
          href={`https://wa.me/?text=${profileUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <FaRegShareSquare />
          </ListItemIcon>
          <ListItemText primary="Refer & Earn" />
        </ListItemButton>

        <ListItemButton
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <TbPasswordMobilePhone />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </ListItemButton>

        <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ProfileTab);
