import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import { validateForm } from "utils/validation";

import {
  updateBank,
  cancelSave,
  setErrors,
  removeBankErrors,
  resetComponentStore,
  getBankByUserId,
} from "actions/bankActions";

import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const UserEditBank = ({
  updateBank,
  setErrors,
  removeBankErrors,
  loadingBank,
  currentBank,
  errorList,
  getBankByUserId,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const initialFormData = {
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branch: "",
    accountType: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const [bank_ID, setBank_ID] = React.useState(null);

  const loadBankFormData = (currentBank) => {
    const {
      accountHolderName,
      bankName,
      accountNumber,
      ifscCode,
      branch,
      accountType,
    } = currentBank;

    const data = {
      accountHolderName,
      bankName,
      accountNumber,
      ifscCode,
      branch,
      accountType,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentBank) return;

    const bankID = currentBank && currentBank?._id ? currentBank?._id : null;

    setBank_ID(bankID);

    loadBankFormData(currentBank);
  }, [currentBank]);

  const {
    accountHolderName,
    bankName,
    accountNumber,
    ifscCode,
    branch,
    accountType,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeBankErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    updateBank(submitData, navigate, bank_ID).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!user_id) return;

    resetComponentStore();

    getBankByUserId(user_id);
  }, []);

  return (
    <React.Fragment>
      {loadingBank ? (
        <Spinner />
      ) : (
        <Col xs="12">
          {currentBank ? (
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    Bank Information
                  </Typography>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="accountHolderName">
                    Account Holder Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    // className={errorList.accountHolderName ? "invalid" : ""}
                    type="text"
                    id="accountHolderName"
                    name="accountHolderName"
                    maxLength="150"
                    value={accountHolderName}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="accountHolderName"
                    id="accountHolderName-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="bankName">Bank Name*</Form.Label>
                  <Form.Control
                    // className={errorList.bankName ? "invalid" : ""}
                    type="text"
                    id="bankName"
                    name="bankName"
                    maxLength="150"
                    value={bankName}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="bankName"
                    id="bankName-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="accountNumber">
                    Account Number*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.accountNumber ? "invalid" : ""}
                    type="text"
                    id="accountNumber"
                    name="accountNumber"
                    maxLength="150"
                    value={accountNumber}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="accountNumber"
                    id="accountNumber-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="ifscCode">IFSC Code*</Form.Label>
                  <Form.Control
                    // className={errorList.ifscCode ? "invalid" : ""}
                    type="text"
                    id="ifscCode"
                    name="ifscCode"
                    maxLength="150"
                    value={ifscCode}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="ifscCode"
                    id="ifscCode-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="branch">Branch*</Form.Label>
                  <Form.Control
                    // className={errorList.branch ? "invalid" : ""}
                    type="text"
                    id="branch"
                    name="branch"
                    maxLength="150"
                    value={branch}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="branch"
                    id="branch-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="accountType">Account Type*</Form.Label>
                  <Form.Control
                    as="select"
                    id="accountType"
                    name="accountType"
                    value={accountType}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  >
                    <option value="saving">Saving</option>
                    <option value="current">Current</option>
                  </Form.Control>
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mx-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          ) : (
            <Card className="p-4 text-danger">
              The user has not added their Bank details yet.
            </Card>
          )}
        </Col>
      )}
    </React.Fragment>
  );
};

UserEditBank.propTypes = {
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingBank: state.bank.loadingBank,
  currentBank: state.bank.currentBank,
});

export default connect(mapStateToProps, {
  updateBank,
  cancelSave,
  setErrors,
  removeBankErrors,
  resetComponentStore,
  getBankByUserId,
  resetComponentStore,
})(UserEditBank);
