import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaRegEye } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

import UpiDetailsModal from "./UpiDetailsModal";

// material-ui
import { Grid } from "@mui/material";

import {
  getUpisList,
  resetComponentStore,
  deleteUpi,
} from "actions/upiActions";

import AnimateButton from "View/Admin/Components/AnimateButton";

import { isAdmin } from "utils/helper";
import { Button } from "react-bootstrap";

const UpisList = ({
  upisList: { data, count },
  getUpisList,
  loadingUpisList,
  resetComponentStore,
  sortingParams,
  deleteUpi,
  user,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [showModal, setShowModal] = React.useState(false);
  const [selectedUpi, setSelectedUpi] = React.useState(null);

  const openModal = (upi) => {
    setSelectedUpi(upi);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUpi(null);
  };

  const actions = (
    <Grid container spacing={2} className="mb-3">
      <Grid item xs={2}>
        <Link to="/admin/credentials/upis/create" title="create">
          <AnimateButton>
            <Button fullWidth size="small" type="button" variant="primary">
              Create UPI
            </Button>
          </AnimateButton>
        </Link>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row.upiHolderName,
      sortable: false,
      width: "20%",
      wrap: true,
    },
    {
      name: "UPI ID",
      selector: (row) => row.upiId,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "UPI Option",
      selector: (row) => row.selectedUpiOption,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "20%",
      button: true,
      cell: (row) => (
        <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item xs={6} sm={3}>
            {isAdmin(user) ? (
              <Link
                to={`/admin/credentials/upis/${row._id}/edit`}
                title="View Upi"
              >
                <Button
                  type="submit"
                  variant="primary"
                  color="primary"
                  size="sm"
                >
                  <FaRegEye />
                </Button>
              </Link>
            ) : (
              <Button
                size="sm"
                type="submit"
                variant="link"
                onClick={() => openModal(row)}
              >
                <FaRegEye />
              </Button>
            )}
          </Grid>

          {isAdmin(user) ? (
            <Grid item xs={6} sm={2}>
              <Button
                size="sm"
                type="submit"
                variant="danger"
                onClick={(e) => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete: ${row.upiId}?`
                    )
                  ) {
                    deleteUpi(row._id);
                  }
                }}
              >
                <BiTrash />
              </Button>
            </Grid>
          ) : null}
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getUpisList(clientParams);
  }, [getUpisList, clientParams, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="UPIs" />

      {showModal && selectedUpi && (
        <UpiDetailsModal upiDetails={selectedUpi} onHide={closeModal} />
      )}

      <Card>
        <Card.Body>
          {data && data.length ? null : actions}

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingUpisList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

UpisList.propTypes = {
  getUpisList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  upisList: state.upi.upisList,
  loadingUpisList: state.upi.loadingUpisList,
  sortingParams: state.upi.sortingParams,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getUpisList,
  resetComponentStore,
  deleteUpi,
})(UpisList);
