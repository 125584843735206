import React from "react";
import MainCard from "../../MainCard";
import { Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ProfileInfo = ({ user }) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(`/admin/users/${user._id}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sm={6} lg={5}>
        <MainCard>
          <Typography variant="h5" color="textSecondary" className="mb-4">
            Profile Info
          </Typography>
          <Grid container className="mb-2">
            <Typography color="textSecondary" style={{ width: "180px" }}>
              Name :
            </Typography>
            <Typography color="primary.main">{`${user.firstname} ${user.lastname}`}</Typography>
          </Grid>
          <Grid container className="mb-2">
            <Typography color="textSecondary" style={{ width: "180px" }}>
              Email :
            </Typography>
            <Typography color="primary.main">{user.email ?? "-"}</Typography>
          </Grid>
          <Grid container className="mb-2">
            <Typography color="textSecondary" style={{ width: "180px" }}>
              Phone Number :
            </Typography>
            <Typography color="primary.main">{user.phone ?? "-"}</Typography>
          </Grid>
          <Grid container className="mb-2">
            <Typography color="textSecondary" style={{ width: "180px" }}>
              My Refer Code :
            </Typography>
            <Typography color="primary.main">
              {user.myReferCode ?? "-"}
            </Typography>
          </Grid>
          <Grid container className="mb-2">
            <Typography color="textSecondary" style={{ width: "180px" }}>
              Refer By :
            </Typography>
            <Typography color="primary.main">
              {user.referralCode ? user.referralCode : "-"}
            </Typography>
          </Grid>

          <Button variant="primary" onClick={handleSubmit}>
            Edit Profile
          </Button>
        </MainCard>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ProfileInfo);
