import React from "react";
import { Grid, Button } from "@mui/material";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
// import MainCard from "../../../MainCard";
import ApprovalForm from "./ApprovalForm";
import { getAdminAccountsList } from "actions/approvalActions";

const UserApproval = ({
  getAdminAccountsList,
  adminAccountsList: { data },
  adminAccountSettingsList,
}) => {
  const { bank = [], upi = [], qrScanner = [] } = data;
  const [telegramUrl, setTelegramUrl] = React.useState(null);

  React.useEffect(() => {
    if (adminAccountSettingsList.data.length) {
      const telegramURL = adminAccountSettingsList.data[0].adminTelegramLink;

      setTelegramUrl(telegramURL);
    }
  }, [adminAccountSettingsList]);

  React.useEffect(() => {
    getAdminAccountsList();
  }, [getAdminAccountsList]);

  const handleTelegramLink = () => {
    const telegramLink = `https://t.me/${telegramUrl}`;
    window.open(telegramLink, "_blank");
  };
  return (
    <Grid container spacing={2}>
      {/* {data && data.bank && data.bank.length ? (
        <Grid item xs={12} md={8} sm={6} lg={5}>
          <MainCard>
            <Typography variant="h5" color="textSecondary" className="mb-4">
              Admin Account Detail
            </Typography>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Bank Name :
              </Typography>
              <Typography color="primary.main">{bank[0].bankName}</Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Account Holder Name :
              </Typography>
              <Typography color="primary.main">
                {bank[0].accountHolderName}
              </Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Account No. :
              </Typography>
              <Typography color="primary.main">
                {bank[0].accountNumber}
              </Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                IFSC Code :
              </Typography>
              <Typography color="primary.main"> {bank[0].ifscCode} </Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Branch :
              </Typography>
              <Typography color="primary.main">{bank[0].branch}</Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Account Type :
              </Typography>
              <Typography color="primary.main">
                {bank[0].accountType}
              </Typography>
            </Grid>
          </MainCard>
        </Grid>
      ) : null} */}
      {/* {data && data.upi && data.upi.length ? (
        <Grid item xs={12} md={8} sm={6} lg={4}>
          <MainCard>
            <Typography variant="h5" color="textSecondary" className="mb-4">
              Admin UPI Detail
            </Typography>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Name :
              </Typography>
              <Typography color="primary.main">
                {upi[0].upiHolderName}
              </Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                UPI ID :
              </Typography>
              <Typography color="primary.main">{upi[0].upiId}</Typography>
            </Grid>
            <Grid container className="mb-2">
              <Typography color="textSecondary" style={{ width: "180px" }}>
                Mobile Number :
              </Typography>
              <Typography color="primary.main">
                {upi[0].mobileNumber}
              </Typography>
            </Grid>
            <Grid container sx={{ mb: "68px" }}>
              <Typography color="textSecondary" style={{ width: "180px" }}>
                UPI Option :
              </Typography>
              <Typography color="primary.main">
                {upi[0].selectedUpiOption}
              </Typography>
            </Grid>
          </MainCard>
        </Grid>
      ) : null} */}
      {/* {data && data.qrScanner && data.qrScanner.length ? (
        <Grid item xs={12} md={8} sm={6} lg={3}>
          <MainCard>
            <Typography variant="h5" color="textSecondary" className="mb-4">
              Admin QR Code
            </Typography>
            <Grid className="d-flex justify-content-center">
              <img
                src={qrScanner[0].imageUrl}
                alt="Preview"
                style={{
                  width: "170px",
                  height: "170px",
                  marginBottom: "10px",
                }}
              />
            </Grid>
          </MainCard>
        </Grid>
      ) : null} */}

      <Grid item xs={12} md={8} sm={6} lg={3}>
        <Button
          disableElevation
          fullWidth
          size="small"
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleTelegramLink}
        >
          Official Telegram Channel
        </Button>
      </Grid>

      <Grid item xs={12} md={8} sm={6} lg={4}>
        <ApprovalForm upi={upi[0]} bank={bank[0]} />
      </Grid>
    </Grid>
  );
};

UserApproval.propTypes = {
  getAdminAccountsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  adminAccountsList: state.approval.adminAccountsList,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
});

export default connect(mapStateToProps, { getAdminAccountsList })(UserApproval);
