import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

// material-ui
import { Button, Grid } from "@mui/material";

import {
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  // updateAdminAccountSetting,
} from "actions/adminAccountSettingActions";
import AnimateButton from "View/Admin/Components/AnimateButton";

// import { isAdmin } from "utils/helper";

const SetDefaultValuesList = ({
  resetComponentStore,
  getAdminAccountSettingsList,
  adminAccountSettingsList: { data },
  loadingAdminAccountSettingsList,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  // const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [showModal, setShowModal] = React.useState(false);
  const [selectedBank, setSelectedBank] = React.useState(null);

  // const openModal = (bank) => {
  //   setSelectedBank(bank);
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  //   setSelectedBank(null);
  // };

  React.useEffect(() => {
    getAdminAccountSettingsList();
  }, [getAdminAccountSettingsList]);

  const actions = (
    <Grid container spacing={2}>
      <Grid item xs={2} className="mb-3">
        <Link to="/admin/set-default-values" title="create">
          <AnimateButton>
            <Button
              fullWidth
              size="small"
              type="button"
              variant="contained"
              color="primary"
            >
              Add Values
            </Button>
          </AnimateButton>
        </Link>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.projectName,
      sortable: false,
      width: "30%",
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNumber,
      sortable: false,
      width: "25%",
      wrap: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "25%",
      button: true,
      cell: (row) => (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Link
              to={`/admin/admin-account-edit/${row._id}`}
              title="View Default Values"
            >
              <AnimateButton>
                <Button
                  size="small"
                  type="submit"
                  variant="text"
                  color="primary"
                >
                  <FaRegEye />
                </Button>
              </AnimateButton>
            </Link>
          </Grid>
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getAdminAccountSettingsList(clientParams);
  }, [getAdminAccountSettingsList, clientParams, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Default Values"
        crumbs={[{ name: "Default Values" }]}
      />

      <Card>
        <Card.Body>
          {data && data.length ? null : actions}

          <PiDataTable
            columns={columns}
            data={data}
            // count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingAdminAccountSettingsList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

// SetDefaultValuesList.propTypes = {
//   createAdminAccountSetting: PropTypes.func.isRequired,
//   errorList: PropTypes.object.isRequired,
//   getAdminAccountSettingsList: PropTypes.func.isRequired,
//   updateAdminAccountSetting: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingAdminAccountSetting: state.accountSetting.loadingAdminAccountSetting,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
  loadingAdminAccountSettingsList:
    state.accountSetting.loadingAdminAccountSettingsList,
});

export default connect(mapStateToProps, {
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  // updateAdminAccountSetting,
})(SetDefaultValuesList);
