import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  banksList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentBank: null,
  loadingBanksList: true,
  loadingBank: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const bankSlice = createSlice({
  name: "bank",
  initialState: initialState,
  reducers: {
    loadingBanksList(state) {
      return {
        ...state,
        loadingBanksList: true,
      };
    },
    bankSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingBanksList: false,
      };
    },
    bankListUpdated(state, action) {
      return {
        ...state,
        banksList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingBanksList: false,
      };
    },
    bankDetailsById(state, action) {
      return {
        ...state,
        currentBank: action.payload,
        loadingBank: false,
      };
    },
    resetBank(state) {
      return {
        ...initialState,
      };
    },

    bankError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingBank: false,
        loadingBanksList: false,
      };
    },
    loadingOnBankSubmit(state) {
      return {
        ...state,
        loadingBank: true,
      };
    },
    bankCreated(state) {
      return {
        ...state,
        loadingBank: false,
      };
    },
    bankUpdated(state, action) {
      return {
        ...state,
        banksList: {
          ...state.banksList,
          data: state.banksList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    bankDeleted(state, action) {
      const currentCount = state.banksList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.banksList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        banksList: {
          data: state.banksList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingBanksList: false,
      };
    },
  },
});
export const {
  loadingBanksList,
  bankSearchParametersUpdate,
  bankListUpdated,
  resetBank,
  bankError,
  loadingOnBankSubmit,
  bankCreated,
  bankUpdated,
  bankDetailsById,
  bankDeleted,
} = bankSlice.actions;
export default bankSlice.reducer;
