import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import AnimateButton from "View/Admin/Components/AnimateButton";

// assets

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthCodeVerification = ({ showNotificationProp }) => {
  const initialState = {
    code: "",
  };

  const navigate = useNavigate();

  const [formData, setFormData] = React.useState(initialState);
  const [showNotification, setShowNotification] = React.useState(false);

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      setStatus({ success: false });
      setSubmitting(false);

      if (values.code === "156599") {
        setShowNotification(true);
      } else {
        setShowNotification(false);
        setErrors({ code: "Verification code is invalid" });
      }
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={Yup.object().shape({
          code: Yup.string()
            .min(6, "Code must be at least 6 characters")
            .required("Code is required, "),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const yupErrors = {};
          if (errors) {
            Object.keys(errors).forEach((key) => {
              if (touched[key]) {
                yupErrors[key] = errors[key];
              }
            });
          }

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="code">Verification Code*</InputLabel>
                    <OutlinedInput
                      id="code"
                      type="text"
                      value={values.code}
                      name="code"
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 6 }}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      disabled={!showNotificationProp}
                      placeholder="Enter verification code"
                      fullWidth
                      error={Boolean(touched.code && errors.code)}
                    />
                    {touched.code && errors.code && (
                      <FormHelperText error id="code">
                        {errors.code}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>

                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!showNotificationProp}
                    >
                      Submit
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
      {showNotification && (
        <FormHelperText
          id="error"
          style={{
            backgroundColor: "#14cd7747",
            color: "#1d6745c7",
            padding: "10px",
            fontSize: "15px",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          Note: Your code has been verified. please approve 3 direct downline
          members to use our services.
        </FormHelperText>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AuthCodeVerification);
