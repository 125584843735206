import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

// material-ui
import { Button, Grid } from "@mui/material";

import {
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminEarningLinksList,
} from "actions/adminAccountSettingActions";

import AnimateButton from "View/Admin/Components/AnimateButton";

const EarningLinksList = ({
  resetComponentStore,
  getAdminEarningLinksList,
  adminEarningLinksList: { data },
  loadingAdminEarningLinksList,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  React.useEffect(() => {
    getAdminEarningLinksList();
  }, [getAdminEarningLinksList]);

  const actions = (
    <Grid container spacing={2}>
      <Grid item xs={2} className="mb-3">
        <Link to="/admin/earning-links/create" title="create">
          <AnimateButton>
            <Button
              fullWidth
              size="small"
              type="button"
              variant="contained"
              color="primary"
            >
              Add Earning Link
            </Button>
          </AnimateButton>
        </Link>
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: false,
      width: "30%",
    },
    {
      name: "Link",
      selector: (row) => row.link,
      sortable: false,
      width: "25%",
      wrap: true,
    },

    {
      name: "Link Type",
      selector: (row) => row.linkType,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Actions",
      width: "25%",
      button: true,
      cell: (row) => (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={2}>
            <Link
              to={`/admin/earning-links/${row._id}/edit`}
              title="View Earning Link"
            >
              <AnimateButton>
                <Button
                  size="small"
                  type="submit"
                  variant="text"
                  color="primary"
                >
                  <FaRegEye />
                </Button>
              </AnimateButton>
            </Link>
          </Grid>
        </Grid>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getAdminEarningLinksList(clientParams);
  }, [getAdminEarningLinksList, clientParams, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Earning Links" />

      <Card>
        <Card.Body>
          {actions}

          <PiDataTable
            columns={columns}
            data={data}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingAdminEarningLinksList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

EarningLinksList.propTypes = {
  errorList: PropTypes.object.isRequired,
  getAdminEarningLinksList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  adminEarningLinksList: state.accountSetting.adminEarningLinksList,
  loadingAdminEarningLinksList:
    state.accountSetting.loadingAdminEarningLinksList,
});

export default connect(mapStateToProps, {
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminEarningLinksList,
})(EarningLinksList);
