import { CgProfile } from "react-icons/cg";
import { BsBank2 } from "react-icons/bs";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const MyProfilePath = {
  id: "group-myProfile",
  title: "",
  type: "group",
  children: [
    {
      id: "myProfile",
      title: "My Profile",
      type: "item",
      icon: CgProfile,
      subChlidrens: [
        {
          id: "myProfile-sub",
          title: "My Profile",
          type: "item",
          url: "/admin/profile-info",
          icon: CgProfile,
        },
        {
          id: "bankCredentials",
          title: "Credentials",
          type: "item",
          icon: BsBank2,
          url: "/admin/credentials",
        },
      ],
    },
  ],
};

export default MyProfilePath;
