import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  spinAndWinAmount: 0,
  loadingSpinAndWin: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  spinAndWinList: {
    spinAndWinData: null,
  },
};

const spinAndWinSlice = createSlice({
  name: "spinAndWin",
  initialState: initialState,
  reducers: {
    spinAndWinAmount(state, action) {
      return {
        ...state,
        spinAndWinAmount: action.payload,
      };
    },

    spinAndWinCreated(state) {
      return {
        ...state,
        loadingSpinAndWin: false,
      };
    },

    resetSpinAndWin(state) {
      return {
        ...initialState,
      };
    },
    spinAndWinListData(state, action) {
      return {
        ...state,
        spinAndWinList: {
          spinAndWinData: action.payload,
        },
      };
    },
    spinAndWinError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingSpinAndWin: false,
      };
    },
  },
});
export const {
  spinAndWinAmount,
  spinAndWinCreated,
  spinAndWinError,
  resetSpinAndWin,
  spinAndWinListData,
} = spinAndWinSlice.actions;
export default spinAndWinSlice.reducer;
