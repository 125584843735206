import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Stack,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import MainCard from "View/Admin/Components/MainCard";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  createOrder,
  resetComponentStore,
  getOrdersListByUserId,
} from "actions/userOrderActions";

import Spinner from "View/Spinners/Spinner";

const UserCheckoutForm = ({
  upi,
  bank,
  itemData,
  user,
  createOrder,
  ordersList: { data },
  getOrdersListByUserId,
  resetComponentStore,
  loadingOrdersList,
}) => {
  const navigate = useNavigate();

  const initialState = {
    methodID: "",
    orderAmount: "",
    utrNumber: "",
  };

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);
  const [disabledSubmit, setDisabledSubmit] = React.useState(false);
  const [filterOrderRequest, setFilterOrderRequest] = React.useState(null);

  const [formData, setFormData] = React.useState(initialState);

  const [selectedLabel, setSelectedLabel] = React.useState("");

  const [amount, setAmmount] = React.useState(0);

  React.useEffect(() => {
    if (!itemData) return;

    setAmmount(itemData?.price || 0);
  }, [itemData]);

  React.useEffect(() => {
    const filteredData = data.filter(
      (item) =>
        (item.status === "pending" || item.status === "approved") &&
        item.type === "order_request"
    );

    if (user && filteredData) {
      const userHaveOrderReq =
        filteredData &&
        filteredData.filter((order) => user._id === order.userId);

      if (userHaveOrderReq && userHaveOrderReq.length) {
        setDisabledSubmit(true);
        setFilterOrderRequest(userHaveOrderReq);
      }
    }
  }, [data]);

  const onChange = (e) => {
    if (!e) return;

    const { name, value } = e.target;

    switch (name) {
      case "methodID":
        handleMethodChange(value);
        break;

      default:
        setFormData({ ...formData, [name]: value });
    }
  };

  const handleMethodChange = (value) => {
    if (upi && upi._id && value === upi._id) {
      setSelectedLabel("UPI");
    } else if (bank && bank._id && value === bank._id) {
      setSelectedLabel("Bank");
    } else {
      setSelectedLabel("");
    }
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      values.selectedLabel = selectedLabel;
      values.orderAmount = amount;
      values.productId = itemData && itemData?._id ? itemData?._id : null;

      setStatus({ success: false });
      setSubmitting(false);
      createOrder(values, navigate);
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getOrdersListByUserId(clientParams);
  }, [getOrdersListByUserId, clientParams, resetComponentStore]);

  return (
    <>
      {loadingOrdersList ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            methodID: Yup.string().required("Please select a methodID"),
            utrNumber: Yup.string().required("UTR number is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <MainCard>
                    <Grid container spacing={2}>
                      <Grid item>
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Method
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="methodID"
                            value={values.methodID}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                          >
                            {upi && upi._id ? (
                              <FormControlLabel
                                value={upi._id}
                                control={<Radio />}
                                label="UPI"
                              />
                            ) : (
                              <FormHelperText error id="method-upi-error">
                                Add UPI First
                              </FormHelperText>
                            )}

                            {bank && bank._id ? (
                              <FormControlLabel
                                value={bank._id}
                                control={<Radio />}
                                label="Bank Account"
                              />
                            ) : (
                              <FormHelperText error id="method-bank-error">
                                Add Bank First
                              </FormHelperText>
                            )}
                          </RadioGroup>

                          {touched.methodID && errors.methodID && (
                            <FormHelperText error id="methodID-error">
                              {errors.methodID}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="orderAmount">Amount*</InputLabel>
                          <OutlinedInput
                            id="orderAmount"
                            type="text"
                            readOnly
                            disabled
                            value={amount}
                            name="orderAmount"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            fullWidth
                            error={Boolean(
                              touched.orderAmount && errors.orderAmount
                            )}
                          />
                          {touched.orderAmount && errors.orderAmount && (
                            <FormHelperText error id="orderAmount">
                              {errors.orderAmount}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="utrNumber">
                            UTR Number*
                          </InputLabel>
                          <OutlinedInput
                            id="utrNumber"
                            type="text"
                            value={values.utrNumber}
                            disabled={disabledSubmit}
                            name="utrNumber"
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => {
                              handleChange(e);
                              onChange(e);
                            }}
                            fullWidth
                            error={Boolean(
                              touched.utrNumber && errors.utrNumber
                            )}
                          />
                          {touched.utrNumber && errors.utrNumber && (
                            <FormHelperText error id="utrNumber">
                              {errors.utrNumber}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>

                      <Grid
                        container
                        className="mt-4"
                        justifyContent="flex-end"
                      >
                        <Grid item xs={4}>
                          <Button
                            disableElevation
                            fullWidth
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={disabledSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>

                {filterOrderRequest && filterOrderRequest.length ? (
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <MainCard>
                      <FormHelperText
                        id="success"
                        style={{
                          backgroundColor: "#c8e6c9",
                          color: "#2e7d32",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {`Your order request with UTR Number ${filterOrderRequest[0]?.utrNumber} has been successfully submitted. Please await administrative order.`}
                      </FormHelperText>
                    </MainCard>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

UserCheckoutForm.propTypes = {
  getOrdersListByUserId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ordersList: state.userOrder.ordersList,
  user: state.auth.user,
  loadingOrdersList: state.userOrder.loadingOrdersList,
});

export default connect(mapStateToProps, {
  createOrder,
  getOrdersListByUserId,
  resetComponentStore,
})(UserCheckoutForm);
