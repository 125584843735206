import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  loadingTelegram: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const telegramSlice = createSlice({
  name: "telegram",
  initialState: initialState,
  reducers: {
    telegramCreated(state) {
      return {
        ...state,
        loadingTelegram: false,
      };
    },

    resetTelegram(state) {
      return {
        ...initialState,
      };
    },

    telegramError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingTelegram: false,
      };
    },
  },
});
export const { telegramCreated, telegramError, resetTelegram } =
  telegramSlice.actions;
export default telegramSlice.reducer;
