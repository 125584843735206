// project import
import EarningPath from "./EarningPath";
import DashboardPath from "./DashboardPath";
import DownlinePath from "./DownlinePath";
import BankingPath from "./BankingPath";
import HistoryPath from "./HistoryPath";
import AdminRequestPath from "./AdminRequestPath";
import AdminDashboardPath from "./AdminDashboardPath";
import AdminDownlinePath from "./AdminDownlinePath";
import AdminUsersListPath from "./AdminUsersListPath";
import UpdatePasswordPath from "./UpdatePasswordPath";
import UserQueryPath from "./UserQueryPath";
import MyProfilePath from "./MyProfilePath";
import AdminProductPath from "./AdminProductPath";
import UserProductPath from "./UserProductPath";
import SetTransactionPasswordPath from "./SetTransactionPasswordPath";
import ChangeTransactionPasswordPath from "./ChangeTransactionPasswordPath";
import UserHistoryPath from "./UserHistoryPath";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    DashboardPath,
    AdminDashboardPath,
    AdminUsersListPath,
    AdminDownlinePath,
    EarningPath,
    AdminProductPath,
    UserProductPath,
    DownlinePath,
    HistoryPath,
    UserHistoryPath,
    BankingPath,
    AdminRequestPath,
    MyProfilePath,
    UpdatePasswordPath,
    SetTransactionPasswordPath,
    ChangeTransactionPasswordPath,
    UserQueryPath,
  ],
};

export default menuItems;
