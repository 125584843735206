import { setAlertMsg, removeAlertMsg } from "../reducers/alert";

export const setAlert =
  (msg, alertType, err_key = "", timeout = 5000) =>
  (dispatch) => {
    const id = "";
    dispatch(setAlertMsg({ msg, alertType, err_key, id }));
  };

export const removeAlert = () => (dispatch) => {
  dispatch(removeAlertMsg());
};
