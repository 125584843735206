import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  telegramError,
  resetTelegram,
  telegramCreated,
} from "reducers/telegramReducer";

import { baseURL } from "constants/index";

export const createTelegram = (formData) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const data = {
      telegramID: formData,
    };

    const res = await axios.post(
      `${baseURL}/api/admin/earnings/telegram`,
      data,
      config
    );

    if (res.data.status === true) {
      dispatch(telegramCreated(res.data.response));
      dispatch(setAlert("Telegram Earning Created.", "success"));

      return res.data;
    } else {
      const errors = res.data.errors;

      if (errors) {
        dispatch(telegramError());
        dispatch(setAlert(errors.msg, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);

    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          telegramError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "error"));
    }
  }
};

// reset errors
export const removeTelegramErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetTelegram());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(telegramError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  // navigate("/admin/earnings/telegram");
};
