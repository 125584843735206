import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Button,
  FormControl,
  FormHelperText,
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { strengthColor, strengthIndicator } from "utils/PasswordStrength";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Errors from "Notifications/Errors";
import { connect } from "react-redux";
import { ChangePassword } from "actions/auth";
import { useNavigate } from "react-router-dom";
import AnimateButton from "View/Admin/Components/AnimateButton";
import MainCard from "View/Admin/Components/MainCard";

const ChangePasswordModal = ({ errorList, ChangePassword, user }) => {
  const navigate = useNavigate();

  const initialState = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowNewConfirmPassword = () => {
    setShowNewConfirmPassword(!showNewConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  useEffect(() => {
    changePassword("");
  }, []);

  const onChange = (e) => {
    if (!e) return;

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      setStatus({ success: true });
      setSubmitting(true);

      if (user) {
        ChangePassword(values, user._id, navigate);
        setFormData(initialState);
      }
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container>
      {/* {user && user.password === "SOF" && user.setPassword === false ? (
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <MainCard className="mb-4">
            <FormHelperText
              id="error"
              style={{
                backgroundColor: "#ffebee",
                color: "red",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              {`For security reasons, you must change your password before using any services.`}
            </FormHelperText>
          </MainCard>
        </Grid>
      ) : null} */}

      <MainCard>
        <Typography id="modal-modal-title" variant="h5">
          Change Password
        </Typography>

        <Formik
          initialValues={formData}
          validationSchema={Yup.object().shape({
            oldPassword: Yup.string()
              .min(3)
              .max(50)
              .required("Old Password is required"),
            newPassword: Yup.string()
              .min(3)
              .max(50)
              .required("New Password is required"),
            confirmNewPassword: Yup.string()
              .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
              .required("Confirm New Password is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => {
            const yupErrors = {};
            if (errors) {
              Object.keys(errors).forEach((key) => {
                if (touched[key]) {
                  yupErrors[key] = errors[key];
                }
              });
            }

            return (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className="mt-2">
                    <Stack spacing={1}>
                      <InputLabel htmlFor="old-password-profile">
                        Old Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.oldPassword &&
                            (errors.oldPassword ||
                              (errorList && errorList.oldPassword))
                        )}
                        id="old-password-profile"
                        type={showPassword ? "text" : "password"}
                        value={values.oldPassword}
                        name="oldPassword"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          onChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle oldPassword visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="******"
                        inputProps={{}}
                      />

                      {touched.oldPassword &&
                        (errors.oldPassword ||
                          yupErrors?.oldPassword ||
                          (errorList && errorList.oldPassword)) && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            yupErrors={yupErrors}
                            currentKey="oldPassword"
                            id="old-password-profile"
                          />
                        )}
                    </Stack>
                    <Stack spacing={1} className="mt-3">
                      <InputLabel htmlFor="new-password-profile">
                        New Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.newPassword &&
                            (errors.newPassword ||
                              (errorList && errorList.newPassword))
                        )}
                        id="new-password-profile"
                        type={showNewPassword ? "text" : "password"}
                        value={values.newPassword}
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          onChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle newPassword visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showNewPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="******"
                        inputProps={{}}
                      />

                      {touched.newPassword &&
                        (errors.newPassword ||
                          yupErrors?.newPassword ||
                          (errorList && errorList.newPassword)) && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            yupErrors={yupErrors}
                            currentKey="newPassword"
                            id="new-password-profile"
                          />
                        )}
                    </Stack>

                    <Stack spacing={1} className="mt-3">
                      <InputLabel htmlFor="confirm-new-password-profile">
                        Confirm New Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(
                          touched.confirmNewPassword &&
                            (errors.confirmNewPassword ||
                              (errorList && errorList.confirmNewPassword))
                        )}
                        id="confirm-new-password-profile"
                        type={showNewConfirmPassword ? "text" : "password"}
                        value={values.confirmNewPassword}
                        name="confirmNewPassword"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          onChange(e);
                          changePassword(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirmNewPassword visibility"
                              onClick={handleClickShowNewConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showNewConfirmPassword ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="******"
                        inputProps={{}}
                      />

                      {touched.confirmNewPassword &&
                        (errors.confirmNewPassword ||
                          yupErrors?.confirmNewPassword ||
                          (errorList && errorList.confirmNewPassword)) && (
                          <Errors
                            errorList={errorList}
                            errors={errors}
                            yupErrors={yupErrors}
                            currentKey="confirmNewPassword"
                            id="confirm-new-password-profile"
                          />
                        )}
                    </Stack>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Box
                            sx={{
                              bgcolor: level?.color,
                              width: 85,
                              height: 8,
                              borderRadius: "7px",
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" fontSize="0.75rem">
                            {level?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </MainCard>
    </Grid>
  );
};

ChangePasswordModal.propTypes = {
  ChangePassword: PropTypes.func.isRequired,
  errorList: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  user: state.auth.user,
});

export default connect(mapStateToProps, { ChangePassword })(
  ChangePasswordModal
);
