import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import {
  updateWithdrawal,
  removeWithdrawalErrors,
  resetComponentStore,
  getWithdrawalById,
} from "actions/withdrawalActions";
import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const EditWithdrawalRequest = ({
  updateWithdrawal,
  loadingWithdrawal,
  currentWithdrawal,
  getWithdrawalById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { withdrawal_id } = useParams();

  const [formData, setFormData] = React.useState({});
  const [approveSubmitting, setApproveSubmitting] = React.useState(false);
  const [rejectSubmitting, setRejectSubmitting] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(null);

  React.useEffect(() => {
    if (currentWithdrawal) {
      setFormData(currentWithdrawal);

      const isPending =
        currentWithdrawal &&
        currentWithdrawal.status &&
        currentWithdrawal.status === "pending";

      setIsDisable(isPending);
    }
  }, [currentWithdrawal]);

  const handleApprove = () => {
    const updatedData = { ...formData, status: "approved" };
    setApproveSubmitting(true);
    updateWithdrawal(updatedData, withdrawal_id, navigate).then(() => {
      setApproveSubmitting(false);
    });
  };

  const handleReject = () => {
    const updatedData = { ...formData, status: "rejected" };
    setRejectSubmitting(true);
    updateWithdrawal(updatedData, withdrawal_id, navigate).then(() => {
      setRejectSubmitting(false);
    });
  };

  React.useEffect(() => {
    if (withdrawal_id) {
      resetComponentStore();
      getWithdrawalById(withdrawal_id);
    }
  }, [withdrawal_id]);

  return (
    <React.Fragment>
      {loadingWithdrawal ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <div className="card-heading mb-3">
                <Typography variant="h5" color="textSecondary" className="mb-2">
                  Withdrawal Request Information
                </Typography>
              </div>

              <Form autoComplete="off">
                <Form.Group className="form-group mb-3">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.userId || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Method</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.method || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.amount || ""}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    value={formData.status || ""}
                  />
                </Form.Group>

                {isDisable && (
                  <div className="mb-3">
                    <Button variant="success" onClick={handleApprove}>
                      {approveSubmitting ? "Submitting..." : "Approve"}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleReject}
                      className="ms-2 me-2"
                    >
                      {rejectSubmitting ? "Submitting..." : "Reject"}
                    </Button>
                  </div>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditWithdrawalRequest.propTypes = {
  loadingWithdrawal: PropTypes.bool.isRequired,
  currentWithdrawal: PropTypes.object,
  getWithdrawalById: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
  updateWithdrawal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loadingWithdrawal: state.withdrawal.loadingWithdrawal,
  currentWithdrawal: state.withdrawal.currentWithdrawal,
});

export default connect(mapStateToProps, {
  updateWithdrawal,
  removeWithdrawalErrors,
  resetComponentStore,
  getWithdrawalById,
})(EditWithdrawalRequest);
