// assets
import { ImSpinner4 } from "react-icons/im";
import { BiRupee } from "react-icons/bi";
import { FiYoutube } from "react-icons/fi";
import { LiaTelegramPlane } from "react-icons/lia";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const EarningPath = {
  id: "earning",
  title: "",
  type: "group",
  children: [
    {
      id: "group-spinAndWin",
      title: "Earning Task",
      type: "item",
      icon: BiRupee,
      subChlidrens: [
        {
          id: "spinAndWin",
          title: "Spin And Win",
          type: "item",
          url: "earnings/spin-and-win",
          icon: ImSpinner4,
        },
        {
          id: "youTube",
          title: "view Ad",
          type: "item",
          url: "earnings/youtube",
          icon: FiYoutube,
        },
        {
          id: "telegram",
          title: "Telegram",
          type: "item",
          url: "earnings/telegram",
          icon: LiaTelegramPlane,
        },
      ],
    },
  ],
};

export default EarningPath;
