export const ADMIN_ROLE = 1;
export const LAB_ROLE = 3;
export const EMPLOYER_ROLE = 2;
export const EMPLOYEE_ROLE = 4;
export const DEFAULT_PAGE_SIZE = 20;

export const PAGE_SIZE_OPTIONS = [
  {
    text: "20",
    page: 20,
  },
  {
    text: "50",
    page: 50,
  },
  {
    text: "100",
    page: 100,
  },
];

export const clientStatus = [
  {
    label: "Ready To Post",
    value: "READY",
  },
  {
    label: "On Hold",
    value: "ONHOLD",
  },
  {
    label: "Posted",
    value: "DONE",
  },
];

export const upiOptions = [
  { label: "Paytm", value: "paytm" },
  { label: "PhonePe", value: "phonepe" },
  { label: "Google Pay", value: "googlepay" },
  { label: "BHIM", value: "bhim" },
  { label: "Amazon Pay", value: "amazonpay" },
  { label: "WhatsApp Pay", value: "whatsapp" },
  { label: "Airtel Payments Bank", value: "airtel" },
  { label: "Mobikwik", value: "mobikwik" },
];

export const earningTypeOptions = [
  { label: "Direct Joining", value: "direct_joining" },
  { label: "Watch Video", value: "watch_video" },
  { label: "Spin and Win", value: "spin_and_win" },
  { label: "Telegram", value: "telegram" },
  { label: "Level", value: "level" },
  { label: "Rewards", value: "rewards" },
  { label: "Approval", value: "approval" },
];

// export const baseURL = "http://localhost:8085";
// export const baseURL = "http://192.168.0.30:8085";
// export const baseURL = "http://test.successoffuture.com:5000";
// export const baseURL = "http://successoffuture.com:8085";
export const baseURL = "https://api.win24games.com";
