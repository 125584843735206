import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

// material-ui
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// project import
import { getUserAllDownline } from "actions/dashboardActions";
import MainCard from "View/Admin/Components/MainCard";
import Dot from "../Dashboard/Table/Dot";

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  {
    id: "userFullname",
    align: "left",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    align: "left",
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "myReferCode",
    align: "left",
    disablePadding: false,
    label: "Refer Code",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function UserTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#e2e6ed" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ fontWeight: "bold", fontSize: "14px" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

UserTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE ||============================== //
function UserDownLine({ getUserAllDownline, userAllDownlineList }) {
  const [order] = useState("asc");
  const [orderBy] = useState("userId");
  const [selected] = useState([]);

  const isSelected = (userId) => selected.indexOf(userId) !== -1;

  React.useEffect(() => {
    getUserAllDownline();
  }, [getUserAllDownline]);

  return (
    <MainCard>
      <Box>
        <TableContainer>
          <Table>
            <UserTableHead order={order} orderBy={orderBy} />
            <TableBody>
              {userAllDownlineList && userAllDownlineList.length
                ? userAllDownlineList?.map((user, index) => {
                    const labelId = `user-${index}`;

                    // Construct full name
                    const fullName = `${user.firstname} ${user.lastname}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected(user._id)}
                        tabIndex={-1}
                        key={user._id}
                        selected={isSelected(user._id)}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray",
                          }}
                        >
                          {fullName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray",
                          }}
                        >
                          {user.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray",
                          }}
                        >
                          {user.phone}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray",
                          }}
                        >
                          {user.myReferCode}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            fontSize: "13px",
                            borderBottom: "1px solid gray",
                          }}
                        >
                          {user.status === 1 ? (
                            <Dot color="success" />
                          ) : (
                            <Dot color="error" />
                          )}
                          {user.status === 1 ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
          {!userAllDownlineList || !userAllDownlineList.length ? (
            <Typography
              variant="div"
              className="d-flex justify-content-center mt-3"
            >
              No downline found
            </Typography>
          ) : null}
        </TableContainer>
      </Box>
    </MainCard>
  );
}

UserDownLine.propTypes = {
  getUserAllDownline: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userAllDownlineList: state.dashboard.userAllDownlineList,
});

export default connect(mapStateToProps, {
  getUserAllDownline,
})(UserDownLine);
