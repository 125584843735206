import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
// import Spinner from "views/Spinner";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

// import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  // updateAdminAccountSetting,
  getAdminAccountSettingById,
} from "actions/adminAccountSettingActions";

import Spinner from "View/Spinners/Spinner";
import Typography from "@mui/material/Typography";

const SetDefaultValuesEdit = ({
  resetComponentStore,
  currentAdminAccountSetting,
  // updateAdminAccountSetting,
  getAdminAccountSettingById,
  loadingAdminAccountSetting,
}) => {
  // const navigate = useNavigate();
  const { accountSetting_id } = useParams();

  const initialFormData = {
    projectName: "",
    email: "",
    mobileNumber: "",
    whatsAppNumber: "",
    adminTelegramLink: "",
    setYoutubeVideoIncome: "",
    setTelegramIncome: "",
    spinSlab: "",
    spinAmmountToMultiply: "",
    registrationBonus: "",
    minimumWithdrawal: "",
    maximumWithdrawal: "",
    minimumDeposit: "",
    defaultApprovalAmount: "",
    alertText: "",
    howToWithdrawalLink: "",
    withdrawalNotification: "",
    withdrawalIsClosed: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadBankFormData = (currentAdminAccountSetting) => {
    const {
      projectName,
      email,
      mobileNumber,
      whatsAppNumber,
      adminTelegramLink,
      setYoutubeVideoIncome,
      setTelegramIncome,
      spinSlab,
      spinAmmountToMultiply,
      registrationBonus,
      minimumWithdrawal,
      maximumWithdrawal,
      minimumDeposit,
      defaultApprovalAmount,
      alertText,
      howToWithdrawalLink,
      withdrawalNotification,
      withdrawalIsClosed,
    } = currentAdminAccountSetting;
    const data = {
      projectName,
      email,
      mobileNumber,
      whatsAppNumber,
      adminTelegramLink,
      setYoutubeVideoIncome,
      setTelegramIncome,
      spinSlab,
      spinAmmountToMultiply,
      registrationBonus,
      minimumWithdrawal,
      maximumWithdrawal,
      minimumDeposit,
      defaultApprovalAmount,
      alertText,
      howToWithdrawalLink,
      withdrawalNotification,
      withdrawalIsClosed,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentAdminAccountSetting) return;

    loadBankFormData(currentAdminAccountSetting);
  }, [currentAdminAccountSetting]);

  const {
    projectName,
    email,
    mobileNumber,
    whatsAppNumber,
    adminTelegramLink,
    setYoutubeVideoIncome,
    setTelegramIncome,
    spinSlab,
    spinAmmountToMultiply,
    registrationBonus,
    minimumWithdrawal,
    maximumWithdrawal,
    minimumDeposit,
    defaultApprovalAmount,
    alertText,
    howToWithdrawalLink,
    withdrawalNotification,
    withdrawalIsClosed,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // removeBankErrors();

    let validationRules = [];

    const errors = validateForm(formData, validationRules);

    // if (errors.length) {
    //   setErrors(errors);
    //   return;
    // }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    // updateAdminAccountSetting(submitData, navigate, accountSetting_id).then(
    //   (res) => {
    //     setSubmitting(false);
    //     toggleEdit();
    //   }
    // );
  };

  React.useMemo(() => {
    if (!accountSetting_id) return;

    resetComponentStore();

    getAdminAccountSettingById(accountSetting_id);
  }, []);

  return (
    <React.Fragment>
      {loadingAdminAccountSetting ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className="mb-2"
                  >
                    Details
                  </Typography>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="projectName">Project Name*</Form.Label>
                  <Form.Control
                    // className={errorList.projectName ? "invalid" : ""}
                    type="text"
                    id="projectName"
                    name="projectName"
                    maxLength="150"
                    value={projectName}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="projectName"
                    id="projectName-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="email">Email*</Form.Label>
                  <Form.Control
                    // className={errorList.email ? "invalid" : ""}
                    type="text"
                    id="email"
                    name="email"
                    maxLength="150"
                    value={email}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="email"
                    id="email-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="Mobile Number">
                    Mobile Number*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.mobileNumber ? "invalid" : ""}
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    maxLength="10"
                    value={mobileNumber}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="mobileNumber"
                    id="mobileNumber-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="whatsAppNumber">
                    WhatsApp Number*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.whatsAppNumber ? "invalid" : ""}
                    type="text"
                    id="whatsAppNumber"
                    name="whatsAppNumber"
                    maxLength="150"
                    value={whatsAppNumber}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="whatsAppNumber"
                    id="whatsAppNumber-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="adminTelegramLink">
                    Telegram Channel Link*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="adminTelegramLink"
                    name="adminTelegramLink"
                    maxLength="250"
                    value={adminTelegramLink}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="setYoutubeVideoIncome">
                    Set Youtube Video Income*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.setYoutubeVideoIncome ? "invalid" : ""}
                    type="text"
                    id="setYoutubeVideoIncome"
                    name="setYoutubeVideoIncome"
                    maxLength="150"
                    value={setYoutubeVideoIncome}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="setYoutubeVideoIncome"
                    id="setYoutubeVideoIncome-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="setTelegramIncome">
                    Set Telegram Income*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.setTelegramIncome ? "invalid" : ""}
                    type="text"
                    id="setTelegramIncome"
                    name="setTelegramIncome"
                    maxLength="150"
                    value={setTelegramIncome}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="setTelegramIncome"
                    id="setTelegramIncome-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="spinSlab">Spin Slab*</Form.Label>
                  <Form.Control
                    // className={errorList.spinSlab ? "invalid" : ""}
                    type="text"
                    id="spinSlab"
                    name="spinSlab"
                    maxLength="150"
                    value={spinSlab}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="spinSlab"
                    id="spinSlab-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="spinAmmountToMultiply">
                    Spin Amount To Multiply*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.spinAmmountToMultiply ? "invalid" : ""}
                    type="text"
                    id="spinAmmountToMultiply"
                    name="spinAmmountToMultiply"
                    maxLength="150"
                    value={spinAmmountToMultiply}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="spinAmmountToMultiply"
                    id="spinAmmountToMultiply-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="registrationBonus">
                    Registration Bonus*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.registrationBonus ? "invalid" : ""}
                    type="text"
                    id="registrationBonus"
                    name="registrationBonus"
                    maxLength="150"
                    value={registrationBonus}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="registrationBonus"
                    id="registrationBonus-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="minimumWithdrawal">
                    Minimum Withdrawal*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.minimumWithdrawal ? "invalid" : ""}
                    type="text"
                    id="minimumWithdrawal"
                    name="minimumWithdrawal"
                    maxLength="150"
                    value={minimumWithdrawal}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="minimumWithdrawal"
                    id="minimumWithdrawal-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="maximumWithdrawal">
                    Maximum Withdrawal*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="maximumWithdrawal"
                    name="maximumWithdrawal"
                    maxLength="150"
                    value={maximumWithdrawal}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="minimumDeposit">
                    Minimum Deposit*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.minimumDeposit ? "invalid" : ""}
                    type="text"
                    id="minimumDeposit"
                    name="minimumDeposit"
                    maxLength="150"
                    value={minimumDeposit}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="minimumDeposit"
                    id="minimumDeposit-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="defaultApprovalAmount">
                    Default Approval Amount*
                  </Form.Label>
                  <Form.Control
                    // className={errorList.defaultApprovalAmount ? "invalid" : ""}
                    type="text"
                    id="defaultApprovalAmount"
                    name="defaultApprovalAmount"
                    maxLength="150"
                    value={defaultApprovalAmount}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="defaultApprovalAmount"
                    id="defaultApprovalAmount-bank"
                  /> */}
                </Form.Group>
                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="alertText">Alert Text*</Form.Label>
                  <Form.Control
                    // className={errorList.alertText ? "invalid" : ""}
                    type="text"
                    id="alertText"
                    name="alertText"
                    maxLength="150"
                    value={alertText}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  {/* <Errors
                    errorList={errorList}
                    errors={null}
                    yupErrors={null}
                    currentKey="alertText"
                    id="alertText-bank"
                  /> */}
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="howToWithdrawalLink">
                    How To Withdrawal Link*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="howToWithdrawalLink"
                    name="howToWithdrawalLink"
                    maxLength="250"
                    value={howToWithdrawalLink}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label htmlFor="withdrawalNotification">
                    Withdrawal Notification*
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="withdrawalNotification"
                    name="withdrawalNotification"
                    maxLength="255"
                    value={withdrawalNotification}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                </Form.Group>

                <Form.Group className="form-group mb-3">
                  <Form.Label>Is Withdrawal Working*</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      id="withdrawalIsClosed-yes"
                      name="withdrawalIsClosed"
                      value="true"
                      checked={withdrawalIsClosed === "true"}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      id="withdrawalIsClosed-no"
                      name="withdrawalIsClosed"
                      value="false"
                      checked={withdrawalIsClosed === "false"}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />
                  </div>

                  {/* Uncomment this block if you need to display errors */}
                  {/* <Errors
                        errorList={errorList}
                        errors={null}
                        yupErrors={null}
                        currentKey="withdrawalIsClosed"
                        id="withdrawalIsClosed-bank"
                      /> */}
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mx-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

// SetDefaultValuesEdit.propTypes = {
//   loadPage: PropTypes.func.isRequired,
//   errorList: PropTypes.object.isRequired,
//   cancelSave: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingAdminAccountSetting: state.accountSetting.loadingAdminAccountSetting,
  adminAccountSettingsList: state.accountSetting.adminAccountSettingsList,
  currentAdminAccountSetting: state.accountSetting.currentAdminAccountSetting,
});

export default connect(mapStateToProps, {
  // createAdminAccountSetting,
  removeAdminAccountSettingErrors,
  resetComponentStore,
  getAdminAccountSettingsList,
  // updateAdminAccountSetting,
  getAdminAccountSettingById,
})(SetDefaultValuesEdit);
