import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  upisList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentUpi: null,
  loadingUpisList: true,
  loadingUpi: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const upiSlice = createSlice({
  name: "upi",
  initialState: initialState,
  reducers: {
    loadingUpisList(state) {
      return {
        ...state,
        loadingUpisList: true,
      };
    },
    upiSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingUpisList: false,
      };
    },
    upiListUpdated(state, action) {
      return {
        ...state,
        upisList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingUpisList: false,
      };
    },
    upiDetailsById(state, action) {
      return {
        ...state,
        currentUpi: action.payload,
        loadingUpi: false,
      };
    },
    resetUpi(state) {
      return {
        ...initialState,
      };
    },

    upiError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingUpi: false,
        loadingUpisList: false,
      };
    },
    loadingOnUpiSubmit(state) {
      return {
        ...state,
        loadingUpi: true,
      };
    },
    upiCreated(state) {
      return {
        ...state,
        loadingUpi: false,
      };
    },
    upiUpdated(state, action) {
      return {
        ...state,
        upisList: {
          ...state.upisList,
          data: state.upisList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    upiDeleted(state, action) {
      const currentCount = state.upisList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.upisList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        upisList: {
          data: state.upisList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingUpisList: false,
      };
    },
  },
});
export const {
  loadingUpisList,
  upiSearchParametersUpdate,
  upiListUpdated,
  resetUpi,
  upiError,
  loadingOnUpiSubmit,
  upiCreated,
  upiUpdated,
  upiDetailsById,
  upiDeleted,
} = upiSlice.actions;
export default upiSlice.reducer;
