// project import
// import NavCard from "./NavCard";
import Navigation from "./Navigation/index";
import SimpleBar from "./SimpleBar/SimpleBar";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
  <SimpleBar
    sx={{
      "& .simplebar-content": {
        display: "flex",
        flexDirection: "column",
      },
    }}
    className="overflow-scroll hulk"
  >
    <Navigation />
    {/* <NavCard /> */}
  </SimpleBar>
);

export default DrawerContent;
