import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import moment from "moment";

import PiDataTable from "View/DataTable/PiDataTable";
import AppBreadcrumb from "View/DataTable/AppBreadCrumb";

import {
  getUsersUnapprovedList,
  deleteUser,
  resetComponentStore,
} from "actions/user";
import UserFilters from "./UserFilters";
import { isAdmin } from "utils/helper";

const UsersUnapprovedList = ({
  loggedInUser,
  usersUnapprovedList: { data, count },
  getUsersUnapprovedList,
  deleteUser,
  loadingUserList,
  resetComponentStore,
  sortingParams,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [userParams, setUserParams] = React.useState(initialSortingParams);

  const columns = [
    {
      name: "Name",
      selector: (row) => `${row.firstname} ${row.lastname}`,
      sortable: false,
      sortField: "name",
      width: "10%",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      sortField: "email",
      width: "15%",
      wrap: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: false,
      width: "15%",
      wrap: true,
    },
    {
      name: "My Refer",
      selector: (row) => row.myReferCode,
      sortable: false,
      width: "15%",
      wrap: true,
    },
    {
      name: "Referral By",
      cell: (row) => (row.referralCode ? row.referralCode : "-"),
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "Registration Date",
      cell: (row) =>
        row.createdAt
          ? moment(row.createdAt).format("hh:mm a, DD-MM-YYYY")
          : "",
      sortable: false,
      width: "20%",
    },

    {
      name: "Actions",
      width: "10%",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link to={`/admin/users/edit/${row._id}`} title="View User">
            <Button variant="primary" size="sm">
              <FaRegEye />
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    if (!loggedInUser) return;

    // To Prevent API call when user is not a admin
    if (!isAdmin(loggedInUser)) {
      navigate("/admin/user-dashboard");
      return;
    }

    getUsersUnapprovedList(userParams);
  }, [getUsersUnapprovedList, userParams, resetComponentStore, loggedInUser]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              firstname: {
                value: searchText,
                type: "String",
              },
              lastname: {
                value: searchText,
                type: "String",
              },
              email: {
                value: searchText,
                type: "String",
              },
              phone: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setUserParams(params);
  };

  const onFilterChange = (newParams) => {
    setUserParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Unapproved Users"
        crumbs={[{ name: "Users" }]}
      />

      <Card>
        <Card.Body>
          {/* {actions} */}
          <div className="table-filter-section">
            <Row>
              <Col md="4">
                <UserFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={userParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={userParams}
            setParams={setUserParams}
            pagination
            responsive
            striped={true}
            progressPending={loadingUserList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

UsersUnapprovedList.propTypes = {
  getUsersUnapprovedList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  usersUnapprovedList: state.user.usersUnapprovedList,
  loadingUserList: state.user.loadingUserList,
  sortingParams: state.user.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getUsersUnapprovedList,
  deleteUser,
  resetComponentStore,
})(UsersUnapprovedList);
