// material-ui
import { Grid, Stack, Typography } from "@mui/material";
import AuthWrapper from "./AuthWrapper";
import AuthTroubleLogin from "./auth-forms/AuthTroubleLogin";
import React from "react";
import AuthCodeVerification from "./auth-forms/AuthCodeVerification";

// ================================|| LOGIN ||================================ //

const TroubleLogin = () => {
  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography variant="h3" className="mt-4 mb-0">
              Troubleshoot Login
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthTroubleLogin />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default TroubleLogin;
