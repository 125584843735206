import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MainCard from "View/Admin/Components/MainCard";
import { getAdminAllDownline } from "actions/dashboardActions";
import { Button } from "react-bootstrap";

const headCells = [
  {
    id: "userFullname",
    align: "left",
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    align: "left",
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "status",
    align: "left",
    disablePadding: false,
    label: "",
  },
];

function UserTableHead() {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#e2e6ed" }}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={`${headCell.id}-${i}`}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ fontWeight: "bold", fontSize: "14px" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function AdminDownline({ getAdminAllDownline, adminAllDownlineList }) {
  const [selected, setSelected] = useState(null);

  const isSelected = (userId) => selected === userId;

  const handleToggle = (userId) => {
    setSelected(isSelected(userId) ? null : userId);
  };

  useEffect(() => {
    getAdminAllDownline();
  }, [getAdminAllDownline]);

  const renderChildren = (children) => {
    return children.map((child, childIndex) => (
      <React.Fragment key={`${child.user._id}-${childIndex}`}>
        <TableRow>
          <TableCell>{`${child.user.firstname} ${child.user.lastname}`}</TableCell>
          <TableCell>{child.user.email}</TableCell>
          <TableCell>{child.user.phone}</TableCell>
          <TableCell>
            {child.user.status === 1 ? "Active" : "Inactive"}
          </TableCell>
        </TableRow>
        {child.children && child.children.length > 0 && (
          <TableRow>
            <TableCell colSpan={4}>
              <Accordion>
                <AccordionSummary expandIcon={<FaAngleDown size={15} />}>
                  <Button variant="primary" disabled>
                    Click To See Downline
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>{renderChildren(child.children)}</TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    ));
  };

  return (
    <MainCard>
      <Box>
        <TableContainer>
          <Table>
            <UserTableHead />
            <TableBody>
              {adminAllDownlineList && adminAllDownlineList.length > 0 ? (
                adminAllDownlineList.map((admin, index) => {
                  const labelId = `admin-${index}`;
                  const fullName = `${admin.user.firstname} ${admin.user.lastname}`;
                  const hasChildren =
                    admin.children && admin.children.length > 0;

                  return (
                    <React.Fragment key={`${admin.user._id}-${index}`}>
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected(admin.user._id)}
                        tabIndex={-1}
                        selected={isSelected(admin.user._id)}
                        onClick={() => handleToggle(admin.user._id)}
                        style={{ cursor: hasChildren ? "pointer" : "default" }}
                        sx={{
                          borderBottom: "1px solid gray", // Add border to table rows
                        }}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray", // Add border to table rows
                          }}
                        >
                          {fullName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray", // Add border to table rows
                          }}
                        >
                          {admin.user.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray", // Add border to table rows
                          }}
                        >
                          {admin.user.phone}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray", // Add border to table rows
                          }}
                        >
                          {admin.user.status === 1 ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottom: "1px solid gray", // Add border to table rows
                          }}
                        >
                          {hasChildren &&
                            (isSelected(admin.user._id) ? (
                              <FaAngleUp size={15} />
                            ) : (
                              <FaAngleDown size={15} />
                            ))}
                        </TableCell>
                      </TableRow>
                      {isSelected(admin.user._id) && hasChildren && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<FaAngleDown size={15} />}
                              >
                                <Button variant="primary" disabled>
                                  Click To See Downline
                                </Button>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Table>
                                  <TableBody>
                                    {renderChildren(admin.children)}
                                  </TableBody>
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography
                      variant="body2"
                      className="d-flex justify-content-center mt-3"
                    >
                      No downline found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MainCard>
  );
}

AdminDownline.propTypes = {
  getAdminAllDownline: PropTypes.func.isRequired,
  adminAllDownlineList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  adminAllDownlineList: state.dashboard.adminAllDownlineList,
});

export default connect(mapStateToProps, { getAdminAllDownline })(AdminDownline);
