import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
} from "@mui/material";

const UpiDetailsModal = ({ upiDetails, onHide }) => {
  return (
    <Dialog open={true} onClose={onHide}>
      <DialogTitle variant="h4" color="textSecondary">
        UPI Details
      </DialogTitle>
      <DialogContent>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Name:
          </Typography>
          <Typography color="primary.main">
            {upiDetails.upiHolderName}
          </Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            UPI ID:
          </Typography>
          <Typography color="primary.main">{upiDetails.upiId}</Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Mobile Number:
          </Typography>
          <Typography color="primary.main">
            {upiDetails.mobileNumber}
          </Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            UPI Option:
          </Typography>
          <Typography color="primary.main">
            {upiDetails.selectedUpiOption}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onHide} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpiDetailsModal;
