import React from "react";
import Grid from "@mui/material/Grid";
import SetDefaultValuesList from "./SetDefaulValues/SetDefaultValuesList";
import EarningLinksList from "./AdminLinks/EarningLinksList";

const AdminAccountSetting = () => {
  return (
    <Grid container>
      <Grid xs={12}>
        <SetDefaultValuesList />
        <EarningLinksList />
      </Grid>
    </Grid>
  );
};

export default AdminAccountSetting;
