import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import AppBreadcrumb from "View/DataTable/AppBreadCrumb";
import PiDataTable from "View/DataTable/PiDataTable";

import {
  getOrdersListByUserId,
  resetComponentStore,
} from "actions/userOrderActions";

import Dot from "../Dashboard/Table/Dot";
import { capitalizeFirst } from "utils/helper";
import UserOrdersFilters from "../AdminRequests/UserOrdersFilters";

const UserOrdersHistory = ({
  userOrdersList: { orderData, count },
  getOrdersListByUserId,
  loadingOrdersList,
  resetComponentStore,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const columns = [
    {
      name: "Name",
      selector: (row) => capitalizeFirst(row.name),
      sortable: false,
      width: "15%",
    },

    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: false,
      width: "15%",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Dot color={row.status} size={8} />
          {capitalizeFirst(row.status)}
        </div>
      ),
      sortable: false,
      width: "15%",
      wrap: true,
    },

    {
      name: "UTR Number",
      selector: (row) => row.utrNumber,
      sortable: false,
      width: "20%",
      wrap: true,
    },

    {
      name: "Date",
      cell: (row) => moment(row.createdAt).format("hh:mm a, DD-MM-YYYY"),
      sortable: false,
      width: "20%",
    },
  ];

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getOrdersListByUserId(clientParams);
  }, [getOrdersListByUserId, clientParams, resetComponentStore]);

  const handleSearch = (fromDate, toDate, statusType) => {
    getOrdersListByUserId({ fromDate, toDate, statusType, ...clientParams });
  };

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Orders History"
        crumbs={[{ name: "Orders History" }]}
      />

      <div className="table-filter-section mb-4">
        <UserOrdersFilters onSearch={handleSearch} />
      </div>

      <Card>
        <Card.Body>
          <PiDataTable
            columns={columns}
            data={orderData}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            responsive
            striped={true}
            progressPending={loadingOrdersList}
            highlightOnHover
            persistTableHead={true}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

UserOrdersHistory.propTypes = {
  getOrdersListByUserId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userOrdersList: state.userOrder.userOrdersList,
  loadingOrdersList: state.userOrder.loadingOrdersList,
});

export default connect(mapStateToProps, {
  getOrdersListByUserId,
  resetComponentStore,
})(UserOrdersHistory);
