import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  spinAndWinError,
  resetSpinAndWin,
  spinAndWinCreated,
  spinAndWinListData,
} from "reducers/spinAndWinReducer";

import { baseURL } from "constants/index";

export const createSpinAndWin = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const data = {
      amount: formData,
    };

    const res = await axios.post(
      `${baseURL}/api/admin/spin-and-win`,
      data,
      config
    );
    if (res.data.status === true) {
      dispatch(spinAndWinCreated(res.data.response));
      dispatch(setAlert("Spin And Win Created.", "success"));
      navigate("/admin/user-dashboard");
      return res.data;
    } else {
      const errors = res.data.errors;

      if (errors) {
        dispatch(spinAndWinError());
        dispatch(setAlert(errors.msg, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);

    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          spinAndWinError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "error"));
    }
  }
};

export const getSpinAndWinList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    const res = await axios.get(
      `${baseURL}/api/admin/spin-and-win/list/${loggedInUserID}`,
      config
    );
    dispatch(spinAndWinListData(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          spinAndWinError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

// reset errors
export const removeSpinAndWinErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetSpinAndWin());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(spinAndWinError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/spin-and-win");
};
