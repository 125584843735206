import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingWithdrawalsList,
  withdrawalSearchParametersUpdate,
  withdrawalListUpdated,
  resetWithdrawal,
  withdrawalError,
  loadingOnWithdrawalSubmit,
  withdrawalCreated,
  withdrawalUpdated,
  withdrawalDetailsById,
  withdrawalDeleted,
  userApprovedDownlineCountData,
} from "reducers/withdrawalReducer";

import { baseURL } from "constants/index";

export const getWithdrawalsList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingWithdrawalsList());
    const res = await axios.get(
      `${baseURL}/api/admin/withdrawals/list/${loggedInUserID}`,
      config
    );

    dispatch(withdrawalSearchParametersUpdate(appTokenParams));

    dispatch(withdrawalListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          withdrawalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getUserApprovedDownlineCount = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `${baseURL}/api/admin/withdrawals/approved-count`,
      config
    );

    dispatch(userApprovedDownlineCountData(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          withdrawalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getWithdrawalById = (withdrawal_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnWithdrawalSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `${baseURL}/api/admin/withdrawals/${withdrawal_id}`,
      config
    );

    dispatch(withdrawalDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          withdrawalError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createWithdrawal = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnWithdrawalSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/withdrawals`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(withdrawalCreated(res.data.response));
      dispatch(setAlert("Withdrawal Created.", "success"));
      navigate(`/admin/withdrawal-history`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(withdrawalError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch(setAlert(err.response.data.message, "error"));
      // Handle HTTP error response
    } else {
      // Handle other types of errors
      dispatch(setAlert("An error occurred", "error"));
    }
  }
};

export const updateWithdrawal =
  (formData, withdrawal_id, navigate) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${baseURL}/api/admin/withdrawals/${withdrawal_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(withdrawalUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        navigate("/admin/withdrawal-request");
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(withdrawalError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            withdrawalError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteWithdrawal = (withdrawal_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(
      `${baseURL}/api/admin/withdrawals/${withdrawal_id}`,
      config
    );

    dispatch(withdrawalDeleted(withdrawal_id));
    dispatch(setAlert("Withdrawal deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        withdrawalError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeWithdrawalErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetWithdrawal());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(withdrawalError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/withdrawals");
};
