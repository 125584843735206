import React from "react";
import YouTube from "react-youtube";

const YouTubeVideos = ({ videoId }) => {
  const isMobile = window.innerWidth <= 768;
  const opts = {
    height: isMobile ? "200" : "300",
    width: isMobile ? "300" : "500",
    playerVars: {
      autoplay: 1,
      controls: 0, // Disable player controls
      disablekb: 1, // Disable keyboard controls
      fs: 0, // Disable fullscreen button
      modestbranding: 1, // Hide YouTube logo
      playsinline: 1,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

export default YouTubeVideos;
