import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
} from "@mui/material";

const BankDetailsModal = ({ bankDetails, onHide }) => {
  return (
    <Dialog open={true} onClose={onHide}>
      <DialogTitle variant="h4" color="textSecondary">
        Bank Details
      </DialogTitle>
      <DialogContent>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Account Holder Name:
          </Typography>
          <Typography color="primary.main">
            {bankDetails.accountHolderName}
          </Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Bank Name:
          </Typography>
          <Typography color="primary.main">{bankDetails.bankName}</Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Branch:
          </Typography>
          <Typography color="primary.main">{bankDetails.branch}</Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Account Number:
          </Typography>
          <Typography color="primary.main">
            {bankDetails.accountNumber}
          </Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            IFSC Code:
          </Typography>
          <Typography color="primary.main">{bankDetails.ifscCode}</Typography>
        </Grid>
        <Grid container className="mb-2">
          <Typography color="textSecondary" style={{ width: "180px" }}>
            Account Type:
          </Typography>
          <Typography color="primary.main">
            {bankDetails.accountType}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankDetailsModal;
