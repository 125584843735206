// assets
import { PiUserList } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const AdminUsersListPath = {
  id: "userList",
  title: "",
  type: "group",
  children: [
    {
      id: "user-list",
      title: "Users List",
      type: "item",
      icon: FaUsers,
      subChlidrens: [
        {
          id: "users-list-sub",
          title: "Approved Users List",
          type: "item",
          url: "users-all-list",
          icon: PiUserList,
        },

        {
          id: "unapproved-users-list",
          title: "Unapproved Users List",
          type: "item",
          url: "unapproved-users-all-list",
          icon: PiUserList,
        },
      ],
    },
  ],
};

export default AdminUsersListPath;
