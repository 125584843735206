import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import store from "./store";
import { loadUser, logout } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import setAuthID from "utils/setAuthID";

import ThemeCustomization from "View/Admin/Components/Themes/Index";

//Admin
import Login from "View/Auth/Login";
import LoginCopy from "View/Auth/LoginCopy";
// import Register from "View/Auth/Register";
import ForgotPassword from "View/Auth/ForgotPassword";
import AdminIndex from "View/Admin/Layout/AdminIndex";
import PrivateRoute from "View/Routing/AdminRoutes/PrivateRoute";

// //User
// import UserForgotPassword from "View/Auth/user/UserForgotPassword";
// import UserLogin from "View/Auth/user/UserLogin";
import UserRegister from "View/Auth/user/UserRegister";
import ChangeForgotPassword from "View/Auth/ChangeForgotPassword";
import Home from "View/userPublicLayout/Home";
import ChangePasswordModal from "View/Auth/ChangePasswordModal";
import TroubleLogin from "View/Auth/TroubleLogin";
import CodeVerification from "View/Auth/CodeVerification";
import { logoutAuth } from "reducers/auth";

// if (localStorage.token) {
// setAuthToken(localStorage.token);
// }

// if (localStorage.authLocalID) {
// setAuthID(localStorage.authLocalID);
// }

const App = (props) => {
  React.useEffect(() => {
    store.dispatch(logoutAuth());
  }, []);

  return (
    <ThemeCustomization>
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/" element={<LoginCopy />} />
          <Route path="/trouble-logging" element={<TroubleLogin />} />
          {/* <Route path="/code-verification" element={<CodeVerification />} /> */}
          {/* <Route path="/register/*" element={<UserRegister />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/users/change-password"
            element={<ChangePasswordModal />}
          />
          <Route
            path="/forgot-password/change-password"
            element={<ChangeForgotPassword />}
          />

          <Route element={<PrivateRoute />}>
            <Route path="/admin/*" element={<AdminIndex />} />
          </Route>

          {/*<Route path="/*" element={<NotFound />} /> */}
        </Routes>
      </div>
    </ThemeCustomization>
  );
};

export default App;
