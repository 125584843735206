import React from "react";
import { Routes, Route } from "react-router-dom";
import { Grid } from "@mui/material";
import AdminLayout from "./AdminLayout";
import AdminRoutes from "View/Routing/AdminRoutes/AdminRoutes";

const AdminIndex = () => {
  return (
    <Grid>
      <Routes>
        <Route path="/" element={<AdminLayout />}>
          {AdminRoutes.map((route, i) => {
            return <Route path={route.path} element={route.element} key={i} />;
          })}
        </Route>
      </Routes>
    </Grid>
  );
};

export default AdminIndex;
