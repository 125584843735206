import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

// Material-UI components
import {
  Button,
  Grid,
  Typography,
  Stack,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

// Components
import Errors from "Notifications/Errors";
import Spinner from "View/Spinners/Spinner";
import MainCard from "View/Admin/Components/MainCard";

// Actions
import {
  cancelSave,
  createQRScanner,
  removeQRScannerErrors,
  resetComponentStore,
  setErrors,
} from "actions/qrScannerActions";
import AnimateButton from "View/Admin/Components/AnimateButton";

const CreateQRScanner = ({
  createQRScanner,
  errorList,
  cancelSave,
  loadingQRScanner,
  setErrors,
  removeQRScannerErrors,
  resetComponentStore,
}) => {
  const navigate = useNavigate();

  const initialValues = {
    title: "",
    imageUrl: null,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    imageUrl: Yup.mixed()
      .required("Image is required")
      .test(
        "fileSize",
        "File size must be less than 1 MB",
        (value) => value && value.size <= 1024 * 1024
      )
      .test("fileType", "Only JPEG, and PNG files are allowed", (value) => {
        if (!value) return true;
        return ["image/jpeg", "image/png"].indexOf(value.type) !== -1;
      }),
  });

  const onSubmit = (values) => {
    removeQRScannerErrors();

    const { title, imageUrl } = values;

    const updatedFormdata = new FormData();
    updatedFormdata.append("title", title);
    updatedFormdata.append("imageFile", imageUrl);

    createQRScanner(updatedFormdata, navigate).then((res) => {
      if (!res.status) return;
    });
  };

  return (
    <React.Fragment>
      {loadingQRScanner ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <MainCard>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="title">Title*</InputLabel>
                        <OutlinedInput
                          id="title"
                          type="text"
                          value={values.title}
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Title"
                          fullWidth
                          error={Boolean(touched.title && errors.title)}
                        />
                        {touched.title && errors.title && (
                          <Errors errors={errors} currentKey="title" />
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="imageUrl">Image Url*</InputLabel>
                        <OutlinedInput
                          id="imageUrl"
                          type="file"
                          name="imageUrl"
                          onBlur={handleBlur}
                          onChange={(event) => {
                            setFieldValue(
                              "imageUrl",
                              event.currentTarget.files[0]
                            );
                          }}
                          fullWidth
                          error={Boolean(touched.imageUrl && errors.imageUrl)}
                        />
                        {touched.imageUrl && errors.imageUrl && (
                          <Errors errors={errors} currentKey="imageUrl" />
                        )}
                      </Stack>
                    </Grid>

                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item xs={2} className="mt-4">
                        <AnimateButton>
                          <Button
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            type="submit"
                            variant="contained"
                            color="error"
                          >
                            Cancle
                          </Button>
                        </AnimateButton>
                      </Grid>
                      <Grid item xs={2} className="mt-4">
                        <AnimateButton>
                          <Button
                            disabled={isSubmitting}
                            fullWidth
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Submit
                          </Button>
                        </AnimateButton>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};

CreateQRScanner.propTypes = {
  createQRScanner: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
  loadingQRScanner: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  removeQRScannerErrors: PropTypes.func.isRequired,
  resetComponentStore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingQRScanner: state.qrScanner.loadingQRScanner,
});

export default connect(mapStateToProps, {
  createQRScanner,
  cancelSave,
  setErrors,
  removeQRScannerErrors,
  resetComponentStore,
})(CreateQRScanner);
