import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import AnimateButton from "View/Admin/Components/AnimateButton";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Errors from "Notifications/Errors";

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthLoginCopy = ({ errorList }) => {
  const initialState = {
    email_or_referCode: "",
    password: "",
    rememberMe: "",
  };

  const navigate = useNavigate();

  const [formData, setFormData] = React.useState(initialState);
  const [showPassword, setShowPassword] = React.useState(false);
  const [accountNotVerified, setAccountNotVerified] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      setStatus({ success: false });
      setSubmitting(false);

      const isAccountVerified = false;

      if (!isAccountVerified) {
        setAccountNotVerified(true);
        setErrors({ email_or_referCode: "Invalid account" });
      } else {
        setAccountNotVerified(false);
      }
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={Yup.object().shape({
          email_or_referCode: Yup.string().required(
            "Email or ReferCode is required"
          ),
          password: Yup.string().min(3).required("Password is required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          const yupErrors = {};
          if (errors) {
            Object.keys(errors).forEach((key) => {
              if (touched[key]) {
                yupErrors[key] = errors[key];
              }
            });
          }

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email_or_referCode-login">
                      User ID*
                    </InputLabel>
                    <OutlinedInput
                      id="email_or_referCode-login"
                      type="text"
                      value={values.email_or_referCode}
                      name="email_or_referCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter User ID"
                      fullWidth
                      error={Boolean(
                        touched.email_or_referCode &&
                          (errors.email_or_referCode ||
                            (errorList && errorList.email_or_referCode))
                      )}
                      autoComplete="off"
                    />

                    {touched.email_or_referCode &&
                      (errors.email_or_referCode ||
                        yupErrors?.email_or_referCode ||
                        (errorList && errorList.email_or_referCode)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="email_or_referCode"
                          id="email_or_referCode-login"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="password-login">Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(
                        touched.password &&
                          (errors.password || (errorList && errorList.password))
                      )}
                      id="password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Enter password"
                      autoComplete="password"
                    />

                    {touched.password &&
                      (errors.password ||
                        yupErrors?.password ||
                        (errorList && errorList.password)) && (
                        <Errors
                          errorList={errorList}
                          errors={errors}
                          yupErrors={yupErrors}
                          currentKey="password"
                          id="password-login"
                        />
                      )}
                  </Stack>
                </Grid>

                <Grid item xs={12} sx={{ mt: -1 }}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Link
                      variant="h6"
                      component={RouterLink}
                      to="/trouble-logging"
                      color="text.primary"
                    >
                      Troubleshoot Logging?
                    </Link>
                  </Stack>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Login
                    </Button>
                  </AnimateButton>
                </Grid>
                {accountNotVerified && (
                  <Grid item xs={12}>
                    <FormHelperText
                      id="error"
                      style={{
                        backgroundColor: "#ffebee",
                        color: "red",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {`Your account is currently not verified. Please `}
                      <Link
                        component={RouterLink}
                        to="/trouble-logging"
                        color="text.primary"
                      >
                        click here
                      </Link>
                      {` to validate your account.`}
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

AuthLoginCopy.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps, {})(AuthLoginCopy);
