import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  // spinAndWinAmount: 0,
  loadingYoutube: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const youtubeSlice = createSlice({
  name: "youtube",
  initialState: initialState,
  reducers: {
    // spinAndWinAmount(state, action) {
    //   return {
    //     ...state,
    //     spinAndWinAmount: action.payload,
    //   };
    // },

    youtubeCreated(state) {
      return {
        ...state,
        loadingYoutube: false,
      };
    },

    resetYoutube(state) {
      return {
        ...initialState,
      };
    },

    youtubeError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingYoutube: false,
      };
    },
  },
});
export const {
  // spinAndWinAmount,
  youtubeCreated,
  youtubeError,
  resetYoutube,
} = youtubeSlice.actions;
export default youtubeSlice.reducer;
