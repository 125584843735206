import React from "react";
import Grid from "@mui/material/Grid";
import MainCard from "../Components/MainCard";
import Typography from "@mui/material/Typography";
import AnimateButton from "../Components/AnimateButton";
import Button from "@mui/material/Button";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import {
  deleteProduct,
  getProductsList,
  resetComponentStore,
} from "actions/productActions";

import { useNavigate } from "react-router-dom";
import { getOrdersListByUserId } from "actions/userOrderActions";

const ProductCards = ({
  productsList: { data, count },
  getProductsList,
  userOrdersList: { orderData },
  loadingProductsList,
  resetComponentStore,
  sortingParams,
  getOrdersListByUserId,
  deleteProduct,
  user,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const navigate = useNavigate();

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [showMoreHeading, setShowMoreHeading] = React.useState(false);
  const [showMoreDescription, setShowMoreDescription] = React.useState(false);

  const headingLimit = 50;
  const descriptionLimit = 60;

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getProductsList(clientParams);
  }, [getProductsList, clientParams, resetComponentStore]);

  const hanldeOrderSubmit = (item) => {
    const hasPendingOrder = orderData.some(
      (order) => order.status === "pending"
    );

    if (hasPendingOrder) {
      window.alert(
        `Your order request with UTR Number ${orderData[0]?.utrNumber} has been successfully submitted. Please await administrative order.`
      );
      return;
    }

    navigate("/admin/orders/checkout", { state: { item } });
  };

  const toggleMore = () => {
    setShowMoreHeading(!showMoreHeading);
  };

  React.useEffect(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getOrdersListByUserId(clientParams);
  }, [getOrdersListByUserId, clientParams, resetComponentStore]);
  return (
    <Grid container spacing={1}>
      {data && data.length > 0
        ? data.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
              <MainCard className="card">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className=" mb-4"
                >
                  <Grid item>
                    <img
                      src={item.imageUrl}
                      height={220}
                      width={250}
                      alt={`${item.productName}`}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  direction="column"
                >
                  <Grid item className="fw-semibold">
                    {/* {item.productName} */}
                    {showMoreHeading || item.productName.length <= headingLimit
                      ? item.productName
                      : item.productName.slice(0, headingLimit) + " ..."}
                    {/* {item.productName.length > headingLimit ? (
                      <Typography
                        variant="span"
                        color="primary.main"
                        sx={{ cursor: "pointer", fontSize: "12px" }}
                        className="ms-2 "
                        onClick={() => toggleMore()}
                      >
                        {showMoreHeading ? "...less" : "more..."}
                      </Typography>
                    ) : (
                      ""
                    )} */}
                  </Grid>
                  <Grid item>
                    <Typography color="textSecondary">
                      {/* {item.description} */}
                      {showMoreDescription ||
                      item.description.length <= descriptionLimit
                        ? item.description
                        : item.description.slice(0, descriptionLimit) + " ..."}
                      {/* {item.description.length > descriptionLimit ? (
                        <Typography
                          variant="span"
                          color="primary.main"
                          sx={{ cursor: "pointer", fontSize: "12px" }}
                          className="ms-2 fw-semibold"
                          onClick={() =>
                            setShowMoreDescription(!showMoreDescription)
                          }
                        >
                          {showMoreDescription ? "...less" : "more.."}
                        </Typography>
                      ) : (
                        ""
                      )} */}
                    </Typography>
                  </Grid>
                  <Grid item className="d-flex gap-2">
                    <Typography color="initial" className="fw-semibold">
                      &#x20b9;{item.price}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      className="text-decoration-line-through "
                    >
                      &#x20b9;{item.mrp}
                    </Typography>
                    <Typography color="success.main" className="fw-semibold">
                      {`${Math.floor(item.discount)}% off`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <AnimateButton>
                      <Button
                        size="small"
                        type="submit"
                        variant="outlined"
                        color="primary"
                        onClick={() => hanldeOrderSubmit(item)}
                      >
                        Buy Now
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          ))
        : null}
    </Grid>
  );
};

ProductCards.propTypes = {
  getProductsList: PropTypes.func.isRequired,
  getOrdersListByUserId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  productsList: state.product.productsList,
  loadingProductsList: state.product.loadingProductsList,
  sortingParams: state.product.sortingParams,
  userOrdersList: state.userOrder.userOrdersList,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getProductsList,
  getOrdersListByUserId,
  resetComponentStore,
  deleteProduct,
})(ProductCards);
