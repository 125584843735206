import { IoCartOutline } from "react-icons/io5";
import { MdOutlineHistory } from "react-icons/md";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const UserProductPath = {
  id: "products-admin",
  title: "",
  type: "group",
  children: [
    {
      id: "products",
      title: "Products",
      type: "item",
      icon: IoCartOutline,
      subChlidrens: [
        {
          id: "userProducts",
          title: "Products",
          type: "item",
          url: "/admin/products/list",
          icon: IoCartOutline,
        },
        {
          id: "userOrdersHistory",
          title: "Orders History",
          type: "item",
          url: "/admin/user/orders/history",
          icon: MdOutlineHistory,
        },
      ],
    },
  ],
};

export default UserProductPath;
