// assets
import { ImTree } from "react-icons/im";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const AdminDownlinePath = {
  id: "group-myDownline",
  title: "",
  type: "group",
  children: [
    {
      id: "adminDownline",
      title: "Downline",
      type: "item",
      url: "admin-downline",
      icon: ImTree,
      breadcrumbs: false,
    },
  ],
};

export default AdminDownlinePath;
