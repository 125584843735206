import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingOrdersList,
  orderSearchParametersUpdate,
  orderListUpdated,
  adminAccountListUpdated,
  resetOrder,
  orderError,
  loadingOnOrderSubmit,
  orderCreated,
  orderUpdated,
  orderDetailsById,
  orderDeleted,
  userOrderListUpdated,
} from "reducers/userOrderReducer";

import { baseURL } from "constants/index";

export const getOrdersList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingOrdersList());
    const res = await axios.get(
      `${baseURL}/api/admin/orders/list/${loggedInUserID}`,
      config
    );

    dispatch(orderSearchParametersUpdate(appTokenParams));

    dispatch(orderListUpdated(res.data?.response[0]?.data));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          orderError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getOrdersListByUserId = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    const loggedInUserID = localStorage.authLocalID
      ? localStorage.authLocalID
      : null;

    dispatch(loadingOrdersList());
    const res = await axios.get(
      `${baseURL}/api/admin/orders/user/list/${loggedInUserID}`,
      config
    );

    dispatch(orderSearchParametersUpdate(appTokenParams));

    dispatch(userOrderListUpdated(res.data?.response[0]?.data));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          orderError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const getAdminAccountsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOrdersList());
    const res = await axios.get(
      `${baseURL}/api/admin/orders/accounts/list`,
      config
    );

    dispatch(adminAccountListUpdated(res.data.response));
  } catch (err) {
    console.error(err);
  }
};

export const getOrderById = (order_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnOrderSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(
      `${baseURL}/api/admin/orders/${order_id}`,
      config
    );

    dispatch(orderDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          orderError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const createOrder = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnOrderSubmit());

    const res = await axios.post(
      `${baseURL}/api/admin/orders`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(orderCreated(res.data.response));
      dispatch(setAlert("Order Created.", "success"));
      navigate("/admin/user/orders/history");

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(orderError());
        dispatch(setAlert(res.data.message, "error"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.path));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          orderError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "error"));
    }
  }
};

export const updateOrder =
  (formData, order_id, navigate) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `${baseURL}/api/admin/orders/${order_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(orderUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));

        navigate("/admin/orders/request");

        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(orderError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            orderError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "error"
            )
          );
        }
      }
    }
  };

export const deleteOrder = (order_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.delete(`${baseURL}/api/admin/orders/${order_id}`, config);

    dispatch(orderDeleted(order_id));
    dispatch(setAlert("Order deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        orderError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeOrderErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetOrder());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(orderError());
    dispatch(setAlert("Please correct the following errors", "error"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.path));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/orders");
};
