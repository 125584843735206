import { TbPasswordMobilePhone } from "react-icons/tb";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const ChangeTransactionPasswordPath = {
  id: "group-updateChangeTransactionPassword",
  title: "",
  type: "group",
  children: [
    {
      id: "updateChangeTransactionPassword",
      title: " Transaction Password",
      type: "item",
      icon: TbPasswordMobilePhone,
      subChlidrens: [
        {
          id: "changeTransactionPassword",
          title: "Update Password",
          type: "item",
          url: "users/change-transaction-password",
          icon: TbPasswordMobilePhone,
        },
      ],
    },
  ],
};

export default ChangeTransactionPasswordPath;
